import Button from "components/ui/Button";
import { useContactMutation } from "redux/api/common";
import { useForm } from "react-hook-form";
import styles from "./ContactUsMain.module.css";
import TextIntput from "components/ui/TextInput";
import ThankYou from "components/Modals/ThankYou";
import { useState } from "react";

const ContactUsMain = () => {
  const [openModal, setOpenModal] = useState();
  const [contact, { isLoading }] = useContactMutation();
  const {
    register,
    handleSubmit,
  } = useForm();

  const onContact = async data => {
    try {
      const res = await contact(data);
      if (res?.data?.success === 'ok') {
        setOpenModal(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <p className={styles.title}>
          <span className="accent-text">contact</span> us
        </p>
        <p className={styles.addition}>
          Fill out and submit the form below and we will contact you as soon as
          possible.
        </p>
      </div>

      <form className={styles.contactWrapper} onSubmit={handleSubmit(onContact)}>
        <div className={styles.leftWrapper}>
          <div className={styles.line} />

          <div className={styles.inputWrapper}>
            <p className={styles.number}>01</p>
            <div className={styles.inputSubWrapper}>
              <p className={styles.inputTitle}>Your name</p>
              <TextIntput register={register} name="name" className={styles.input} placeholder="Name" />
            </div>
          </div>

          <div className={styles.line} />

          <div className={styles.inputWrapper}>
            <p className={styles.number}>02</p>
            <div className={styles.inputSubWrapper}>
              <p className={styles.inputTitle}>
                Your e-mail<span className="accent-text">*</span>
              </p>
              <TextIntput register={register} name="email" type="email" className={styles.input} placeholder="example@gmail.com" />
            </div>
          </div>

          <div className={styles.line} />

          <div className={styles.inputWrapper}>
            <p className={styles.number}>03</p>
            <div className={styles.inputSubWrapper}>
              <p className={styles.inputTitle}>Your phone</p>
              <TextIntput register={register} name="phone" type="phone" className={styles.input} placeholder="+" />
            </div>
          </div>

          <div className={styles.line} />
        </div>

        <div className={styles.rightWrapper}>
          <div className={styles.line} />

          <div className={styles.inputWrapper}>
            <div className={styles.inputSubWrapper}>
              <p className={styles.inputTitle}>Message</p>
              <textarea
                className={styles.input}
                rows={6}
                placeholder="Write your text"
                name="message"
                {...register('message')}
              />
            </div>
          </div>

          <div className={styles.line} />

          <Button type="submit" isLoading={isLoading} className={styles.button} variant="accent">
            {" "}
            Contact us
          </Button>
        </div>
      </form>
      <ThankYou active={openModal} setActive={setOpenModal} />
    </div>
  );
};

export default ContactUsMain;
