import styles from "./Card.module.css"
import Button from "components/ui/Button"
import { withTranslation } from "react-i18next"
import { useNavigateLang } from "hooks"

const Card = ({
  img,
  name,
  specialist,
  isEmpty,
  t
}) => {
  const navigate = useNavigateLang();

  return(
    <div className={styles.wrapper} style={false ? { gap: '0.8vw' } : null}>
      {!isEmpty ?
        <>
          <img className={styles.img} src={img}/>
          <div className={styles.textWrapper}>
            <p className={styles.specialist}>{specialist}</p>
            <p className={styles.name}>{name}</p>
          </div>
        </> :
        <>
          <img className={styles.img} src={img}/>
          <p className={styles.placeholderText}>{t('placeholder-text')}</p>
          <div className={styles.textWrapper}>
            <Button onClick={() => navigate('/contact-us')} variant='accent'>{t('contact-us')}</Button>
          </div>
        </>
      }
    </div>
  )
}

export default withTranslation('aboutus')(Card);
