import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from 'helpers/constants/routes';
import { useNavigate } from 'react-router-dom';

const LanguageBasedRoutes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const [langState, setLangState] = useState('en');

  useEffect(() => {
    const langMatch = pathname.match(/^\/(en|ru|ua|pt|es)\b/);

    if (langMatch) {
      const lang = langMatch ? langMatch[1] : 'en';

      i18n.changeLanguage(lang);
      setLangState(lang);
    } else {
      navigate(`/en${pathname}`);
    }
  }, []);

  i18n.on('languageChanged', lng => setLangState(lng));

  return (
    <Routes>
      {routes.map(({path, component}) => (
        <Route key={path} path={`/${langState}${path}`} element={component} />
      ))}
    </Routes>
  );
};

export default LanguageBasedRoutes;
