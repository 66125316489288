import { Button } from "components";
import styles from "./CommonIntro.module.css";
import { CircleArrowDownIcon } from "components/ui/icons";
import { withTranslation, Trans } from "react-i18next";

const CommonIntro = ({ title, subtitle, number }) => {
  const scrollToNext = () => {
    window.scrollTo({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <section className={styles.intro}>
      <div className={styles.titleWrapper}>
        {typeof title === "string"
          ? title.split(" ").map((word, index) => (
              <h2 key={index} className={styles.title}>
                {word}
              </h2>
            ))
          : null}
      </div>
      <div className={styles.bottom}>
        <div className={styles.numberWrapper}>
          <span>{number}</span>
        </div>
        <div className={styles.textWrapper}>
          {subtitle}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            icon={<CircleArrowDownIcon />}
            onClick={scrollToNext}
          ></Button>
        </div>
      </div>
    </section>
  );
};

export default CommonIntro;
