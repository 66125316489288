import styles from './Drawer.module.css';
import { CloseSquareIcon } from 'components/ui/icons';
import LangSelector from 'components/LangSelector';
import Button from 'components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from 'redux/slices/commonSlice';
import {useTranslation, withTranslation} from 'react-i18next';
import { useNavigateLang } from 'hooks';

const Drawer = ({
                  variant = "blur", // blur | light
                  t,
                }) => {
  const navigate = useNavigateLang();
  const { i18n } = useTranslation();
  const isLight = variant === "light";
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector(state => state.common.isDrawerOpen);

  const handleCloseClick = () => {
    dispatch(toggleDrawer());
  };

  const handleMenuItmeClick = path => {
    navigate(path);
    dispatch(toggleDrawer());
  }

  const navigateToBackofficeLogin = () => {
    const sponsorLogin = localStorage.getItem('sponsor_login') || '';
    window.location = '//partners.cannabetrust.net/login?lang=' + i18n.language + '&sponsor_login=' + sponsorLogin;
  };

  const navigateToBackofficeRegistration = () => {
    const sponsorLogin = localStorage.getItem('sponsor_login') || '';
    window.location = '//partners.cannabetrust.net/register?lang=' + i18n.language + '&sponsor_login=' + sponsorLogin;
  };

  const menuItems = [
    {
      text: t('main'),
      path: '/',
    },
    {
      text: t('about-us'),
      path: '/about-us',
    },
    {
      text: t('products'),
      path: '/our-products',
    },
    {
      text: t('tech'),
      path: '/technical',
    },
    {
      text: t('medical'),
      path: '/medical',
    },
    {
      text: t('contact-us'),
      path: '/contact-us',
    },
  ];

  return (
    <div
      className={styles.drawerWrapper}
      style={{ transform: isDrawerOpen ? 'translateX(0)' : 'translateX(100vw)' }}
    >
      <div className={styles.drawer} style={{ left: isDrawerOpen ? '21px' : '100vw' }}>
        <div className={styles.head}>
          <div className={styles.closeButton} onClick={handleCloseClick}>
            <CloseSquareIcon />
          </div>
        </div>
        <nav className={styles.menu}>
          <ul className={styles.list}>
            {menuItems.map(({ text, path }, index) =>
              <li key={index} className={styles.item}>
                <a onClick={() => handleMenuItmeClick(path)}>
                  {text}
                </a>
              </li>
            )}
          </ul>
        </nav>
        <LangSelector
          variant='dark'
          className={styles.langSelector}
          withIcon
        />
        <div className={styles.actions}>
          <Button
            size="big"
            className={styles.signin}
            variant={isLight ? "accent" : "primary"}
            onClick={() => navigateToBackofficeLogin()}
          >
            {t('log-in')}
          </Button>

          <Button
            size="big"
            className={styles.signup}
            variant='outline'
            onClick={() => navigateToBackofficeRegistration()}
          >
            {t('sign-up')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withTranslation('header')(Drawer);
