import styles from './Sidebar.module.css';
import logo3 from 'assets/images/logo-3.svg';
import coin from 'assets/images/icons/coin.svg';
import profilePlaceholder from 'assets/images/profile-image-placeholder.svg';
import starIcon from 'assets/images/icons/star.svg';
import {
  BinaryIcon,
  DashboardIcon,
  PackagesIcon,
  MaterialsIcon,
  FinanceIcon,
  StoreIcon,
} from 'components/ui/icons';
import { Burger, Menu } from 'components';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BonusCard from './BonusCard';
import { useDashboardQuery } from 'redux/api/common';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSidebar, toggleSidebar } from 'redux/slices/commonSlice';
import { withTranslation } from 'react-i18next';
import { useNavigateLang } from 'hooks';


const Sidebar = ({ className, t }) => {
  const [menuValue, setMenuValue] = useState(0);
  const [isHorizontal, setIsHorizontal] = useState(false);
  const [isCompact, setIsCompact] = useState(false);
  const navigate = useNavigateLang();
  const location = useLocation();
  const { data } = useDashboardQuery();
  const { isSidebarOpen } = useSelector(state => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const resizeCondition = window.outerWidth <= 805;
      setIsHorizontal(resizeCondition);
      setIsCompact(resizeCondition);
      dispatch(setIsSidebar(true));

      if (!resizeCondition) {
        dispatch(setIsSidebar(false));
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuSelect = item => {
    if (item && item.value) {
      navigate(item.value);
      dispatch(toggleSidebar());
    }
  };

  useEffect(() => {
    setMenuValue(
      menuItems.findIndex(item => item.value === location.pathname)
    );
  }, []);

  const filter = 'invert(42%) sepia(5%) saturate(231%) hue-rotate(169deg) brightness(90%) contrast(89%)';

  const menuItems = [
    {
      text: t('dashboard'),
      icon: <DashboardIcon style={{ filter }} />,
      value: '/dashboard',
    },
    {
      text: t('finance'),
      icon: <FinanceIcon style={{ filter }} />,
      value: '/finance',
    },
    {
      text: t('packages'),
      icon: <PackagesIcon style={{ filter }} />,
      value: '/packages',
    },
    {
      text: t('team'),
      icon: <BinaryIcon style={{ filter }} />,
      value: '/binary',
    },
    {
      text: t('materials'),
      icon: <MaterialsIcon style={{ filter }} />,
      value: '/materials',
    },
    {
      text: t('store'),
      icon: <StoreIcon style={{ filter }} />,
      value: '/store',
    },
  ];

  return (
    <section
      className={`${styles.sidebar} ${isSidebarOpen ? styles.sidebarOpen : null} ${className}`}
    // style={isCompact ? { maxWidth: 0, width: 0 } : { maxWidth: '999px', width: 'auto' }}
    // onMouseEnter={() => setIsCompact(false)}
    // onMouseLeave={() => setIsCompact(true)}
    >
      <div className={styles.inner}>
        <div className={styles.header}>
          <div className={styles.logos}>
            <img
              className={styles.logoDesktop}
              src={logo3}
              onClick={() => navigate('/')}
              style={isCompact ? { display: 'none' } : null}
            />
            <img
              className={styles.logoMobile}
              src={coin}
              onClick={() => navigate('/')}
              style={isCompact ? { display: 'block' } : null}
            />
          </div>
          <div className={styles.toggleButton}>
            <Burger open={isSidebarOpen} onClick={() => dispatch(toggleSidebar())} />
          </div>
        </div>
        <div
          className={styles.profile}
          style={isCompact ? { display: 'none' } : null}
        >
          <div className={styles.profilePlaceholder}>
            <img src={profilePlaceholder} />
          </div>
          <div className={styles.profileInfo}>
            <div className={styles.username}>{data?.user?.login}</div>
            <div className={styles.userRole}>
              <img src={starIcon} />
              <span>{data?.rank?.name}</span>
            </div>
          </div>
        </div>
        <Menu
          className={styles.menu}
          items={menuItems}
          onSelect={handleMenuSelect}
          value={menuValue}
          isCompact={isCompact}
          isHorizontal={isHorizontal}
        />
        {!isCompact && <BonusCard />}
      </div>
    </section>
  );
}

export default withTranslation('cabinet-header')(Sidebar);
