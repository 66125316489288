import styles from "./TokenCard.module.css"

const TokenCard = ({percent, description}) => {
  return( 
    <div className={styles.wrapper}>
      <div className={styles.percent}>{percent}</div>
      <div className={styles.description}>{description}</div>
    </div>
  )
}

export default TokenCard