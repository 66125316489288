const SearchIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <circle cx="8.80547" cy="8.30589" r="7.49047" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.0153 13.9043L16.9519 16.8334" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default SearchIcon;
