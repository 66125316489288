import styles from "./Product.module.css"
import { Button, Counter } from "components";
import { CartIcon } from "components/ui/icons";

const Product = ({ title, subtitle, dosageLeft, dosageRight, price, img, buttonText, status }) => {
  return (
    <div className={styles.product}>
      <div className={styles.imageContainer}>
        <img src={img} />
      </div>
      <div className={styles.head}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={styles.dosage}>
        <div>{dosageLeft.map((item, index) => <span key={index}>{item}</span>)}</div>
        <div>{dosageRight.map((item, index) => <span key={index}>{item}</span>)}</div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.price}>{price}$</div>
        <div className={styles.actions}>
          {status === 'available' ? <Counter /> : null}
          <Button
            className={`${styles.addCart} ${styles[status]}`}
            size="big"
            variant="accent"
            icon={<CartIcon />}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Product;
