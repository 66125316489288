import UltimateModal from "../UltimateModal";
import styles from "./PasswordChanged.module.css"
import Button from "components/ui/Button";
import { withTranslation, Trans} from "react-i18next";
import { useNavigateLang } from "hooks";

const PasswordChanged = ({
  active,
  setActive,
  withClose = true,
  t,
}) => {
  const navigate = useNavigateLang();

  const modalProps = {
    children: (
      <>
        <h2 className={styles.title}>
          <span>
            <Trans
              t={t}
              i18nKey={"password-successfully-changed"}
              components={[<span className="accent-text"/>]}
            /> 
          </span>
        </h2>
        <p className={styles.subtitle}>
          <Trans
            t={t}
            i18nKey={"you-can-now-login"}
          /> 
        </p>
        <div className={styles.bottom}>
          <Button
            variant="accent"
            size='big'
            onClick={() => navigate('/login')}
          >
            {t('log-in')}
          </Button>
        </div>
      </>
    ),
    className: styles.modal,
    active,
    setActive,
    withClose,
  };

  return <UltimateModal {...modalProps} />;
};

export default withTranslation('settings')(PasswordChanged);
