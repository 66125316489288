import styles from './DashboardIncome.module.css';
import { Button, ButtonGroup, Slider } from 'components';
import { ActivityIcon } from 'components/ui/icons';
import { useEffect, useState, useRef } from 'react';
import { usePackagesAllQuery, usePackagesCalcQuery } from 'redux/api/common';
import { withTranslation } from 'react-i18next';
import { useNavigateLang } from 'hooks';

const DashboardIncome = ({ t }) => {
  const [incomeValue, setIncomeValue] = useState(10000);
  const [packageButtons, setPackageButtons] = useState([]);
  const navigate = useNavigateLang();
  const [id, setId] = useState(0)
  const inputRef = useRef();
  const { data: packages } = usePackagesCalcQuery({ packageId: id + 1, amount: 50 });
  const { data: packagesAll } = usePackagesAllQuery();
  const [ fixedPackage, setFixedPackage ] = useState(null);
  const fixablePackages = ['Canna Hold']

  const checkPackage = value => {
    for (const [index, item] of packagesAll.entries()) {
      if (fixedPackage) {
        continue;
      }
      
      value = parseInt(value);
      const from = parseInt(item?.amount_from?.replace(' ', ''));
      const to = parseInt(item?.amount_to?.replace(' ', ''));

      if (value >= from && value <= to || value >= 50000) {
        setId(index);
      }
    }
  };

  useEffect(() => {
    if (packagesAll) {
      setPackageButtons(packagesAll.filter(p => p.name !== 'Shareholder').map(({ name }) => ({
        text: name,
        color: `var(--color-${name.toLowerCase().replace(' ', '-')})`,
      })));
    }
  }, [packagesAll]);

  useEffect(() => {
    if (packagesAll) {
      checkPackage(incomeValue);
    }
  }, [packagesAll]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.style.width = `${incomeValue.length}ch`;
    }
  }, [incomeValue]);

  const handleChange = value => {
    setIncomeValue(value);
    checkPackage(value);
  };

  const handleInputChange = (e) => {
    const value = parseFloat(e.target.value);

    if (value <= 50000 || value >= 50) {
      setIncomeValue(parseFloat(value));
      e.target.style.width = `${e.target.value.length}ch`;
    }
  };

  const handleButtonGroupSelect = ({ text }) => {
    if(fixablePackages.includes(text) && text !== fixedPackage){
      setFixedPackage(text)
    }else{
      setFixedPackage(null)
    }


    const pack = packagesAll?.find(p => p.name === text);

    if (pack) {
      const from = parseInt(pack.amount_from?.replace(' ', ''));
      setIncomeValue(from);
    }
  };

  const formatedNumber = (number) => {
    return Number(number).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  }

  const calculations = [
    {
      title: t('days'),
      value: `${formatedNumber(packages?.calc.full.percent / packages?.calc.day.percent)} Days`,
      isAccent: false,
    },
    {
      title: t('profit-in-percent-per-day'),
      value: `${formatedNumber(packages?.calc.day.percent)}%`,
      isAccent: false,
    },
    {
      title: t('profit-per-day'),
      value: `${formatedNumber((incomeValue * packages?.calc.day.percent) / 100)} $`,
      isAccent: true,
    },
    {
      title: t('profit-in-percent-for-all-period'),
      value: `${formatedNumber(packages?.calc.full.percent)}%`,
      isAccent: false,
    },
    {
      title: t('profit-for-all-period'),
      value: `${formatedNumber((incomeValue * packages?.calc.full.percent) / 100)} $`,
      isAccent: true,
    },
  ];

  return (
    <section className={styles.dashboardIncome}>
      <div className={styles.head}>
        <div className={styles.left}>
          <div className={styles.suptitle}>{t('investment-amount')}</div>
          <div className={styles.title}>
            <input
              className={`${styles.title} ${styles.incomeInput}`}
              // value={formatNumber(incomeValue)}
              value={incomeValue}
              onChange={handleInputChange}
              min={50}
              max={50000}
              ref={inputRef}
              type='number'
            />
          $</div>
        </div>
        <div className={styles.right}>
          <Button
            className={styles.iconButton}
            variant='outline-filled'
            icon={<ActivityIcon />}
            disableEffects
          />
        </div>
      </div>
      <Slider
        min={50}
        max={50_000}
        value={incomeValue}
        onChange={handleChange}
      />
      <ButtonGroup
        label={t('choose-investment-package')}
        buttons={packageButtons}
        value={id}
        onChange={handleButtonGroupSelect}
        className={styles.buttonGroup}
      />
      <div className={styles.calcBlock}>
        {calculations.map(({ title, value, isAccent }, index) =>
          <div key={index}>
            <div className={styles.calcTitle}>{title}</div>
            <div
              className={styles.calcValue}
              style={isAccent ? { color: 'var(--accent)' } : null}
            >
              {value}
            </div>
          </div>
        )}
      </div>
      <Button
        variant="accent"
        style={{ width: '100%' }}
        onClick={() => navigate('/packages')}
      >
        {t('invest-now')}
      </Button>
    </section>
  );
};

export default withTranslation('dashboard')(DashboardIncome);
