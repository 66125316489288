import { createSlice } from '@reduxjs/toolkit';

const authToken = localStorage.getItem('auth_token');

const commonSlice = createSlice({
    name: 'common',
    initialState: {
      csrfToken: '',
      isDrawerOpen: false,
      cartList: [],
      authToken: authToken,
      isAuth: typeof authToken === 'string' && authToken.trim().length > 0,
      isHeaderHidden: false,
      selectedPackage: { id: 0, index: 0 },
      isSidebarOpen: false,
      binaryBaseData: {},
      modal: '',//oil, capsules, bears, cream
    },
    reducers: {
      setCsrfToken(state, action) {
        state.csrfToken = action.payload;
      },
      addProductToCart(state, action) {
        state.cartList = [...state.cartList, action.payload];
      },
      removeProductFromCart(state, action) {
        state.cartList = [...state.cartList].filter(product => product.id !== action.id);
      },
      toggleDrawer(state) {
        state.isDrawerOpen = !state.isDrawerOpen;
      },
      setIsHeaderHidden(state, action) {
        state.isHeaderHidden = action.payload;
      },
      setSelectedPackage(state, action) {
        state.selectedPackage = action.payload;
      },
      toggleSidebar(state, _) {
        state.isSidebarOpen = !state.isSidebarOpen;
      },
      setIsSidebar(state, action) {
        state.isSidebarOpen = action.payload;
      },
      setBinaryBaseData(state, action) {
        state.binaryBaseData = action.payload;
      },
      setModal(state, action) {
        state.modal = action.payload;
      }
    },
});

export const {
  setCsrfToken,
  toggleDrawer,
  addProductToCart,
  removeProductFromCart,
  setIsHeaderHidden,
  setSelectedPackage,
  toggleSidebar,
  setIsSidebar,
  setBinaryBaseData,
  setModal,
} = commonSlice.actions;
export default commonSlice.reducer;
