const BinaryIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M14.9064 9.65564C16.0689 9.4923 16.9639 8.49564 16.9664 7.28814C16.9664 6.09814 16.0989 5.11147 14.9614 4.9248" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.4404 12.4502C17.5663 12.6185 18.3521 13.0127 18.3521 13.8252C18.3521 14.3844 17.9821 14.7477 17.3838 14.976" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.90553 12.7949C7.2272 12.7949 4.9397 13.2008 4.9397 14.8216C4.9397 16.4416 7.21303 16.8591 9.90553 16.8591C12.5839 16.8591 14.8705 16.4574 14.8705 14.8358C14.8705 13.2141 12.598 12.7949 9.90553 12.7949Z" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.90555 10.4812C11.663 10.4812 13.088 9.05704 13.088 7.29871C13.088 5.54121 11.663 4.11621 9.90555 4.11621C8.14805 4.11621 6.72305 5.54121 6.72305 7.29871C6.71638 9.05038 8.13055 10.4754 9.88221 10.4812H9.90555Z" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.90399 9.65564C3.74066 9.4923 2.84649 8.49564 2.84399 7.28814C2.84399 6.09814 3.71149 5.11147 4.84899 4.9248" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.36992 12.4502C2.24409 12.6185 1.45825 13.0127 1.45825 13.8252C1.45825 14.3844 1.82825 14.7477 2.42659 14.976" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default BinaryIcon;
