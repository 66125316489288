import UltimateModal from "../UltimateModal"
import styles from "./ProductsModal.module.css"

const ProductsModal = ({
  title1,
  title2,
  subtitle,
  addition,
  cards1 = [],
  cards2 = [],
  image,
  color,
  type = 'products',
  active,
  setActive,
  withClose = true,
}) => {
  const props = {
    children: (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {(title1 || title2) && 
            <p className={styles.title}>{title1} <br/> <span className="accent-text">{title2}</span></p>
          }
          <div className={styles.top}>
            {subtitle && 
              <p className={styles.subtitle}>{subtitle}</p>
            }
            {addition && 
              <p className={styles.addition}>{addition}</p>
            }
          </div>
        </div>

        <div className={styles.line}/>

        <div className={styles.content}>
          {cards1 && 
            <div className={styles.cards}>
              {cards1.map((card, index)=>
                <div className={styles.card}>
                  <p className={styles.title}>{card.title}</p>
                  <p className={styles.text}>{card.text}</p>
                </div>
              )}
            </div>
          }
          {cards2 && 
            <div className={styles.cards}>
              {cards2.map((card, index)=>
                <div className={styles.card}>
                  {card.title && 
                    <p className={styles.title}>{card.title}</p>
                  }
                  {card.text && 
                    <p className={styles.text}>{card.text}</p>
                  }
                </div>
              )}
            </div>
          }
          {image && 
          <div className={styles.circle} style={{backgroundColor: color}}>
            {image}
          </div>
          }
        </div>
      </div>
    ),
    type,
    active,
    setActive,
    withClose,
  }
  return <UltimateModal {...props} />
}

export default ProductsModal;