import styles from "./StandartedTextRowDouble.module.css"

const StandartedTextRowDouble = ({
  title=<></>,
  subtitle1=<></>,
  addition1=<></>,
  subtitle2=<></>,
  addition2=<></>,
  children=<></>
}) => {
  return(
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.right}>
        <div className={styles.subtitle}>{subtitle1}</div>
        <div className={`${styles.addition} ${styles.addition1}`}>{addition1}</div>
      </div>
      <div className={styles.right}>
        <div className={styles.subtitle}>{subtitle2}</div>
        <div className={`${styles.addition} ${styles.addition2}`}>{addition2}</div>
      </div>
      {children}
    </div>
  )
}

export default StandartedTextRowDouble;