import styles from './Footer.module.css';
import logo from 'assets/images/logo-2.svg';
import upIcon from 'assets/images/icons/arrow-up-in-circle_outline.svg';
import fbIcon from 'assets/images/icons/social-fb.svg';
import igIcon from 'assets/images/icons/social-ig.svg';
import ytIcon from 'assets/images/icons/social-yt.svg';
import tgIcon from 'assets/images/icons/social-tg.svg';
import { withTranslation } from 'react-i18next';
import { useNavigateLang } from 'hooks';

const Footer = ({ t }) => {
  const navigate = useNavigateLang();

  const scrollToStart = () => {
    window.scrollTo({
      top:0,
      left:0,
      behavior: 'smooth',
    })
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.column} onClick={() => navigate('/')} style={{"cursor": "pointer"}}>
            <img src={logo} />
          </div>
          <div className={styles.column}>
            <span onClick={() => navigate('/about-us')}>{t('about')}</span>
            <span onClick={() => navigate('/our-products')}>{t('products')}</span>
            <span onClick={() => navigate('/contact-us')}>{t('contacts')}</span>
          </div>
          <div className={styles.column}>
            {/*<span onClick={() => navigate('/seeds')}>{t('seeds')}</span>*/}
            <span onClick={() => navigate('/technical')}>{t('technical')}</span>
            <span onClick={() => navigate('/medical')}>{t('medical')}</span>
          </div>
        </div>
        <div className={styles.right}>
          <a className={styles.contact} target='_blank' href='https://www.facebook.com/groups/cannabetrust'>
            <img src={fbIcon} />
            Facebook
          </a>
          <a className={styles.contact} target='_blank' href='https://www.instagram.com/cannabetrust/'>
            <img src={igIcon} />
            Instagram
          </a>
          <a className={styles.contact} target='_blank' href='https://www.youtube.com/channel/UCmF1J9_7YO_vwJ3rzcIBMsw'>
            <img src={ytIcon} />
            Youtube
          </a>
          <a className={styles.contact} target='_blank' href='https://t.me/cannabetrustcompany'>
            <img src={tgIcon} />
            Telegram
          </a>
        </div>
      </div>
      <div className={styles.center}>
        <span className={styles.email}>info@cannabetrust.com</span>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomLeft}>
          <span className={styles.secondary}>© {t('copy')}</span>
          <span className={styles.email}>info@cannabetrust.com</span>
        </div>
        <div className={styles.bottomCenter}>
          <span onClick={() => navigate('/privacy-policy')} className={styles.secondary}>{t('privacy')}</span>
          <span onClick={() => navigate('/terms')} className={styles.secondary}>{t('terms')}</span>
          <a href='https://cannabetrust.freshdesk.com/' target='_blank' className={styles.secondary}>{t('support')}</a>
        </div>
        <div className={styles.bottomRight} onClick={scrollToStart}>
          <button className={styles.buttonToStart}>
            <img src={upIcon} />
          </button>
        </div>
      </div>
    </footer>
  );
}

export default withTranslation('footer')(Footer);
