import styles from "./Pagination.module.css"
import icon from "assets/images/icons/arrow-right-dark-square.svg"

const Pagination = ({
  page, 
  setPage=()=>{},
  length,
}) => {

  const handlePage = (number) => {
    if(number < 0){
      setPage(0);
      return;
    }
    if(number > length){
      setPage(length);
      return;
    }
    setPage(number)
  }

  const pageNubmers = (number) => {
    return(
      <>
        {number > 0 && 
          <div className={styles.num} onClick={() => handlePage(length-1)}>
            {1}
          </div>
        }
        {number > 0+1 && 
          <div>
            ...
          </div>
        }
        <div>
          <span className="accent-text">{number+1}</span>
        </div>
        {number < length-1 && 
          <div>
            ...
          </div>
        }
        {number < length && 
          <div className={styles.num} onClick={() => handlePage(length + 1)}>
            {length+1}
          </div>
        }
      </>
    )
  }

  return(
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={() => handlePage(page - 1)}>
        <img className={styles.icon} src={icon}/>
      </button>
      <div className={styles.numbers}>
        {pageNubmers(page)}
      </div>
      <button className={styles.button} onClick={() => handlePage(page + 1)}>
        <img className={styles.icon} src={icon}/>
      </button>
    </div>
  )
}

export default Pagination;
