import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const langs = ['en', 'ru', 'ua', 'pt', 'es'];

const namespaces = [
  'common',
  'header',
  'footer',
  'main',
  'our-products',
  'seeds',
  'technical',
  'medical',
  'aboutus',
  'terms',
  'privacy-policy',
  'dashboard',
  'binary',
  'materials',
  'store',
  'cabinet-header',
  'packages',
  'finance',
  'settings',
  'not-a-found',
  'landing-modals',
  'forms',
  'cabinet-modals',
];

const resources = {};

for (const lang of langs) {
  resources[lang] = {};
  for (const namespace of namespaces) {
    resources[lang][namespace] = require(`./locales/${lang}/${namespace}.json`);
  }
}

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources,
  ns: [Object.keys(resources.en)],
  defaultNS: 'common',
});

i18n.languages = Object.keys(resources);

export default i18n;
