import styles from "./Card.module.css"

const Card = ({title, subtitle}) => {
  console.log(title, subtitle)
  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  )
}

export default Card;