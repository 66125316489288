const CopyIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2805 2.30178H6.73717C5.00384 2.29512 3.583 3.67595 3.54217 5.40928V14.4501C3.513 16.1584 4.87384 17.5676 6.583 17.5976C6.63384 17.5976 6.6855 17.5976 6.73717 17.5959H13.3938C15.118 17.5784 16.5047 16.1743 16.5022 14.4501V6.70012L12.2805 2.30178Z" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0619 2.29199V4.71616C12.0619 5.89949 13.0194 6.85866 14.2028 6.86199H16.4978" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.9114 10.7624H7.82809" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.87042 12.8031V8.71973" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default CopyIcon;
