import { CabinetContainer, Remove2FA } from "components";

const SettingRemove2FA = () => {
  return(
    <CabinetContainer>
      <Remove2FA />
    </CabinetContainer>
  )
}

export default SettingRemove2FA;