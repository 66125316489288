import styles from "./OurDocuments.module.css";
import Card from "./Card";
import doc1 from "assets/images/document-1.png";
import doc2 from "assets/images/document-2.png";
import doc3 from "assets/images/document-3.png";
import doc4 from "assets/images/document-4.png";
import docFull1 from "assets/images/doc-1.png";
import docFull2 from "assets/images/doc-2.png";
import docFull3 from "assets/images/doc-3.png";
import docFull4 from "assets/images/doc-4.png";
import { ArrowRightIcon } from "components/ui/icons";
import UltimateModal from "components/Modals/UltimateModal";
import { Swiper, SwiperSlide } from "swiper/react";
import { withTranslation } from "react-i18next";

// Import Swiper styles
import "swiper/css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsHeaderHidden } from "redux/slices/commonSlice";

const OurDocuments = ({ t }) => {
  const swiperRef = useRef(null);
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  const [modalOpen, setModalOpen] = useState();
  const images = [docFull1, docFull2, docFull3, docFull4];

  useEffect(() => {
    dispatch(setIsHeaderHidden(modalOpen));
  }, [modalOpen]);

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleSelect = index => {
    setModalOpen(true);
    setSelectedItem(index);
  };

  const cards = [
    {
      name: t('documents-card-1-name'),
      img: doc1,
    },
    {
      name: t('documents-card-2-name'),
      img: doc2,
    },
    {
      name: t('documents-card-3-name'),
      img: doc3,
    },
    {
      name: t('documents-card-4-name'),
      img: doc4,
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.nowCardHandler}>
        <div className={styles.buttons}>
          <button className={styles.button} onClick={handlePrev}>
            <ArrowRightIcon />
          </button>
          <button className={styles.button} onClick={handleNext}>
            <ArrowRightIcon style={{ filter: "invert(100%)" }} />
          </button>
        </div>
        <h3 className={styles.buttonYears}>0{activeIndex+1}/04</h3>
      </div>
      <div className={styles.slides}>
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          className="productsSwiper"
          ref={swiperRef}
          onSlideChange={(e) => setActiveIndex(e.activeIndex)}
        >
          {cards.map((card, index) => (
            <SwiperSlide key={index}>
              <Card
                key={index}
                {...card}
                hide={index === 0 && activeIndex >= 2}
                onClick={() => handleSelect(index)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.cards}>
        {cards.map((card, index) => (
          <Card
            number={index}
            onClick={() => handleSelect(index)}
            {...card}
          />
        ))}
      </div>
      <UltimateModal className={styles.modal} active={modalOpen} setActive={setModalOpen}>
        <img src={images[selectedItem]} />
      </UltimateModal>
    </div>
  );
};

export default withTranslation('aboutus')(OurDocuments);
