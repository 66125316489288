import ProductsModal from "../ProductsModal";
import UltimateModal from "../UltimateModal";
import styles from "./Oil.module.css"
import img from "assets/images/card-1-normalized.png"
import { withTranslation } from "react-i18next";

const Oil = ({
  active,
  setActive = ()=>{},
  withClose = true,
  t,
}) => {
  const cards1 = [
    {
      title: t('how-to-use'),
      text: t('shake-before-use'),
    },
    {
      title: t('storage-conditions'),
      text: t('keep-in-the-refrigerator'),
    },
    {
      title: t('ingredients'),
      text: t('our-cbd-oil-also-include'),
    },
  ]

  const cards2 = [
    {
      title: t('cbd'),
      text: '30%',
    },
    {
      title: t('package-size'),
      text: `2000 ${t('mg')}`,
    },
    {
      title: t('thc'),
      text: '0%',
    },
  ]

  const title1 = t('cannabetrust')
  const title2 = `${t('cbd')} ${t('oil')}`
  const subtitle = t('one-of-the-best-ways')
  const addition = t('is-made-form-organic')
  const image = <img className={styles.img} src={img} alt={`${t('cbd')} ${t('oil')}`}/>
  const color = '#67B793'

  const props = {
    title1,
    title2,
    subtitle,
    addition,
    cards1,
    cards2,
    image,
    color,

    active,
    setActive,
    withClose,
  }

  return <ProductsModal {...props} />
}

export default withTranslation('landing-modals')(Oil);
