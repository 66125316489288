import Button from "components/ui/Button";
import { useEffect } from "react";
import styles from "./Enable2FA.module.css"
import { ArrowRightIcon, PasswordIcon } from "components/ui/icons";
import TextIntput from "components/ui/TextInput";
import { useForm } from "react-hook-form";
import { useEnableTfaMutation, useFormTfaQuery } from "redux/api/2fa";
import { withTranslation, Trans } from "react-i18next";
import { toast } from 'react-toastify';
import { useNavigateLang } from "hooks";

const Enable2FA = ({ t }) => {
  const { data } = useFormTfaQuery();
  const [enableTfa, { isLoading, error }] = useEnableTfaMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigateLang();

  useEffect(() => {
      if(error && error?.status !== 200) {
        const message = error?.data?.errors || 'Something went wrong';
	      toast(message.join(' '), { type: 'error' });
    }
  }, [error]);

  const handleEnableTfa = async ({ code }) => {
    const resp = await enableTfa({ code, secret: data?.secret });

    if (resp?.data?.success === 'ok') {
      localStorage.setItem('tfa_codes', JSON.stringify(resp?.data?.codes));
      navigate('/2fa/enabled');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <Button onClick={() => navigate('/settings')} className={styles.titleButton} icon={<ArrowRightIcon className={styles.iconButton}/>}> {t('back-to-settings')} </Button>
        <p className={styles.title}>
          <span className="accent-text">{t('google-authenticator')}</span>
        </p>
      </div>
      <div className={styles.content}>
        <p className={styles.QRtitle}>
          <Trans
            t={t}
            i18nKey={"scan-QR-code"}
            components={[]}
          /> 
        </p>
    
        <img width={167} src={data?.qrImage} />

        <p className={styles.FAcode}>
          {data?.secret}
        </p>

        <form className={styles.input} onSubmit={handleSubmit(handleEnableTfa)}>
          <TextIntput
            name={'code'}
            type='number'
            label={t('enter-code')}
            register={register}
            icon={<PasswordIcon/>}
            placeholder="|"
            errors={errors}
            className={styles.codeInput}
            validationSchema={{
              required: 'Fill this field!',
            }}
          />
          <Button type="submit" isLoading={isLoading} className={styles.inputButton} variant="accent">{t('next')}</Button>
        </form>
      </div>
    </div>
  )
}

export default withTranslation('settings')(Enable2FA);
