import { AuthPageContainer, ResetForm } from "components"

const Reset = () => {
  return (
    <div>
      <AuthPageContainer>
        <ResetForm />
      </AuthPageContainer>
    </div>
  )
}

export default Reset
