import styles from './Menu.module.css';
import MenuItem from './MenuItem';
import { useEffect, useState } from 'react';

const Menu = ({
  items,
  onSelect,
  value,
  isCompact,
  isHorizontal,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    if (typeof value === 'number') {
      setSelectedItem(value);
    }
  }, [value]);

  const handleItemClick = index => {
    setSelectedItem(index);

    if (typeof onSelect === 'function' && items[index]) {
      onSelect(items[index]);
    }
  };

  return (
    <nav
      className={styles.menuNav}
      onClick={e => e.stopPropagation()}
      {...props}
    >
      <ul className={`${styles.menuList} ${isHorizontal ? styles.horizontalMenu : ''}`}>
        {items.map((item, index) =>
          <MenuItem
            key={index}
            icon={item.icon}
            text={item.text}
            active={index === selectedItem}
            onClick={() => handleItemClick(index)}
            isCompact={isCompact}
          />
        )}
      </ul>
    </nav>
  );
};

export default Menu;
