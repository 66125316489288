import Button from "components/ui/Button";
import { chunkArray } from "helpers/utils";
import { useEffect, useState } from "react";
import styles from "./Enabled2FA.module.css"
import { withTranslation, Trans } from "react-i18next";
import { useNavigateLang } from "hooks";

const Enabled2FA = ({ t }) => {
  const [codesArr, setCodesArr] = useState();
  const navigate = useNavigateLang();

  useEffect(() => {
    const tfaCodes = JSON.parse(localStorage.getItem('tfa_codes') || null);
    if (tfaCodes) {
      setCodesArr(chunkArray(tfaCodes, 2));
    } else {
      navigate('/settings')
    }

    return () => {
      localStorage.removeItem('tfa_codes');
    };
  }, []);

  return(
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        <span className="accent-text">{t('google-authenticator')}</span> {t('enabled')}
      </h1>
      <div className={styles.content}>
        <div className={styles.card}>
          <div>
            {codesArr && codesArr[0].map((code, index) => <p key={index}>{code}</p>)}
          </div>
          <div>
            {codesArr && codesArr[1].map((code, index) => <p key={index}>{code}</p>)}
          </div>
        </div>
        <p className={styles.text}>
          <Trans
            t={t}
            i18nKey={"can-backup"}
            components={[<span className="accent-text"/>]}
          /> 
        </p>
        <Button className={styles.button} onClick={() => navigate('/settings')}>
          {t('back-to-settings')}
        </Button>
      </div>
    </div>
  )
}

export default withTranslation('settings')(Enabled2FA);
