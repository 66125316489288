import { Capsules, Cream, GummyBears, Oil, OurAdvantages, PageContainer, ProductsInfo, ProductsIntro } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from 'redux/slices/commonSlice';

const Main = () => {
  const { modal } = useSelector(state => state.common);
  const [state, setState] = useState(false)

  const modals = {
    oil: <Oil active={state}/>,
    capsules: <Capsules active={state}/>,
    bears: <GummyBears active={state}/>,
    cream: <Cream active={state}/>,
  }

  useEffect(()=>{//шобы анимация появления была
    modals[modal] ? setState(true) : setState(false);
  },[modal])

  return (
    <PageContainer>
      <ProductsIntro/>
      <OurAdvantages/>
      <ProductsInfo/>
      {modals[modal] &&
        modals[modal]
      }
    </PageContainer>
  );
}

export default Main;
