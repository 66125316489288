import styles from "./PackagesCards.module.css"
import coin from "assets/images/icons/coin.svg";
import logo from "assets/images/icons/cannabi-gray.svg";
import arrowExpand from "assets/images/icons/arrow_expand.svg";
import infoIcon from "assets/images/icons/info-icon.svg";
import Button from "components/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPackage } from "redux/slices/commonSlice";
import { usePackagesAllQuery } from "redux/api/common";
import { useEffect, useState } from "react";
import { scrollTo } from "helpers/utils";
import { withTranslation, Trans } from "react-i18next";

const PackagesCards = ({ t }) => {
  const { data: packageList } = usePackagesAllQuery();
  const { selectedPackage } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState();
  const [expanded, setExpanded] = useState();
  const isMobile = window.outerWidth < 1200 ? true : false;

  const handleTitleClick = index => {
    if (!collapsed) {
      return;
    }

    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  }

  const handleCardClick = index => {
    dispatch(setSelectedPackage({
      id: packageList[index].id,
      name: packageList[index].name,
      index,
    }));
    scrollTo('buy-form');
  };

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.outerWidth <= 500)
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (packageList) {
      dispatch(setSelectedPackage({
        id: packageList[0].id,
        name: packageList[0].name,
        index: 0,
      }));
    }
  }, [packageList]);

  const colors = [
    '#87C7AB',
    '#67B793',
    '#6F7DD7',
    '#489B6D',
    '#367755',
    '#26543D',
  ];

  return(
    <div className={styles.wrapper}>
      {packageList && packageList.filter(p => p.name !== 'Shareholder').map(({
        id,
        name,
        amount_from,
        amount_to,
        daily_interest,
        days,
        direct_bonus,
        binary_bonus,
      }, index) => 
        <div
          className={`
            ${styles.card}
            ${selectedPackage.index === index && styles.active}
            ${!collapsed || expanded === index ? null : styles.cardCollapsed}
          `}
          onClick={() => handleCardClick(index)}
          style={selectedPackage.index === index ? {backgroundColor: colors[index]} : null}
          key={id}
        >
          <div>
          <div
            className={`${styles.title} ${styles.compactTitle} ${name === 'Canna Hold' ? styles.canna : null}`}
            style={{ backgroundColor: colors[index] }}
            onClick={() => handleTitleClick(index)}
          >
            {name === 'Canna Hold' && !isMobile ?
            <div
              className={styles.tip}
              style={{ borderColor: colors[index] }}
            >
              <Trans
                t={t}
                i18nKey={"help-cannabe-hold"}
                components={[<span className={styles.bold}/>, <span style={{ color: colors[index] }}/>]}
              /> 
              <div style={{ borderBottomColor: colors[index] }} className={styles.triangle} />
            </div> : null}
            <p>
              {name}
              {name === 'Canna Hold' ? <img className={styles.infoIcon} src={infoIcon} /> : null}
            </p>
            <div className={styles.dailyMob}>
              <p className={styles.name}>
                {t('daily-interest')}
              </p>
              <p className={styles.value}>
                {`${daily_interest}%`}
              </p>
            </div>
            <img className={styles.expandIcon} src={arrowExpand} style={{ filter: 'invert(100%)' }} />
          </div>
          <div className={styles.cost}>
            <img className={styles.coin} src={coin}/>
            <p className={styles.value}>
              {amount_from} - {amount_to || '∞'}
            </p>
          </div>
          <div className={styles.dailyInterest}>
            <p className={styles.name}>
            {t('daily-interest')}
            </p>
            <p className={styles.value}>
              {`${daily_interest}%`}
            </p>
          </div>
          <div className={styles.days}>
            <p className={styles.name}>
              {t('days')}
            </p>
            <p className={styles.value}>
              {typeof days === 'number' ? `${days} days` : days}
            </p>
          </div>
          <img className={styles.logo} src={logo}/>
          </div>
          <Button
            className={styles.button}
          >
            {t('select-packages')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default withTranslation('packages')(PackagesCards);
