const FinanceIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M18.0324 12.5704H14.6588C13.4201 12.5697 12.4161 11.5664 12.4153 10.3277C12.4153 9.08899 13.4201 8.08572 14.6588 8.08496H18.0324" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.0405 10.2762H14.7808" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.45637 3.07422H13.6592C16.0744 3.07422 18.0323 5.03214 18.0323 7.44727V13.4281C18.0323 15.8433 16.0744 17.8012 13.6592 17.8012H6.45637C4.04124 17.8012 2.08331 15.8433 2.08331 13.4281V7.44727C2.08331 5.03214 4.04124 3.07422 6.45637 3.07422Z" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.86298 6.8563H10.3621" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default FinanceIcon;
