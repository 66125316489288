import styles from "./DownloadButton.module.css";
import { DownloadIcon } from "components/ui/icons";
import { useMaterialsQuery } from "redux/api/common";

const DownloadButton = ({ className }) => {
  const { data } = useMaterialsQuery();

  return (
    <a className={`${styles.wrapper} ${className}`} href={data?.items[0].url || "#"}>
      <DownloadIcon className={styles.icon} />
    </a>
  );
};

export default DownloadButton;
