import React, { useState } from 'react';
import styles from './Dropdown.module.css';
import iconLight from 'assets/images/icons/arrow-down.svg';
import iconDark from 'assets/images/icons/arrow-down_dark.svg';

const Dropdown = ({
  options,
  onSelect,
  openAction="click", // click | hover
  variant="blur", // blur | light
  defaultValue
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  console.log(variant)

  return (
    <div
      className={styles.dropdownWrapper}
      onMouseEnter={handleToggle}
      onMouseLeave={handleToggle}
    >
      <div className={styles.selectedOption}>
        <span>{defaultValue}</span>
        <img src={variant === 'blur' ? iconLight : iconDark} />
        {/*selectedOption ? (
          <div>
            {selectedOption.image && <img src={selectedOption.image} alt={selectedOption.label} className={styles.optionImage} />}
            <span>{selectedOption.label}</span>
          </div>
        ) : (
          'Select an option'
        )*/}
      </div>
      {isOpen && <div className={styles.dropdownBlock}/>}
      {isOpen && (
        <ul className={`${styles.optionsList} ${styles[variant]}`}>
          {options.map((option) => (
            <li
              key={option.value}
              className={styles.option}
              onClick={() => handleOptionSelect(option)}
            >
              {option.image && <img src={option.image} alt={option.label} className={styles.optionImage} />}
              <span>{option.label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
