import LettersComponent from "components/LettersComponent";
import styles from "./AdditionalInfo.module.css"

const AdditionalInfo = ({ children, letters = true, backgroundImg = false }) => {

  return (
    <section className={styles.wrapper}>
      {children}
      {letters && <LettersComponent />}
      {backgroundImg && <img src={backgroundImg}/>}
    </section>
  )
}

export default AdditionalInfo;