import { useEffect } from "react";
import { useNavigateLang } from "hooks";
import { AuthPageContainer, LoginForm } from "components"

const Login = () => {
    const navigate = useNavigateLang();

    useEffect(() => {
        navigate('/');
    }, [navigate]);
  return (
    <div>
      <AuthPageContainer>
        <LoginForm />
      </AuthPageContainer>
    </div>
  )
}

export default Login
