import Card from "./Card";
import styles from "./Roadmap.module.css";
import {ArrowRightIcon} from "components/ui/icons";
import {Button, Reveal} from "components";
import { useLocation } from "react-router-dom";
import HorizontalScrollContainer from "components/containers/horisontal";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { useRef, useState } from "react";
import { withTranslation } from "react-i18next";

const Roadmap = ({ t, hideButtons=false }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const prefixBuilder = (str) => {
    const [num, letter] = str.split(" ");
    return `${num}${letter[0]}`;
  };

  const cardList = [
    {
      title: t("roadmap-card1-title"),
      suptitle: t("roadmap-card1-suptitle"),
      description: t("roadmap-card1-description"),
      footerPrefix: prefixBuilder(t("roadmap-card1-suptitle")),
      footerContent: t("roadmap-card1-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card2-title"),
      suptitle: t("roadmap-card2-suptitle"),
      description: t("roadmap-card2-description"),
      footerPrefix: prefixBuilder(t("roadmap-card2-suptitle")),
      footerContent: t("roadmap-card2-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card3-title"),
      suptitle: t("roadmap-card3-suptitle"),
      description: t("roadmap-card3-description"),
      footerPrefix: prefixBuilder(t("roadmap-card3-suptitle")),
      footerContent: t("roadmap-card3-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card4-title"),
      suptitle: t("roadmap-card4-suptitle"),
      description: t("roadmap-card4-description"),
      footerPrefix: prefixBuilder(t("roadmap-card4-suptitle")),
      footerContent: t("roadmap-card4-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card5-title"),
      suptitle: t("roadmap-card5-suptitle"),
      description: t("roadmap-card5-description"),
      footerPrefix: prefixBuilder(t("roadmap-card5-suptitle")),
      footerContent: t("roadmap-card5-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card6-title"),
      suptitle: t("roadmap-card6-suptitle"),
      description: t("roadmap-card6-description"),
      footerPrefix: prefixBuilder(t("roadmap-card6-suptitle")),
      footerContent: t("roadmap-card6-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card7-title"),
      suptitle: t("roadmap-card7-suptitle"),
      description: t("roadmap-card7-description"),
      footerPrefix: prefixBuilder(t("roadmap-card7-suptitle")),
      footerContent: t("roadmap-card7-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card8-title"),
      suptitle: t("roadmap-card8-suptitle"),
      description: t("roadmap-card8-description"),
      footerPrefix: prefixBuilder(t("roadmap-card8-suptitle")),
      footerContent: t("roadmap-card8-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card9-title"),
      suptitle: t("roadmap-card9-suptitle"),
      description: t("roadmap-card9-description"),
      footerPrefix: prefixBuilder(t("roadmap-card9-suptitle")),
      footerContent: t("roadmap-card9-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card10-title"),
      suptitle: t("roadmap-card10-suptitle"),
      description: t("roadmap-card10-description"),
      footerPrefix: prefixBuilder(t("roadmap-card10-suptitle")),
      footerContent: t("roadmap-card10-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card11-title"),
      suptitle: t("roadmap-card11-suptitle"),
      description: t("roadmap-card11-description"),
      footerPrefix: prefixBuilder(t("roadmap-card11-suptitle")),
      footerContent: t("roadmap-card11-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card12-title"),
      suptitle: t("roadmap-card12-suptitle"),
      description: t("roadmap-card12-description"),
      footerPrefix: prefixBuilder(t("roadmap-card12-suptitle")),
      footerContent: t("roadmap-card12-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card13-title"),
      suptitle: t("roadmap-card13-suptitle"),
      description: t("roadmap-card13-description"),
      footerPrefix: prefixBuilder(t("roadmap-card13-suptitle")),
      footerContent: t("roadmap-card13-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card14-title"),
      suptitle: t("roadmap-card14-suptitle"),
      description: t("roadmap-card14-description"),
      footerPrefix: prefixBuilder(t("roadmap-card14-suptitle")),
      footerContent: t("roadmap-card14-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card15-title"),
      suptitle: t("roadmap-card15-suptitle"),
      description: t("roadmap-card15-description"),
      footerPrefix: prefixBuilder(t("roadmap-card15-suptitle")),
      footerContent: t("roadmap-card15-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card16-title"),
      suptitle: t("roadmap-card16-suptitle"),
      description: t("roadmap-card16-description"),
      footerPrefix: prefixBuilder(t("roadmap-card16-suptitle")),
      footerContent: t("roadmap-card16-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card17-title"),
      suptitle: t("roadmap-card17-suptitle"),
      description: t("roadmap-card17-description"),
      footerPrefix: prefixBuilder(t("roadmap-card17-suptitle")),
      footerContent: t("roadmap-card17-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card18-title"),
      suptitle: t("roadmap-card18-suptitle"),
      description: t("roadmap-card18-description"),
      footerPrefix: prefixBuilder(t("roadmap-card18-suptitle")),
      footerContent: t("roadmap-card18-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card19-title"),
      suptitle: t("roadmap-card19-suptitle"),
      description: t("roadmap-card19-description"),
      footerPrefix: prefixBuilder(t("roadmap-card19-suptitle")),
      footerContent: t("roadmap-card19-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card20-title"),
      suptitle: t("roadmap-card20-suptitle"),
      description: t("roadmap-card20-description"),
      footerPrefix: prefixBuilder(t("roadmap-card20-suptitle")),
      footerContent: t("roadmap-card20-footerContent"),
      isEven: true,
    },
    {
      title: t("roadmap-card21-title"),
      suptitle: t("roadmap-card21-suptitle"),
      description: t("roadmap-card21-description"),
      footerPrefix: prefixBuilder(t("roadmap-card21-suptitle")),
      footerContent: t("roadmap-card21-footerContent"),
      isEven: false,
    },
    {
      title: t("roadmap-card22-title"),
      suptitle: t("roadmap-card22-suptitle"),
      description: t("roadmap-card22-description"),
      footerPrefix: prefixBuilder(t("roadmap-card22-suptitle")),
      footerContent: t("roadmap-card22-footerContent"),
      isEven: true,
    },
  ];

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const location = useLocation();
  const isPadding = ["/"];

  const handleDownload = () => {
    // Assuming '/path/to/your/file.pdf' is the path to the file you want to download
    const link = document.createElement('a');
    link.href = '/roadmap.pdf';
    link.setAttribute('download', 'Roadmap.pdf');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
      <section
          className={`${styles.roadmap} ${
              isPadding.includes(location.pathname) && styles.padding
          }`}
      >
        <div className={styles.head}>
          <div className={styles.titleWrapper}>
            <Reveal>
              <h2 className={`${styles.title}`}>{t("company")}</h2>
              <h2 className={`${styles.title}`}>
                <span className="accent-text">{t("roadmap")}</span>
              </h2>
            </Reveal>
          </div>
          <h3 className={styles.years}>{t("roadmap-years")}</h3>
        </div>
        <div className={styles.roadmapButtons}>
          <div className={styles.buttons}>
            <button className={styles.button} onClick={handlePrev}>
              <ArrowRightIcon style={{transform: "rotate(180deg)"}}/>
            </button>
            <button className={styles.button} onClick={handleNext}>
              <ArrowRightIcon/>
            </button>
          </div>
          <h3 className={styles.buttonYears}>2019-2024</h3>
        </div>
        <div className={styles.cards}>
          <div className={styles.cardsList}>
            <Swiper
                ref={swiperRef}
                spaceBetween={30}
                slidesPerView={1}
                className="productsSwiper"
                onSlideChange={(e) => setActiveIndex(e.activeIndex)}
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 2,
                  },
                  // when window width is >= 1024px
                  1024: {
                    slidesPerView: 3,
                  },
                  // when window width is >= 1366px
                  1366: {
                    slidesPerView: 4,
                  },
                }}
            >
              {cardList.map((card, index) => (
                  <SwiperSlide key={index}>
                    <Card key={index} {...card} hideButtons={hideButtons}/>
                  </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className={styles.button_download}>
          <Button onClick={handleDownload} size="big" color variant="primary">{t('roadmap-button')}</Button>
        </div>
      </section>
  );
};

export default withTranslation("main")(Roadmap);
