import Button from "components/ui/Button";
import TextIntput from "components/ui/TextInput";
import styles from "./PackagesSeeds.module.css"
import { useForm } from 'react-hook-form';
import { WalletIcon, CoinIcon } from "components/ui/icons";
import coin from "assets/images/icons/coin.svg";
import { useBuyPackageMutation, usePackagesAllQuery, usePackagesCalcQuery } from "redux/api/common";
import { useSelector } from "react-redux";
import { useState } from "react";
import BuyPackage from "components/Modals/BuyPackage";
import { withTranslation, Trans } from "react-i18next";
import { toast } from 'react-toastify';
import { useEffect } from "react";
import InsufficientFunds from "components/Modals/InsufficientFunds";

const PackagesSeeds = ({ t }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: 50,
    }
  });
  const [amount, setAmount] = useState(getValues().amount);
  const [modalOpen, setModalOpen] = useState();
  const [openError, setOpenError] = useState();
  const { selectedPackage } = useSelector(state => state.common);
  const { data } = usePackagesCalcQuery({ packageId: selectedPackage.id, amount });
  const [buyPackage, { isLoading, error }] = useBuyPackageMutation();
  const { data : packages } = usePackagesAllQuery();
  const isMobile = window.outerWidth < 1200 ? true : false;

  useEffect(()=>{
    if(!packages) return;
    setAmount(packages[selectedPackage.index].amount_from.replace(/\s/g, ""));
  },[selectedPackage])

  const onSubmit = async ({ code }) => {
    try {
      const resp = await buyPackage({ packageId: selectedPackage.id, amount, code });

      if (resp?.data?.success === 'ok') {
        setModalOpen(true);
      } else {
        const message = resp?.data?.error || resp?.error?.data?.message || 'Something went wrong';
        setOpenError(true);
	      // toast(message, { type: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // if (error) {
    //   if(error?.status === 500) {
	   //    toast('Something went wrong', { type: 'error' });
    //   } else {
    //     const message = error?.data?.message || 'Something went wrong';
	   //    toast(message, { type: 'error' });
    //   }
    // }
  }, [error]);

  const cards = [
    {
      title: t('per-week'),
      name: t('week-interest'),
      interest: data?.calc.week.percent,
      can: data?.calc.week.amount,
    },
    {
      title: t('per-month'),
      name: t('month-interest'),
      interest: data?.calc.month.percent,
      can: data?.calc.month.amount,
    },
    {
      title: t('full-period'),
      name: t('full-period-interest'),
      interest: data?.calc.full.percent,
      can: data?.calc.full.amount,
    }
  ]

  return(
    <>
      <div className={styles.wrapper} id='buy-form'>
        <div className={styles.subWrapper}>
          <div className={styles.top}>
            <div className={styles.text}>
              <p>{t('to-buy-package')}</p>
              <h2>{selectedPackage.name}</h2>
            </div>
            <Button className={styles.button} variant='accent' disabled>{selectedPackage.name} package</Button>
          </div>
          {selectedPackage.id === 3 && isMobile ?
            <div
              className={styles.tip}
              style={{ borderColor: "#6F7DD7" }}
            >
              *<Trans
                t={t}
                i18nKey={"help-cannabe-hold"}
                components={[<span className={styles.bold}/>, <span style={{ color: "#6F7DD7" }}/>]}
              /> 
            </div> : null}
          <div className={styles.bottom}>
            <p>{t('amount')}</p>
            <form className={styles.input} onSubmit={handleSubmit(onSubmit)}>
              <TextIntput
                name={'amount'}
                type='number'
                register={register}
                placeholder="50"
                icon={<CoinIcon className={styles.coinIcon}/>}
                value={amount}
                onChange={e => setAmount(e.target.value)}
                errors={errors}
                validationSchema={{
                  required: 'Fill this field!',
                }}
                withReqStar={false}
              />
              {/*<TextIntput
                name={'code'}
                placeholder={t('promo-code')}
                register={register}
                errors={errors}
              />*/}
              <Button className={styles.button} type="submit" variant='accent' size='big' isLoading={isLoading}>{t('buy-package')}</Button>
            </form>
          </div>
        </div>

        <div className={styles.cards}>
          {cards.map((card, index) => 
            <div key={index} className={styles.card}>
              <div className={styles.title}>
                <p>{card.title}</p>
                <div>
                  <WalletIcon/>
                </div>
              </div>

              <div className={styles.first}>
                <p className={styles.name}>{card.name}</p>
                <p className={styles.value}>{`${card.interest}%`}</p>
              </div>

              <div className={styles.second}>
                <p className={styles.name}>{`${card.name} CAN`}</p>
                <div>
                  <img className={styles.coin} src={coin}></img>
                  <p className={styles.value}>{`${card.can} CAN`}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <BuyPackage active={modalOpen} setActive={setModalOpen} withClose text={t('successfully-buy')} />
      <InsufficientFunds active={openError} setActive={setOpenError} />
    </>
  )
}

export default withTranslation('packages')(PackagesSeeds);
