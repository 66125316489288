import { CabinetHeader, Sidebar } from 'components';
import { useNavigateLang } from 'hooks';
import { useEffect } from 'react';
import styles from './CabinetContainer.module.css';

const CabinetContainer = ({ children }) => {
  const navigate = useNavigateLang();

    const handleResize = () => {
      const widget =  document.querySelector('#freshworks-container');

      if (window.outerWidth <= 805 && widget) {
        widget.style.display = 'none';
      } else if (window.outerWidth > 805 && widget) {
        widget.style.display = 'block';
      }
    };

  useEffect(() => {
    return
    if (window.outerWidth <= 805) {
      return;
    }

    const script = document.createElement('script');
    const externalScript = document.createElement('script');

    script.innerHTML = `
      window.fwSettings = {
        'widget_id': 151000000754
      };
      !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
    `;

    externalScript.src = 'https://widget.freshworks.com/widgets/151000000754.js';
    externalScript.async = true;
    externalScript.defer = true;

    document.body.appendChild(script);
    document.body.appendChild(externalScript);

    handleResize();

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(externalScript);
      const container = document.querySelector('#freshworks-container');

      if (container) {
        container.remove();
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const authToken = localStorage.getItem('auth_token');

    if (!authToken) {
      navigate('/login');
    }
  }, []);

  return (
    <div className={styles.container}>
      <Sidebar className={styles.sidebar} />
      <section className={styles.content}>
        <CabinetHeader />
        <div className={styles.wrapper}>
          {children}
        </div>
      </section>
      
      {window.outerWidth >= 805 && 
        <a href="https://t.me/CannaBeSupport" target='_blank' className={styles.help}>
          <span className={styles.circle}>?</span> Help
        </a>
      }
    </div>
  );
};

export default CabinetContainer;
