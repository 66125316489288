import Card from "./Card";
import styles from "./ProductsInfo.module.css";
import card1 from "assets/images/card-1-normalized.png";
import card2 from "assets/images/card-2-normalized.png";
import card3 from "assets/images/card-3-normalized.png";
import card4 from "assets/images/card-4-normalized.png";
import { withTranslation, Trans } from "react-i18next";

const ProductsInfo = ({ t }) => {
  const cardList = [
    {
      number: "01",
      title: t('card-title'),
      name: t('card1-name'),
      description: t('card1-description'),
      image: card1,
      modal: 'oil',
    },
    {
      number: "02",
      title: t('card-title'),
      name: t('card2-name'),
      description: t('card2-description'),
      image: card2,
      modal: 'capsules',
    },
    {
      number: "03",
      title: t('card-title'),
      name: t('card3-name'),
      description: t('card3-description'),
      image: card3,
      modal: 'bears',
    },
    {
      number: "04",
      title: t('card-title'),
      name: t('card4-name'),
      description: t('card4-description'),
      image: card4,
      modal: 'cream',
    },
  ];

  return (
    <section className={styles.info}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>
          {" "}
          <Trans
            t={t}
            i18nKey="products-info-title"
            components={[<span className="accent-text" />]}
          />
        </h2>
      </div>
      <div className={styles.cardList}>
        {cardList.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </section>
  );
};

export default withTranslation('our-products')(ProductsInfo);
