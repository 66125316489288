import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from 'helpers/constants/api';

const baseQueryWithCsrfToken = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers) => {
    const authToken = localStorage.getItem('auth_token');
    headers.set('Authorization', `Bearer ${authToken}`);
    return headers;
  },
});

export const tfaApi = createApi({
  reducerPath: 'tfaApi',
  tagTypes: ['tfa'],
  baseQuery: baseQueryWithCsrfToken,
  endpoints: build => ({
    formTfa: build.query({
      query: () => ({
        url: '2fa/form',
        method: 'POST',
      }),
    }),
    enableTfa: build.mutation({
      query: params => ({
        url: '2fa/enable',
        method: 'POST',
        params,
      }),
    }),
    removeTfa: build.mutation({
      query: params => ({
        url: '2fa/remove',
        method: 'POST',
        params,
      }),
    }),
  })
});

export const {
  useFormTfaQuery,
  useEnableTfaMutation,
  useRemoveTfaMutation,
} = tfaApi;
