import styles from './InfoBox.module.css';

const InfoBox = ({
  variant="filled", // filled | outline
  icon,
  text,
  className,
}) => {
  return (
    <div className={`${styles.infoBox} ${styles[variant]} ${className}`}>
      <img className={styles.icon} src={icon} />
      <span className={styles.text}>{text}</span>
    </div>
  );
}

export default InfoBox;
