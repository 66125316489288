const TeamIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path d="M15.4064 9.08142C16.5689 8.91809 17.4639 7.92142 17.4664 6.71392C17.4664 5.52392 16.5989 4.53725 15.4614 4.35059" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.9404 11.876C18.0663 12.0443 18.8521 12.4385 18.8521 13.251C18.8521 13.8101 18.4821 14.1735 17.8838 14.4018" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4056 12.2207C7.72726 12.2207 5.43976 12.6265 5.43976 14.2474C5.43976 15.8674 7.71309 16.2849 10.4056 16.2849C13.0839 16.2849 15.3706 15.8832 15.3706 14.2615C15.3706 12.6399 13.0981 12.2207 10.4056 12.2207Z" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4056 9.90699C12.1631 9.90699 13.5881 8.48283 13.5881 6.72449C13.5881 4.96699 12.1631 3.54199 10.4056 3.54199C8.64808 3.54199 7.22308 4.96699 7.22308 6.72449C7.21641 8.47616 8.63058 9.90116 10.3822 9.90699H10.4056Z" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.40399 9.08142C4.24066 8.91809 3.34649 7.92142 3.34399 6.71392C3.34399 5.52392 4.21149 4.53725 5.34899 4.35059" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.86992 11.876C2.74409 12.0443 1.95825 12.4385 1.95825 13.251C1.95825 13.8101 2.32825 14.1735 2.92659 14.4018" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default TeamIcon;
