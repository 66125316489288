import Roadmap from "../Roadmap";
import styles from "./main.module.css";
import Intro from "../Intro";
import Corp from "../Corp";
import OurProducts from "../OurProducts";
import Margin from "../Margin";

const MainPage = () => {
  return (
    <section className={styles.wrapper}>
      <Intro />
      <Corp />
      <OurProducts />
      <Margin margin={-20} />
      <div className={styles.container}>
        <Roadmap />
      </div>
    </section>
  );
};

export default MainPage;
