import styles from './CabinetHeader.module.css';
import { LangSelector, Button, Burger } from 'components';
import InfoBox from './InfoBox';
import coinIcon from 'assets/images/icons/coin.svg';
import logo3 from 'assets/images/logo-3.svg';
import { CopyIcon, LogoutIcon, SettingIcon } from 'components/ui/icons';
import { useDashboardQuery, useFinanceQuery } from 'redux/api/common';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from 'redux/slices/commonSlice';
import { withTranslation } from 'react-i18next';
import { useNavigateLang } from 'hooks';
import { SITE_URL } from 'helpers/constants/common';

const CabinetHeader = ({ t, i18n }) => {
  const navigate = useNavigateLang();
  const { isSidebarOpen } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const { data } = useDashboardQuery();
  const { data: financeData } = useFinanceQuery();
  const [copyBtnText, setCopyBtnText] = useState(t('copy-refferal-link'));

  const handleCopyReferralCode = async () => {
    try {
      const referralLink = `${SITE_URL}${i18n.language}/pro?referral=${data?.user?.referral?.code}`;
      await navigator.clipboard.writeText(referralLink);
      await navigator.clipboard.writeText(referralLink);
      setCopyBtnText(t('copied-refferal-link'));
    } catch (error) {
      console.error(error);
    }
  }

  const logout = () => {
    localStorage.removeItem('auth_token');
    navigate('/');
    window.location.reload();
  };

  const handleDepositClick = () => {
    navigate('/finance#deposit-block');
  };

  return (
    <>
      <header className={styles.header__desktop}>
        <div className={styles.section}>
          <div className={styles.burgerContainer}>
            <Burger open={isSidebarOpen} onClick={() => dispatch(toggleSidebar())} />
          </div>
          <InfoBox text={`1 CAN = ${financeData?.deposit?.rate_deposit} $`} icon={coinIcon} />
          <InfoBox text={`Balance: ${data?.user?.balance || 0}`} icon={coinIcon} />
          <Button
            className={styles.button}
            variant='accent'
            onClick={() => navigate('/packages')}
          >
            {t('buy-package')}
          </Button>
        </div>
        <div className={styles.section}>
          <Button
            variant='accent'
            icon={<CopyIcon style={{ filter: 'invert(100%)' }} />}
            onClick={handleCopyReferralCode}
          >
            {copyBtnText}
          </Button>
          <LangSelector variant='dark' className={styles.langSelector} />
          <Button onClick={() => navigate('/settings')} className={`${styles.button} ${styles.iconButton}`} variant='outline-filled' icon={<SettingIcon />} />
          <Button onClick={logout} className={`${styles.button} ${styles.iconButton}`} variant='outline-filled' icon={<LogoutIcon />} />
        </div>
      </header>
      <header className={styles.header__mobile}>
        <div className={`${styles.row} ${styles.top}`}>
          <div className={styles.rowSide}>
            <div className={styles.burgerContainer}>
              <Burger open={isSidebarOpen} onClick={() => dispatch(toggleSidebar())} />
            </div>
            <img
              className={`${styles.mobBox} ${styles.logo3}`}
              src={logo3}
              onClick={() => navigate('/')}
            />
          </div>
          <div className={styles.rowSide}>
            <LangSelector withIcon={false} variant='dark' className={styles.langSelector} />
            <Button onClick={() => navigate('/settings')} className={`${styles.button} ${styles.iconButton}`} variant='outline-filled' icon={<SettingIcon />} />
            <Button onClick={logout} className={`${styles.button} ${styles.iconButton}`} variant='outline-filled' icon={<LogoutIcon />} />
          </div>
        </div>
        <div className={`${styles.row} ${styles.mid}`}>
          <InfoBox className={styles.infoBox} text='1 CAN = 1 $' icon={coinIcon} />
          <InfoBox className={styles.infoBox} text={`${t('balance')}: ${data?.user?.balance || 0}`} icon={coinIcon} />
        </div>
        <div className={`${styles.row} ${styles.bottom}`}>
          <Button onClick={handleDepositClick} className={styles.button__mobile} variant='accent'>Deposit</Button>
        </div>
      </header>
    </>
  );
}

export default withTranslation('cabinet-header')(CabinetHeader);
