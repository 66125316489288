import { ProfileIcon } from "components/ui/icons";
import { useBinaryChangePositionMutation, useBinaryQuery } from "redux/api/common";
import styles from "./BinaryTree.module.css";
import { formatNode } from "helpers/utils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBinaryBaseData } from "redux/slices/commonSlice";

const START_LVL = 1;
const LVL_COUNT = 4;
const DEFAULT_COLOR = '#8F939C';

const BinaryTree = () => {
  const { data } = useBinaryQuery();
  const [binaryChangePosition, result] = useBinaryChangePositionMutation();
  const { binaryBaseData } = useSelector(state => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(setBinaryBaseData(data));
    }
  }, [data]);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      dispatch(setBinaryBaseData(result?.data));
    }
  }, [result]);

  const renderTree = (depth, node) => {
    if (depth === LVL_COUNT) {
      return null;
    }

    const { childes } = formatNode(node);

    return (
      <>
        <div className={styles.box}>
          <div className={styles.row}>
            <div className={`${styles.item} ${styles[`lvl${depth + 1}`]}`}>
              {childes?.left?.rank_index ? <div className={styles.badge}>{childes?.left?.rank_index}</div> : null}
              <div
                className={styles.icon}
                onClick={childes?.left?.login ? () => binaryChangePosition({ login: childes?.left?.login }) : null}
              >
                <ProfileIcon fill={childes?.left?.color_hex || DEFAULT_COLOR} />
              </div>
              <div className={styles.login}>{childes?.left?.login}</div>
            </div>
          </div>
          <div className={styles.row}>
            {renderTree(depth + 1, childes?.left)}
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.row}>
            <div className={`${styles.item} ${styles[`lvl${depth + 1}`]}`}>
              {childes?.right?.rank_index ? <div className={styles.badge}>{childes?.right?.rank_index}</div> : null}
              <div
                className={styles.icon}
                onClick={childes?.right?.login ? () => binaryChangePosition({ login: childes?.right?.login }) : null}
              >
                <ProfileIcon fill={childes?.right?.color_hex || DEFAULT_COLOR} />
              </div>
              <div className={styles.login}>{childes?.right?.login}</div>
            </div>
          </div>
          <div className={styles.row}>
            {renderTree(depth + 1, childes?.right)}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.binaryTree}>
        <div className={styles.scheme}>
          <div className={styles.row}>
            <div className={`${styles.item} ${styles.lvl1}`}>
              {binaryBaseData?.binary?.structure?.user?.rank_index ?
                <div className={styles.badge}>{binaryBaseData?.binary?.structure?.user?.rank_index}</div>
              : null}
              <div
                className={styles.icon}
                onClick={() => binaryChangePosition({ login: binaryBaseData?.binary?.structure?.user?.login })}
              >
                <ProfileIcon fill={binaryBaseData?.binary?.structure?.user?.color_hex || DEFAULT_COLOR} />
              </div>
              <div className={styles.login}>{binaryBaseData?.binary?.structure?.user?.login}</div>
            </div>
          </div>
          <div className={styles.row}>
            {renderTree(START_LVL, binaryBaseData?.binary?.structure)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BinaryTree;
