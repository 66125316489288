import styles from './Checkbox.module.css';
import { CheckboxBorderIcon, CheckboxIcon } from 'components/ui/icons';
  
export default function Checkbox({ label, checked, onChange, disabled, className, ...props }) {
  return (
      <label className={`${className} ${styles.checkbox}`}>
        <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
          <input
            type='checkbox'
            checked={checked}
            onChange={onChange}
            onClick={e => e.stopPropagation()}
            disabled={disabled}
            {...props}
          />
          {<CheckboxIcon className={styles.mark} />}
          {<CheckboxBorderIcon className={styles.border} />}
        </div>
        {label && <span className={styles.label}>{label}</span>}
      </label>
  );
};
