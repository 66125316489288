import { Button, Table } from "components";
import { useDeferredValue, useEffect, useState } from "react";
import { useAllHistoryQuery, useDepositHistoryQuery, useWithdrawalHistoryQuery } from "redux/api/common";
import styles from "./TransactionHistory.module.css";
import { ArrowRightIcon } from "components/ui/icons";
import Pagination from "./Pagination";
import { withTranslation } from "react-i18next";

const TransactionHistory = ({ t }) => {
  const { data: depositHistory } = useDepositHistoryQuery();
  const { data: withdrawalHistory } = useWithdrawalHistoryQuery();
  const { data: allHistory } = useAllHistoryQuery();
  const [history, setHistory] = useState(null);
  const [columns, setColumns] = useState(null);
  const [button, setButton] = useState('all');
  const [page, setPage] = useState(0);
  const itemsPerPage = window.innerWidth > 1200 ? 10 : 5;
  const [itemsOnPage, setItemsOnPage] = useState(null)

  const allColumns = [
    {
      title: 'ID',
      key: 'id',
    },
    {
      title: t('action'),
      key: 'action',
    },
    {
      title: t('amount'),
      key: 'amount',
    },
    {
      title: t('name'),
      key: 'name',
    },
    {
      title: t('package'),
      key: 'package.name',
    },
    {
      title: t('time_date'),
      key: 'date',
    },
  ];
  const withdrawalColumns = [
    {
      title: 'ID',
      key: 'id',
    },
    {
      title: t('address'),
      key: 'address',
    },
    {
      title: t('currency'),
      key: 'currency.name'
    },
    {
      title: t('amount'),
      key: 'amount',
    },
    {
      title: t('fee-amount'),
      key: 'user_amount',
    },
    {
      title: t('status'),
      key: 'status'
    },
    {
      title: t('time_date'),
      key: 'date',
    },
  ]
  const depositColumns = [
    {
      title: 'ID',
      key: 'id',
    },
    {
      title: t('amount'),
      key: 'amount',
    },
    {
      title: t('currency'),
      key: 'currency.name'
    },
    {
      title: t('user_amount'),
      key: 'user_amount'
    },
    {
      title: t('status'),
      key: 'status'
    },
    {
      title: t('time_date'),
      key: 'date',
    },
  ]

  useEffect(()=>{
    setPage(0);
  },[history])

  useEffect(()=>{
    if(!history){
      return;
    }

    let array = [];

    for(let i = 0; i < itemsPerPage; i++){
      if(history.items[i+(page*itemsPerPage)]){
        array.push(history.items[i+(page*itemsPerPage)])
      }
    }
    
    setItemsOnPage(array);
  },[history, page])

  const handleHistory = (name) => {
    switch(name){
      
      case 'all':
        setHistory(allHistory);
        setColumns(allColumns);
        setButton(name)
        break;
      case 'deposit':
        setHistory(depositHistory);
        setColumns(depositColumns);
        setButton(name)
        break;
      case 'withdrawal':
        setHistory(withdrawalHistory);
        setColumns(withdrawalColumns);
        setButton(name)
        break;
      default:
        setHistory(allHistory);
        setColumns(allColumns);
        setButton('all')
    }
  }

  useEffect(()=>{
    if(!history)
    setHistory(allHistory);
    setColumns(allColumns);
  },[allHistory])

  return (
    <div className={styles.transactionHistory}>
      <div className={styles.buttons}>
        <Button className={`${styles.button} ${button === 'all' ? styles.active : null}`} onClick={() => handleHistory('all')}>{t('all')}</Button>
        <Button className={`${styles.button} ${button === 'deposit' ? styles.active : null}`} onClick={() => handleHistory('deposit')}>{t('deposits')}</Button>
        <Button className={`${styles.button} ${button === 'withdrawal' ? styles.active : null}`} onClick={() => handleHistory('withdrawal')}>{t('withdrawal')}</Button>
      </div>
      {history && <Table columns={columns} data={itemsOnPage ? itemsOnPage : []}/>}
      <Pagination page={page} setPage={setPage} length={Math.floor(history?.items.length / itemsPerPage)}/>
    </div>
  );
};

export default withTranslation('finance')(TransactionHistory);
