const LogoutIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path d="M13.0133 6.1579V5.3804C13.0133 3.68457 11.6383 2.30957 9.9425 2.30957H5.88C4.185 2.30957 2.81 3.68457 2.81 5.3804V14.6554C2.81 16.3512 4.185 17.7262 5.88 17.7262H9.95083C11.6417 17.7262 13.0133 16.3554 13.0133 14.6646V13.8787" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.6746 10.0182H8.64041" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.2343 7.58887L18.6743 10.018L16.2343 12.448" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default LogoutIcon;
