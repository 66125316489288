import { CabinetContainer, MaterialsCards, MaterialsVideo } from 'components';
import { withTranslation } from 'react-i18next';

const Materials = ({ t }) => {
  return (
    <CabinetContainer>
      <div>
        <h1>{t('materials')}</h1>
        <MaterialsCards />
      </div>
      <div>
        <h1>{t('company-video-presentation')} </h1>
        <MaterialsVideo />
      </div>
    </CabinetContainer>
  );
}

export default withTranslation('materials')(Materials);
