import { StarIcon, TicketStarIcon } from 'components/ui/icons';
import { Button, CircularProgress, Progress } from 'components';
import styles from './DashboardCareerPromo.module.css';
import { useDashboardQuery } from 'redux/api/common';
import { useState, useEffect } from 'react';
import { withTranslation, Trans } from 'react-i18next';

const DashboardCareerPromo = ({ t }) => {
  const { data } = useDashboardQuery();
  const [isExpired, setIsExpired] = useState();

  useEffect(() => {
    if (data && parseInt(data?.promotion_bonus.days) === 0) {
      setIsExpired(true);
    }
  }, [data]);

  return (
    <section className={styles.dashboardCareerPromo}>
      <div className={styles.career}>
        <h2>{t('leadership-career')}</h2>
        <div className={styles.card}>
          <div className={styles.head}>
            <div className={styles.left}>
              <div className={styles.suptitle}>{t('rank')}</div>
              <div className={styles.title}>{data?.rank.name}</div>
            </div>
            <Button variant='outline-filled' icon={<StarIcon />} disableEffects />
          </div>
          <div className={styles.secondaryHead}>
            <div className={styles.suptitle}>{t('next-rank')}</div>
            <div className={styles.title}>{data?.rank.next.name}</div>
          </div>
          <div className={styles.conditionsWrapper}>
            <h3 className={styles.conditionsTitle}>{t('conditions')}</h3>
            <div className={styles.conditions}>
              <Progress
                value={data?.rank.next.personal_deposit.value}
                min={data?.rank.next.personal_deposit.value}
                max={data?.rank.next.personal_deposit.to}
                percent={data?.rank.next.personal_deposit.percent}
                label={t('personal-deposit')}
              />
              <Progress
                value={data?.rank.next.first_turnover.value}
                min={data?.rank.next.first_turnover.value}
                max={data?.rank.next.first_turnover.to}
                percent={data?.rank.next.first_turnover.percent}
                label={t('first-line-turnover')}
              />
              <Progress
                value={data?.rank.next.small_turnover.value}
                min={data?.rank.next.small_turnover.value}
                max={data?.rank.next.small_turnover.to}
                percent={data?.rank.next.small_turnover.percent}
                label={t('small-team-turnover')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.promo}>
        <h2>{t('promotions')}</h2>
        <div className={styles.card}>
          {isExpired && <div className={styles.expired} />}
          <div>
            <div className={styles.head}>
              <div className={styles.left}>
                <div className={styles.suptitle}>{t('do-not-miss-to-receive-you')}</div>
                <div className={styles.title}>{t('quick-start-bonus')}</div>
              </div>
              <Button className={styles.promoBtn} variant='outline' icon={<TicketStarIcon />} disableEffects />
            </div>
            <div className={styles.radialProgess}>
              <CircularProgress
                label='Left'
                progressLabel={`${data?.promotion_bonus.days} days`}
                progress={data?.promotion_bonus.percent}
                trackWidth={10}
                indicatorWidth={10}
                size={161}
              />
            </div>
          </div>
          <div>
            <div className={styles.text}>
              <Trans
                t={t}
                i18nKey={"10-days-to-receive"}
                components={[<b className="accent-text"/>, <b/>]}
              /> 
            </div>
            <div className={styles.progress}>
              <Progress variant='accent' min={0} max={10000} value={2500} label='First line turnover' />
            </div>
            <Button variant='accent' style={{ width: '100%' }}>{t('deposit')}</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('dashboard')(DashboardCareerPromo);
