import { CabinetContainer, DashboardCards, PackagesCards, PackagesInfo, PackagesSeeds } from 'components';
import { CoinIcon, TeamIcon, TetherIcon } from 'components/ui/icons';
import { withTranslation } from 'react-i18next';
import { useFinanceQuery } from 'redux/api/common';

const Packages = ({ t }) => {
  const { data } = useFinanceQuery();
  const cards = [
    {
      title: {
        text: `${t('packages')} ${t('income').toLowerCase()}`,
        icon: <TeamIcon />,
      },
      fields: [
        {
          key: `${t('income')} CAN`,
          value: {
            isBig: false,
            text: `${data?.profit_package.amount} CAN`,
            icons: [<CoinIcon />]
          }
        },
        {
          key: `${t('income')} $`,
          value: {
            isBig: true,
            text: `${data?.profit_package.usd} $`,
            icons: [<TetherIcon />]
          }
        },
      ],
    },
  ];


  return (
    <CabinetContainer>
      <div>
        <h1>{t('packages')}</h1>
        <PackagesCards />
      </div>
      <div>
        <PackagesSeeds/>
      </div>
      <div>
        <h2>{t('package-info')}</h2>
        {window.innerWidth <= 600 && 
          <DashboardCards cards={cards} style={{ marginBottom: '10px' }} />
        }
        <PackagesInfo/>
      </div>
    </CabinetContainer>
  );
}

export default withTranslation('packages')(Packages);
