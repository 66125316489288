import styles from './MaterialsCards.module.css';
import card1 from 'assets/images/card1.png';
import card2 from 'assets/images/card2.png';
import LangSelector from './LangSelector';
import { Button } from 'components';
import { DownloadIcon2 } from 'components/ui/icons';
import { useMaterialsQuery } from 'redux/api/common';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';

const MaterialsCards = ({ t }) => {
  const [language, setLanguage] = useState(0);
  const { data } = useMaterialsQuery();

  const cards = [
    {
      title: t('about-company'),
      suptitle: t('presentation'),
      img: card1,
      url: data?.items[0].url,
    },
    {
      title: t('marketing-plan'),
      suptitle: t('presentation'),
      img: card2,
      url: data?.items[1].url,
    },
  ];

  return (
    <section className={styles.materialsCards}>
      <div className={styles.cardsWrapper}>
        {cards.map(({ title, suptitle, img, url }, index) =>
          <div className={styles.card} key={index}>
            <div className={styles.head}>
              <div className={styles.left}>
                <div className={styles.suptitle}>{suptitle}</div>
                <div className={styles.title}>{title}</div>
              </div>
              <div className={styles.right}>
                <LangSelector
                  className={styles.langSelector}
                  selectedLanguage={language}
                  setSelectedLanguage={setLanguage}
                  variant='dark'
                  withIcon
                />
              </div>
            </div>
            <div className={styles.body}>
              <img className={styles.image} src={img} />
            </div>
            <div className={styles.actions}>
              <a target='_blank' href={url}>
              <Button variant="accent" size="big" icon={<DownloadIcon2 />}>{t('download')}</Button>
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default withTranslation('materials')(MaterialsCards);
