import AdditionalInfo from "../AdditionalInfo"
import styles from "./InvestmentIndustries.module.css"
import img from "assets/images/glassy-3.png"
import Card from "./Card"
import Reveal from "components/containers/animations/Reveal"
import { withTranslation } from "react-i18next"

const InvestmentIndustries = ({ t }) => {
  const cards = [
    {
      number: t('investment-card-2-number'),
      title: t('investment-card-2-title'),
      description: t('investment-card-2-description'),
      path: '/technical'
    },
    {
      number: t('investment-card-3-number'),
      title: t('investment-card-3-title'),
      description: t('investment-card-3-description'),
      path: '/medical'
    },
  ]
  return(
    <AdditionalInfo>
      <div className={styles.titleWrapper}>
        <Reveal>
          <p className={styles.title}>{t('investment-title-1')}</p>
        </Reveal>
        <Reveal>
          <p className={styles.title}><span className="white-accent-text">{t('investment-title-2')}</span></p>
        </Reveal>
      </div>
      <div className={styles.cards}>
        {cards.map((card, index) => 
          <Card
            {...card}
          />
        )}
      </div>
      <img className={styles.img} src={img}/>
    </AdditionalInfo>
  )
}

export default withTranslation('aboutus')(InvestmentIndustries);
