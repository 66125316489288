import styles from "./ChooseCoin.module.css"
import { TetherIcon, CoinIcon } from "components/ui/icons";
import { withTranslation } from "react-i18next";

const ChooseCoin = ({ currect, setCurrect, onSelect=Function.prototype, t }) => {
  const list = [
    {
      label: <span>USDT <br /> TRC-20</span>,
      icon: <TetherIcon />,
    },
    {
      label: <span>CAN <br /> TRC-20</span>,
      icon: <CoinIcon />,
    },
    // {
    //   label: <span>USDT <br /> ERC-20</span>,
    //   icon: <TetherIcon />,
    // },
  ];

  const handleSelect = index => {
    onSelect(index);
    setCurrect(index);
  };

  return(
    <div className={styles.chooseCoin}>
      <div className={styles.label}>{t('choose-coin')}</div>
      <div className={styles.itemsContainer}>
        {list.map(({ icon, label }, index) => 
          <div
            className={`${styles.coinItem} ${currect === index ? styles.active : null}`}
            onClick={() => handleSelect(index)}
            key={index}
          >
            {icon}
            {label}
          </div>
        )}
      </div>
    </div>
  )
}

export default withTranslation('finance')(ChooseCoin);
