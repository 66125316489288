import styles from "./LogoCard.module.css"
import logo1 from "assets/images/logo/HQD-logo.png"
import logo2 from "assets/images/logo/LordJones-logo.png"
import logo3 from "assets/images/logo/Ignite-logo.png"
import logo4 from "assets/images/logo/Union-logo.png"
import logo5 from "assets/images/logo/Heliospectra-logo.png"
import logo6 from "assets/images/logo/IDC-logo.png"
import logo7 from "assets/images/logo/CannabisClean-logo.png"
import logo8 from "assets/images/logo/Sandoz-logo.png"
import { useState, useRef, useEffect } from "react";

const LogoCard = () => {
  const [load, setLoad] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const { top } = container.getBoundingClientRect();
        const screenHeight = window.innerHeight;

        const offsetY = top - screenHeight;

        setLoad(offsetY < 0);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logos = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8
  ]
  return(
    <div className={styles.wrapper} ref={containerRef}>
      <div className={`${styles.logos} ${load && styles.onLoad}`}>
        {logos.map((logo, index) => 
          <div className={styles.logo}>
            <img src={logo}/>
          </div>
        )}
      </div>
    </div>
  )
}

export default LogoCard
