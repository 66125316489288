import styles from "./Card.module.css"

const Card = ({ title, description }) => {
  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}><span className="accent-text">{description}</span></p>
    </div>
  )
}

export default Card;