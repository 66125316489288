const PlayIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.0007 2.5835C14.3722 2.5835 17.9173 6.12779 17.9173 10.5002C17.9173 14.8725 14.3722 18.4168 10.0007 18.4168C5.62828 18.4168 2.08398 14.8725 2.08398 10.5002C2.08398 6.12779 5.62828 2.5835 10.0007 2.5835Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5007 10.496C12.5007 9.82001 9.03609 7.6576 8.64308 8.04641C8.25006 8.43523 8.21227 12.52 8.64308 12.9455C9.07388 13.3724 12.5007 11.1719 12.5007 10.496Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default PlayIcon;
