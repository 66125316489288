import { LettersComponent } from "components";
import styles from "./OurProducts.module.css";
import Card from "./Card";
import { ArrowRightIcon } from "components/ui/icons";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import card1 from "assets/images/card-1.png";
import card2 from "assets/images/card-2.png";
import card3 from "assets/images/card-3.png";
import card4 from "assets/images/card-4.png";
import { useState, useRef } from "react";
import { withTranslation } from "react-i18next";

const OurProducts = ({ t }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const cardList = [
    {
      title: t('products-card1-title'),
      subtitle: t('products-card1-subtitle'),
      description: t('products-card1-description'),
      image: card1,
    },
    {
      title: t('products-card2-title'),
      subtitle: t('products-card2-subtitle'),
      description: t('products-card2-description'),
      image: card2,
    },
    {
      title: t('products-card3-title'),
      subtitle: t('products-card3-subtitle'),
      description: t('products-card3-description'),
      image: card3,
    },
    {
      title: t('products-card4-title'),
      subtitle: t('products-card4-subtitle'),
      description: t('products-card4-description'),
      image: card4,
    },
  ];

  return (
    <section className={styles.ourProducts}>
      <div className={styles.ourProductsContainer}>
        <div className={styles.head}>
          <div className={styles.headings}>
            <h2 className={styles.title}>{t('our')}</h2>
            <h2 className={styles.title}>{t('products')}</h2>
          </div>
          <div className={styles.buttons}>
            <button className={`${styles.button} ${activeIndex === 0 || styles.lastItemButton}`} onClick={handlePrev}>
              <ArrowRightIcon />
            </button>
            <button className={`${styles.button} ${activeIndex === cardList.length - 2 || styles.lastItemButton}`} onClick={handleNext}>
              <ArrowRightIcon />
            </button>
          </div>
        </div>
        <div className={styles.cardsContainer}>
          <div></div>
          <div className={styles.slides}>
            <Swiper
              ref={swiperRef}
              spaceBetween={30}
              slidesPerView={2}
              className="productsSwiper"
              onSlideChange={(e) => setActiveIndex(e.activeIndex)}
            >
              {cardList.map((card, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    {...card}
                    hide={index === 0 && activeIndex >= 2}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.cards}>
            {cardList.map((card, index) => (
              <Card key={index} {...card} />
            ))}
          </div>
        </div>
      </div>
      <LettersComponent
        counts={cardList.length}
        percent={(100 / (cardList.length - 2)) * (activeIndex + 0)}
      />
    </section>
  );
};

export default withTranslation('main')(OurProducts);
