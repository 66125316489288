const MaterialsIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M13.0968 14.0934H7.08014" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.0968 10.6042H7.08014" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.37609 7.1237H7.08026" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2572 2.86523C13.2572 2.86523 6.85965 2.86857 6.84965 2.86857C4.54965 2.88273 3.12549 4.39607 3.12549 6.7044V14.3677C3.12549 16.6877 4.56049 18.2069 6.88049 18.2069C6.88049 18.2069 13.2772 18.2044 13.288 18.2044C15.588 18.1902 17.013 16.6761 17.013 14.3677V6.7044C17.013 4.3844 15.5772 2.86523 13.2572 2.86523Z" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default MaterialsIcon;
