import styles from './Intro.module.css';
import { PlayIcon } from 'components/ui/icons';
import { Button, LettersComponent, Reveal, UltimateModal } from 'components';
import { withTranslation, Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';

const Intro = ({ t }) => {
  const { i18n } = useTranslation();
  const [videoModalOpen, setVideoModalOpen] = useState();

  const navigateToBackofficeRegistration = () => {
    const sponsorLogin = localStorage.getItem('sponsor_login') || '';
    window.location = '//partners.cannabetrust.net/register?lang=' + i18n.language + '&sponsor_login=' + sponsorLogin;
  };

  return (
    <section className={styles.intro}>
      <div className={styles.titleWrapper}>

      </div>
      <Reveal>
        <h1 className={styles.title}>
          {t('cannabis')}<br />
          {t('trend')}<br />
          {t('unique')}<br />
        </h1>
      </Reveal>
      <Reveal>
        <h2 className={styles.subTitle}>
          <Trans t={t} i18nKey='reliable-investment' />
        </h2>
      </Reveal>
      <div className={styles.buttons}>
        <Button onClick={() => navigateToBackofficeRegistration()} size="big" variant="primary">{t('partner')}</Button>
        <Button onClick={() => setVideoModalOpen(true)} size="big" variant="outline" icon={<PlayIcon />}>{t('video')}</Button>
      </div>
      <LettersComponent />
      <UltimateModal type={'video'} active={videoModalOpen} setActive={setVideoModalOpen} withClose={false} className={styles.modal}>
        {videoModalOpen &&
        <iframe 
          src="https://player.vimeo.com/video/756427203?h=1074c0682a" 
          width={window.innerWidth < window.innerHeight ? window.innerWidth * 0.95 : window.innerHeight * 0.95}
          height={window.innerWidth < window.innerHeight ? (window.innerWidth * 0.95) / 1.77777777 : (window.innerHeight * 0.95) / 1.77777777}
          frameborder="0" 
          allow="autoplay; fullscreen; picture-in-picture" 
          allowfullscreen
        />
        }
      </UltimateModal>
    </section>
  );
};

export default withTranslation('main')(Intro);
