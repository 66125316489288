import styles from "./Card.module.css"
import arrow from "assets/images/icons/arrow-right-in-circle.svg"
import { useNavigateLang } from "hooks"

const Card = ({
  number,
  title,
  description,
  path
}) => {
  const navigate = useNavigateLang();
  return(
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <p className={styles.number}>{number}</p>
      </div>
      <div className={styles.right}>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
      </div>
      <button className={styles.img} onClick={() => navigate(path)}>
        <img src={arrow}/>
      </button>
    </div>
  )
}

export default Card;