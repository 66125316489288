import { Button } from 'components';
import { LocationIcon, ProfileOutlineIcon, StarIcon, TeamIcon, WalletIcon } from 'components/ui/icons';
import styles from './BinaryInfo.module.css';
import coin from 'assets/images/icons/coin.svg';
import { formatNumber } from 'helpers/utils';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useBinaryQuery } from 'redux/api/common';
import { withTranslation } from 'react-i18next';

const BinaryInfo = ({ t }) => {
  // const { data } = useBinaryQuery();
  const { binaryBaseData: data } = useSelector(state => state.common);

  const gridItems = [
    {
      suptitle: t('personal-invited'),
      title: <><span className='accent-text'>{formatNumber(data?.binary?.personal_count)}</span>  {t('partners')}</>,
      icon: null,
      buttonIcon: <TeamIcon />,
    },
    {
      suptitle: t('active-partners'),
      title: <><span className='accent-text'>{formatNumber(data?.binary?.active_count)}</span>  {t('partners')}</>,
      icon: null,
      buttonIcon: <TeamIcon />,
    },
    {
      suptitle: t('team'),
      title: <><span className='accent-text'>{formatNumber(data?.binary?.team_count)}</span>  {t('partners')}</>,
      icon: null,
      buttonIcon: <TeamIcon />,
    },
    {
      suptitle: t('turnover'),
      title: `${data?.binary?.total_invested.amount} CAN`,
      icon: coin,
      buttonIcon: <WalletIcon />,
    },
  ];

  return (
    <section className={styles.binaryInfo}>
      <div className={styles.commonBlock}>
        <div className={styles.box}>
          <Button className={`${styles.iconButton} ${styles.starButton}`} variant='outline-filled' icon={<StarIcon />}/>
          <div className={styles.info}>
            <div className={styles.label}>{t('sponsor-id')}</div>
            <div className={styles.value}>{data?.user?.sponsor}</div>
          </div>
        </div>
        <div className={styles.box}>
          <Button className={styles.iconButton} variant='outline-filled' icon={<ProfileOutlineIcon />}/>
          <div className={styles.info}>
            <div className={styles.label}>{t('login')}</div>
            <div className={styles.value}>{data?.user?.login}</div>
          </div>
        </div>
        <div className={styles.box}>
          <Button className={styles.iconButton} variant='outline-filled' icon={<LocationIcon />}/>
          <div className={styles.info}>
            <div className={styles.label}>{t('country')}</div>
            <div className={styles.value}>{data?.user?.country}</div>
          </div>
        </div>
      </div>
      <div className={styles.gridBlock}>
        {gridItems.map(({ suptitle, title, icon, buttonIcon }, index) => 
          <div key={index} className={`${styles.box} ${styles.gridItem}`}>
            <div className={styles.left}>
              <div className={styles.label}>{suptitle}</div>
              <div className={styles.value}>
                {icon && <img src={icon} />}
                <div className={styles.title}>{title}</div>
              </div>
            </div>
            <div className={styles.right}>
              <Button className={styles.iconButton} variant='outline-filled' icon={buttonIcon} />
            </div>
          </div>
        )}
      </div>
      <div className={styles.bottomBlock}>
        <div className={styles.bottomItem}>
          <div className={styles.bottomBox}>
            <Button className={`${styles.iconButton} ${styles.starButton}`} variant='outline-filled' icon={<StarIcon />}/>
            <div className={styles.info}>
              <div className={styles.label}>{t('left-team')}</div>
              <div className={styles.value}>
                <span className='accent-text'>{data?.binary?.team_left_count}</span>  Partners
              </div>
            </div>
          </div>
          <div className={styles.bottomBox}>
            <Button className={`${styles.iconButton} ${styles.starButton}`} variant='outline-filled' icon={<StarIcon />}/>
            <div className={styles.info}>
              <div className={styles.label}>{t('right-team')}</div>
              <div className={styles.value}>
                <span className='accent-text'>{data?.binary?.team_right_count}</span>  Partners
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomItem}>
          <div className={styles.bottomBox}>
            <Button className={`${styles.iconButton} ${styles.starButton}`} variant='outline-filled' icon={<StarIcon />}/>
            <div className={styles.info}>
              <div className={styles.label}>{t('left-turnover')}</div>
              <div className={styles.value}>
                <img src={coin} />
                <div className={styles.title}>{data?.binary?.team_left_invested} CAN</div>
              </div>
            </div>
          </div>
          <div className={styles.bottomBox}>
            <Button className={`${styles.iconButton} ${styles.starButton}`} variant='outline-filled' icon={<StarIcon />}/>
            <div className={styles.info}>
              <div className={styles.label}>{t('right-turnover')}</div>
              <div className={styles.value}>
                <img src={coin} />
                <div className={styles.title}>{data?.binary?.team_right_invested} CAN</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('binary')(BinaryInfo);
