import styles from "./Card.module.css";
import arrowIcon from "assets/images/icons/arrow-right-in-circle.svg";
import { useNavigateLang } from "hooks";

const Card = ({ title, subtitle, description, image, hide }) => {
  const navigate = useNavigateLang();

  return (
    <div className={styles.card} style={{ opacity: hide ? 0 : 1 }}>
      <div className={styles.head}>
        <div className={styles.title}>{title}</div>
        <img className={styles.image} src={image} />
      </div>
      <div className={styles.body}>
        <div className={styles.bodyLeft}>
          <div className={styles.subtitle}>{subtitle}</div>
          <img onClick={() => navigate('/our-products')} src={arrowIcon} className={styles.button} />
        </div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default Card;
