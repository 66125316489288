import styles from "./JudgeCards.module.css"
import abstract1 from 'assets/images/abstract-1.png';
import abstract2 from 'assets/images/abstract-2.png';

const JudgeCards = ({title, cards}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.line}/>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <img className={styles.abstract1} src={abstract1} />
        <img className={styles.abstract2} src={abstract2} />
        <div className={styles.cards}>
          {cards.map((card, index) => 
            <div className={styles.card}>
              <p className={styles.description}>{card.description}</p>
              <p className={styles.value}>{card.value}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default JudgeCards;
