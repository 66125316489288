import styles from './Table.module.css';
import { CoinIcon, TetherIcon } from '../icons';

const Table = ({ data = [], columns = [] }) => {
  const currencyIcon = {
    USDT: <TetherIcon className={styles.icon} />,
    CAN: <CoinIcon className={styles.icon} />
  }

  const getValueFromNestedKey = (obj, key) => {
    const keys = key.split('.');
    let value = obj;

    for (const nestedKey of keys) {
      if (value && value.hasOwnProperty(nestedKey)) {
        value = value[nestedKey];
      } else {
        value = null;
        break;
      }
    }

    return value;
  };

  return (
    <>
      {window.innerWidth > 1200 &&
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr className={styles.tr}>
              {columns.map(({ title }, index) =>
                <th className={styles.th} key={index}>{title}</th>
              )}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {data.map((item, index) =>
              <tr className={styles.tr} key={index}>
                {columns.map(({ key }, index) => (
                  <th key={index} className={styles.th}>
                    {key.includes('.')
                      ? getValueFromNestedKey(item, key)
                      : item[key]}
                  </th>
                ))}
              </tr>
            )}
          </tbody>
        </table>
      }
      {window.innerWidth <= 1200 &&
        <div className={styles.wrapper}>
          <div className={styles.line}/>
          {data.map((item, index) =>
            <div key={index} className={styles.item}>
              {columns.map(({ key, title }, index) =>
                <div key={index} className={styles.column}>
                  <p className={styles.key}>
                    {title}
                  </p>
                  <p className={styles.value}>
                    {key.includes('.')
                      ? getValueFromNestedKey(item, key)
                      : item[key]}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      }
    </>
  );
};

export default Table;


/*
<table className={styles.table}>
      <thead className={styles.thead}>
        <tr className={styles.tr}>
          {columns.map(({ title }, index) =>
            <th className={styles.th} key={index}>{title}</th>
          )}
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {data.map((item, index) =>
          <tr className={styles.tr} key={index}>
            {columns.map(({ key }, index) => (
              <th key={index} className={styles.th}>
                {key.includes('.')
                  ? getValueFromNestedKey(item, key)
                  : item[key]}
              </th>
            ))}
          </tr>
        )}
      </tbody>
    </table>
    */

    /*
    {window.innerWidth <= 1200 &&
        <div className={styles.wrapper}>
          {data.map((item, index) =>
            <div className={styles.item}>
              {columns.map(({ key, title }, index) =>
                <div className={styles.column}>
                  <p className={styles.key}>
                    {title}
                  </p>
                  <p className={styles.value}>
                    {key.includes('.')
                      ? getValueFromNestedKey(item, key)
                      : item[key]}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      }
    */
