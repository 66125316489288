import { PageContainer, PrivacyPolicyMain } from "components";

const PrivacyPolicy = () => {
  return(
    <PageContainer>
      <PrivacyPolicyMain/>
    </PageContainer>
  )
}

export default PrivacyPolicy;