import { CabinetContainer, Enabled2FA } from "components";

const SettingEnabled2FA = () => {
  return(
    <CabinetContainer>
      <Enabled2FA />
    </CabinetContainer>
  )
}

export default SettingEnabled2FA;