import { withTranslation, Trans } from "react-i18next";
import TextBuilder from "../TextBuilder";
import styles from "./TermsMain.module.css"

const TermsMain = ({ t }) => {
  return(
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <p className={styles.title}>
          <Trans
            t={t}
            i18nKey={"title-1"}
            components={[<span className="accent-text"/>]}
          /> 
        </p>
        <p className={styles.title}>{t('title-2')}</p>
        <div className={styles.line}/>
      </div>
      <TextBuilder texts={t('terms', { returnObjects: true })}/>
    </div>
  )
}

export default withTranslation('terms')(TermsMain);
