import { AuthPageContainer, ForgotForm } from "components"

const Forgot = () => {
  return (
    <div>
      <AuthPageContainer>
        <ForgotForm />
      </AuthPageContainer>
    </div>
  )
}

export default Forgot
