import { useLocation } from "react-router-dom";
import { Button, Dropdown, LangSelector } from "../";
import styles from "./Header.module.css";
import logo from "assets/images/logo.svg";
import logo3 from "assets/images/logo-3.svg";
import menuIcon from "assets/images/icons/menu.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "redux/slices/commonSlice";
import { ProfileIcon } from "components/ui/icons";
import { useDashboardQuery } from "redux/api/common";
import { withTranslation, useTranslation } from "react-i18next";
import { useNavigateLang } from "hooks";

const Header = ({
  variant = "blur", // blur | light
  t,
}) => {
  const navigate = useNavigateLang();
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuth, isHeaderHidden } = useSelector(state => state.common);
  const isLight = variant === "light";
  const { data } = useDashboardQuery();
  
  const navOptions = [
    // {
    //   value: "seeds",
    //   label: "Seeds cannabis",
    // },
    {
      value: "technical",
      label: "Technical cannabis",
    },
    {
      value: "medical",
      label: "Medical cannabis",
    },
  ];

  const handleMenuSelect = (option) => {
    navigate(`/${option.value}`);
  };

  const navigateToBackofficeLogin = () => {
    const sponsorLogin = localStorage.getItem('sponsor_login') || '';
    window.location = '//partners.cannabetrust.net/login?lang=' + i18n.language + '&sponsor_login=' + sponsorLogin;
  };

  const navigateToBackofficeRegistration = () => {
    const sponsorLogin = localStorage.getItem('sponsor_login') || '';
    window.location = '//partners.cannabetrust.net/register?lang=' + i18n.language + '&sponsor_login=' + sponsorLogin;
  };

  const top35 = ["/"]; //Это чисто потому что у Main маржин сверху 35px, и только там Ы

  return (
    <>
      <div onClick={() => navigate("/")} className={styles.mobileLogoContainer}>
        <img className={styles.logoImg} src={isLight ? logo3 : logo} />
      </div>
      <header
        className={`${styles.header} ${isHeaderHidden && styles.hidden} ${styles[variant]}`}
        style={top35.includes(location.pathname) ? { top: "25px" } : null}
      >
        <div className={`${styles.background} ${styles[variant]}`} />
        <div className={styles.left}>
          <button
            className={styles.menuButton}
            onClick={() => dispatch(toggleDrawer())}
          >
            <img className={styles.menuIcon} src={menuIcon} />
          </button>
          <div onClick={() => navigate("/")} className={styles.logoContainer}>
            <img className={styles.logoImg} src={isLight ? logo3 : logo} />
          </div>
          <LangSelector
            className={styles.languageSelector}
            variant={isLight ? "dark" : "light"}
          />
        </div>
        <nav className={styles.nav}>
          <a onClick={() => navigate("/about-us")}>{t('about-us')}</a>
          <a onClick={() => navigate("/our-products")}>{t('products')}</a>
          <Dropdown
            options={navOptions}
            openAction="hover"
            defaultValue={t('industries')}
            onSelect={handleMenuSelect}
            variant={isLight ? "light" : "blur"}
          />
          <a onClick={() => navigate("/contact-us")}>{t('contact-us')}</a>
        </nav>
        <div className={styles.buttons}>
          <Button
            style={isLight ? { color: "var(--dark)" } : null}
            onClick={() => navigateToBackofficeRegistration()}
          >
            {t('sign-up')}
          </Button>
          {isAuth ?
            <Button
              variant={isLight ? "accent" : "primary"}
              onClick={() => navigate("/dashboard")}
              icon={<ProfileIcon style={{ filter: 'brightness(0)' }} />}
              isLeftIcon
            >
              {data?.user?.login}
            </Button>
            :
            <Button
              variant={isLight ? "accent" : "primary"}
              onClick={() => navigateToBackofficeLogin()}
            >
              {t('log-in')}
            </Button>
          }
        </div>
      </header>
    </>
  );
};

export default withTranslation('header')(Header);
