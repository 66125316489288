import { Button, TextIntput } from 'components';
import { SearchIcon, ProfileIcon, ArrowDownIcon} from 'components/ui/icons';
import styles from './Structure.module.css';
import gradient from 'assets/images/icons/gradient.svg';
import profile from 'assets/images/icons/profile.svg';
import two from 'assets/images/icons/two.svg';
import { useState } from 'react';
import { useLazyStructureQuery, useStructureQuery } from 'redux/api/common';
import { withTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

const DEFAULT_COLOR = '#8F939C';

const Structure = ({ t }) => {
  const { register } = useForm();
  const [partners, setPartners] = useState({});
  const [showPartners, setShowPartners] = useState({});
  const [searchQuery, setSearchQuery] = useState(undefined);
  const [inputSearchQuery, setInputSearchQuery] = useState('');
  const { data: structure } = useStructureQuery({search: searchQuery});
  const [structureTrigger] = useLazyStructureQuery();

  const handleArrowClick = async (user) => {
    setShowPartners((prevState) => ({
      ...prevState,
      [user.id]: !prevState[user.id],
    }));

    if (!showPartners[user.id]) {
      let structure = await structureTrigger({user_id: user.id});
      setPartners((prevState) => ({
        ...prevState,
        [user.id]: structure.data.data,
      }));
    }
  };

  const handleSearch = () => {
    setSearchQuery(inputSearchQuery);
  };

  const renderUser = (user) => {
    return (
    <div className={styles.line} key={user.id}>
      <div className={styles.item}>
        <div className={styles.partner}>
          <div className={styles.badge}>{user.rank_index}</div>
          <ProfileIcon fill={user.package_color ?? DEFAULT_COLOR} className={styles.icon}/>
        </div>
        {user.partner_count ? (
            <ArrowDownIcon
                className={`${styles.arrowIcon} ${showPartners[user.id] ? styles.arrowIconRotated : ''}`}
                onClick={() => handleArrowClick(user)}/>
        ): null}
        <div>{user.login} ({user.partner_count})</div>
      </div>

      {showPartners[user.id] && partners[user.id] && partners[user.id].length > 0 && (
          <div className={styles.subStructure}>
            {partners[user.id].map((user) => (renderUser(user)))}
          </div>
      )}
    </div>
    );
  }

  return (
    <section className={styles.binaryStructure}>
      <div className={styles.head}>
        <div className={styles.searchBar}>
          <TextIntput
            className={styles.searchInput}
            label={t('search-partner')}
            name='search'
            register={register}
            value={inputSearchQuery}
            onChange={e => setInputSearchQuery(e.target.value)}
            placeholder='login'
            icon={<SearchIcon />}
          />
          <Button variant='accent' onClick={handleSearch}>
            {t('search-partner')}
          </Button>
        </div>

        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.imgWrapper}>
              <img src={gradient} alt=""/>
            </div>
            <span>{t('displays-partners-package')}</span>
          </div>
          <div className={styles.item}>
            <div className={styles.imgWrapper}>
              <img src={two} alt=""/>
            </div>
            <span>{t('rank')}</span>
          </div>
          <div className={styles.item}>
            <div className={styles.imgWrapper}>
              <img src={profile} alt=""/>
            </div>
            <span>{t('non-active-partner')}</span>
          </div>
        </div>

        {structure && structure.data && structure.data.length > 0 && (
            <div className={styles.structure}>
              {structure.data.map((user) => (renderUser(user)))}
            </div>
        )}
      </div>
    </section>
  );
};

export default withTranslation('binary')(Structure);
