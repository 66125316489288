import { CartIcon } from 'components/ui/icons';
import { Badge, Button } from 'components';
import styles from './StoreHeader.module.css';
import { withTranslation } from 'react-i18next';

const StoreHeader = ({ t }) => {

  return (
    <div className={styles.storeHeader}>
      <h1>{t('store')}</h1>
      <div className={styles.cart}>
        <Button variant="accent" icon={<CartIcon />} />
      </div>
    </div>
  );
}

export default withTranslation('store')(StoreHeader);
