import TextBuilder from "../TextBuilder";
import styles from "./PrivacyPolicyMain.module.css"
import { withTranslation } from "react-i18next";

const PrivacyPolicyMain = ({ t }) => {
  return(
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <p className={styles.title}><span className="accent-text">{t('title-1')}</span></p>
        <p className={styles.title}>{t('title-2')}</p>
        <div className={styles.line}/>
      </div>
      <TextBuilder texts={t('privacy-policy', {returnObjects: true})}/>
    </div>
  )
}

export default withTranslation('privacy-policy')(PrivacyPolicyMain);
