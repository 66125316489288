import { AdditionalInfo, LettersComponent, Margin, StandartedTextRowV2, StandartedTextRowV3 } from "components";
import InvestInfo from "../InvestInfo";
import StandartedTextRow from "../StandartedTextRow";
import styles from "./SeedsMain.module.css"
import JudgeCards from "./JudgeCards";
import StandartedTextRowDouble from "../StandartedTextRowDouble";
import AccentCards from "./AccentCards";
import img9 from "assets/images/image-9.png"
import img7 from "assets/images/image-7.png"
import img10 from "assets/images/image-10.png"
import francuaImg from "assets/images/flags/francua.png"
import usaImg from "assets/images/flags/usa.png"
import canadaImg from "assets/images/flags/canada.png"
import { Trans, withTranslation } from "react-i18next";

const SeedsMain = ({t}) => {
  const mainProduct = {
    title: 
      <Trans
        t={t}
        i18nKey={"product-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle: t("product-subtitle"),
    addition: t("product-addition"),
  }
  const judge = {
    title: 
      <Trans
        t={t}
        i18nKey={"judge-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle: t("judge-subtitle"),
    addition: t("judge-addition"),
  }
  const individualGenetic = {
    title:
      <Trans
        t={t}
        i18nKey={"genetic-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle:
      <Trans
        t={t}
        i18nKey={"genetic-subtitle"}
        components={[<span className="accent-text"/>]}
      />,
    addition: 
      <Trans
        t={t}
        i18nKey={"genetic-addition"}
        components={[<span className="accent-text"/>]}
      />,
  }
  const cards = [
    {
      number : "01",
      title : t("card-1-title"),
      description : t("card-1-description"),
    },
    {
      number : "02",
      title : t("card-2-title"),
      description : t("card-2-description"),
    },
  ]
  const judgeCards = [
    {
      description: t("judge-card-1-description"),
      value: t("judge-card-1-value"),
    },
    {
      description: t("judge-card-2-description"),
      value: t("judge-card-2-value"),
    },
  ]
  const usefulSeeds = {
    title: 
      <Trans
        t={t}
        i18nKey={"useful-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle1: t("useful-subtitle-1"),
    addition1: 
      <Trans
        t={t}
        i18nKey={"useful-addition-1"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle2: t("useful-subtitle-2"),
    addition2: 
      <Trans
        t={t}
        i18nKey={"useful-addition-2"}
        components={[<span className="accent-text"/>]}
      />,
  }
  const accentCards = [
    {
      number: "01",
      title: t("accent-card-1-title"),
      description: t("accent-card-1-description"),
    },
    {
      number: "02",
      title: t("accent-card-2-title"),
      description: t("accent-card-2-description"),
    }
  ]
  return(
    <section className={styles.wrapper}>
      <Margin margin={110}/>
      <StandartedTextRow 
        {...mainProduct}
      >
        <img className={styles.img9} src={img9} />
      </StandartedTextRow>
      <Margin margin={30}/>
      <div className={styles.letters}>
        <LettersComponent isDark isAccent />
      </div>
      <Margin margin={-60}/>
      <StandartedTextRowV2
        {...judge}
      />
      <JudgeCards 
        title={t("approximately")}
        cards={judgeCards}
      />
      <Margin margin={170}/>
      <AdditionalInfo>
        <p className={styles.title}>
          <Trans
            t={t}
            i18nKey={"genetically"}
            components={[<span className="white-accent-text"/>]}
          />
        </p>
        <div className={styles.accentCards}>
          {accentCards.map((card, index) => 
            <AccentCards
              {...card}
            >
              {index === 1 && 
              <div className={styles.countyImages}>
                <img className={`${styles.countryImg} ${styles.francuaImg}`} src={francuaImg}/>
                <img className={`${styles.countryImg} ${styles.usaImg}`} src={usaImg}/>
                <img className={`${styles.countryImg} ${styles.canadaImg}`} src={canadaImg}/>
              </div>
              }
            </AccentCards>
          )}
        </div>
      </AdditionalInfo>
      <Margin margin={100}/>
      <StandartedTextRowDouble
        {...usefulSeeds}
      >
        <img className={styles.img7} src={img7} />
      </StandartedTextRowDouble>
      <div className={styles.letters}>
        <LettersComponent isDark isAccent />
        <img className={styles.img10} src={img10} />
      </div>
      <Margin margin={210}/>
      <StandartedTextRowV3
        {...individualGenetic}
      />
      <InvestInfo cards={cards}/>
    </section>
  )
}

export default withTranslation("seeds")(SeedsMain);
