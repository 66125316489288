import { useState } from 'react';
import styles from './TextInput.module.css';

const TextIntput = ({
  label,
  rightLabel,
  icon,
  name,
  type,
  register,
  errors,
  validationSchema,
  defaultIconColor,
  withReqStar=true,
  onChange=Function.prototype,
  onFocus=Function.prototype,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState();
  const { onChange: formChange, onBlur, ref } = register(name, validationSchema); 
  const isRequired = validationSchema ? validationSchema?.required : false;

  return (
    <div className={styles.container}>
      <div className={styles.labelContainer}>
        <span
          className={`${styles.textLabel} ${isRequired && withReqStar ? styles.requiredLabel : null}`}
        >
          {label}
        </span>
        {errors && errors[name]?.message && (
          <span className={styles.error}>{errors[name].message}</span>
        )}
        {errors && !errors[name]?.message && rightLabel && (
          <span className={`${styles.error} ${styles.rightLabel}`}>{rightLabel}</span>
        )}
      </div>
      <label
        className={`${styles.inputLabel} ${isFocused ? styles.focusIcon : null} ${errors && errors[name] ? styles.errorContainer : null}`}
      >
        {icon}
        <input
          style={ icon ? { paddingLeft: '50px' } : null}
          onFocus={e => {setIsFocused(true); onFocus(e)}}
          onBlur={e => {setIsFocused(false); onBlur(e)}}
          onChange={e => {formChange(e); onChange(e)}}
          ref={ref} 
          name={name}
          type={type}
          {...props}
        />
      </label>
    </div>
  );
};

export default TextIntput;
