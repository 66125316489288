const TicketStarIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.881 17.438C16.4418 17.438 17.7077 16.173 17.7085 14.6122V14.6105V12.3605C16.6777 12.3605 15.8427 11.5255 15.8418 10.4947C15.8418 9.46466 16.6768 8.62883 17.7077 8.62883H17.7085V6.37883C17.7102 4.81716 16.446 3.55049 14.8852 3.54883H14.8802H5.12017C3.55851 3.54883 2.29267 4.81383 2.29184 6.37549V6.37633V8.70133C3.28684 8.66633 4.12101 9.44466 4.15601 10.4397C4.15684 10.458 4.15767 10.4763 4.15767 10.4947C4.15851 11.5238 3.32601 12.3588 2.29684 12.3605H2.29184V14.6105C2.29101 16.1713 3.55684 17.438 5.11767 17.438H5.11851H14.881Z" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.3094 7.97619L10.8227 9.01536C10.8727 9.11702 10.9694 9.18786 11.0819 9.20452L12.2294 9.37202C12.5136 9.41369 12.6261 9.76202 12.4211 9.96119L11.5911 10.7695C11.5094 10.8487 11.4727 10.9629 11.4911 11.0754L11.6869 12.217C11.7352 12.4995 11.4394 12.7145 11.1861 12.5812L10.1602 12.042C10.0594 11.9887 9.93942 11.9887 9.83858 12.042L8.81358 12.5812C8.55942 12.7145 8.26358 12.4995 8.31192 12.217L8.50775 11.0754C8.52692 10.9629 8.48942 10.8487 8.40775 10.7695L7.57858 9.96119C7.37358 9.76202 7.48608 9.41369 7.76942 9.37202L8.91692 9.20452C9.02942 9.18786 9.12692 9.11702 9.17692 9.01536L9.68942 7.97619C9.81608 7.71952 10.1827 7.71952 10.3094 7.97619Z" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default TicketStarIcon;
