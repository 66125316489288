import styles from "./GummyBears.module.css"
import ProductsModal from "../ProductsModal";
import img from "assets/images/card-3-normalized.png"
import { withTranslation } from "react-i18next";

const GummyBears = ({
  active,
  setActive,
  withClose,
  t,
}) => {
  const cards1 = [
    {
      title: '',
      text: t('since-they-are-made-fresh'),
    },
    {
      title: t('each-gummy-bear-is'),
      text: t('crafted-in-a-gmp'),
    },
  ]

  const cards2 = [
    {
      title: t('cbd'),
      text: `20 ${t('mg')}`,
    },
    {
      title: t('package-size'),
      text: `25 ${t('bears')}`,
    },
    {
      title: t('flavor'),
      text: t('wild-berry'),
    },
  ]

  const title1 = t('cannabetrust');
  const title2 = `${t('cbd')} ${t('gummy-bears')}`;
  const subtitle = t('gummy-bears-have-all-the-benefits');
  const addition = t('each-gummy-bear-contains-20mg');
  const image = <img className={styles.img} src={img} alt={`${t('cbd')} ${t('gummy-bears')}`}/>
  const color = '#87C7AB'

  const props = {
    title1,
    title2,
    subtitle,
    addition,
    cards1,
    cards2,
    image,
    color,

    active,
    setActive,
    withClose,
  }

  return <ProductsModal {...props} />
}

export default withTranslation('landing-modals')(GummyBears);