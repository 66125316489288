const WalletIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path d="M18.5324 11.9962H15.1589C13.9202 11.9954 12.9161 10.9922 12.9154 9.75347C12.9154 8.51477 13.9202 7.5115 15.1589 7.51074H18.5324" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.5405 9.702H15.2808" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.95639 2.5H14.1592C16.5744 2.5 18.5323 4.45792 18.5323 6.87305V12.8539C18.5323 15.269 16.5744 17.227 14.1592 17.227H6.95639C4.54126 17.227 2.58334 15.269 2.58334 12.8539V6.87305C2.58334 4.45792 4.54126 2.5 6.95639 2.5Z" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.36301 6.28208H10.8621" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default WalletIcon;
