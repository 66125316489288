import {
  AboutUs,
  Forgot,
  Login,
  Main,
  Medical,
  OurProducts,
  Register,
  Seeds,
  Technical,
  Dashboard,
  Packages,
  Finance,
  NotFound,
  PrivacyPolicy,
  Terms,
  Binary,
  Materials,
  Store,
  ContactUs,
  Settings,
  SettingEnable2FA,
  SettingEnabled2FA,
  SettingRemove2FA,
  Reset,
  Pro,
} from "pages";

const BASE_ROUTE = '/:lang(en|ru|ua|pt|es)?';

const routes = [
  {
    path: `${BASE_ROUTE}/`,
    name: "Main",
    component: <Main />,
  },
  {
    path: `${BASE_ROUTE}/login`,
    name: "Login",
    component: <Login />,
  },
  {
    path: `${BASE_ROUTE}/register`,
    name: "Register",
    component: <Register />,
  },
  {
    path: `${BASE_ROUTE}/forgot`,
    name: "Forgot",
    component: <Forgot />,
  },
  {
    path: `${BASE_ROUTE}/password/reset/:token`,
    name: "Reset",
    component: <Reset />,
  },
  {
    path: `${BASE_ROUTE}/about-us`,
    name: "AboutUs",
    component: <AboutUs />,
  },
  {
    path: `${BASE_ROUTE}/our-products`,
    name: "OurProducts",
    component: <OurProducts />,
  },
  // {
  //   path: `${BASE_ROUTE}/seeds`,
  //   name: "Seeds",
  //   component: <Seeds />,
  // },
  {
    path: `${BASE_ROUTE}/technical`,
    name: "Technical",
    component: <Technical />,
  },
  {
    path: `${BASE_ROUTE}/medical`,
    name: "Medical",
    component: <Medical />,
  },
  {
    path: `${BASE_ROUTE}/*`,
    name: "NotFound",
    component: <NotFound />,
  },
  {
    path: `${BASE_ROUTE}/dashboard`,
    name: "Dashboard",
    component: <Dashboard />,
  },
  {
    path: `${BASE_ROUTE}/packages`,
    name: "Packages",
    component: <Packages />,
  },
  {
    path: `${BASE_ROUTE}/finance`,
    name: "Finance",
    component: <Finance />,
  },
  {
    path: `${BASE_ROUTE}/binary`,
    name: "Binary",
    component: <Binary />,
  },
  {
    path: `${BASE_ROUTE}/materials`,
    name: "Materials",
    component: <Materials />,
  },
  {
    path: `${BASE_ROUTE}/store`,
    name: "Store",
    component: <Store />,
  },
  {
    path: `${BASE_ROUTE}/privacy-policy`,
    name: "PrivacyPolicy",
    component: <PrivacyPolicy />,
  },
  {
    path: `${BASE_ROUTE}/terms`,
    name: "Terms",
    component: <Terms />,
  },
  {
    path: `${BASE_ROUTE}/contact-us`,
    name: "ContactUs",
    component: <ContactUs />,
  },
  {
    path: `${BASE_ROUTE}/settings`,
    name: "Settings",
    component: <Settings />,
  },
  {
    path: `${BASE_ROUTE}/2fa/enable`,
    name: "2FA Enable",
    component: <SettingEnable2FA />,
  },
  {
    path: `${BASE_ROUTE}/2fa/enabled`,
    name: "2FA Enabled",
    component: <SettingEnabled2FA />,
  },
  {
    path: `${BASE_ROUTE}/2fa/remove`,
    name: "2FA Remove",
    component: <SettingRemove2FA />,
  },
  {
    path: `${BASE_ROUTE}/pro`,
    name: "2FA Remove",
    component: <Pro />,
  },
];

export default routes;
