const CircleArrowDownIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
      <g filter="url(#filter0_b_307_12662)">
        <circle cx="40" cy="40" r="40" transform="rotate(90 40 40)" fill="white" fillOpacity="0.3" />
      </g>
      <path d="M40.0026 28.4987L40.0021 51.5018M40.0021 51.5018L49.989 41.5149M40.0021 51.5018L29.4857 40.9854" stroke="white" strokeWidth="3" />
      <defs>
        <filter id="filter0_b_307_12662" x="-20" y="-20" width="120" height="120" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_307_12662" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_307_12662" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default CircleArrowDownIcon;
