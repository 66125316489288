import { CabinetContainer, SettingsInfo, SettingsRefferal } from "components";
import { withTranslation } from "react-i18next";

const Settings = ({ t }) => {
  return(
    <CabinetContainer>
      <div>
        <h1>{t('settings')}</h1>
        <SettingsRefferal />
      </div>
      <div>
        <h2>{t('info')}</h2>
        <SettingsInfo />
      </div>
    </CabinetContainer>
  )
}

export default withTranslation('settings')(Settings);