const LockIcon = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
      <path d="M11.1869 4.13519C10.6727 2.65019 9.26185 1.58353 7.60185 1.58353C5.50768 1.57519 3.80268 3.26519 3.79352 5.35936V5.37603V7.16519" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7775 17.0005H4.41C2.665 17.0005 1.25 15.5855 1.25 13.8396V10.2663C1.25 8.52047 2.665 7.10547 4.41 7.10547H10.7775C12.5225 7.10547 13.9375 8.52047 13.9375 10.2663V13.8396C13.9375 15.5855 12.5225 17.0005 10.7775 17.0005Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.59391 11.1274V12.9791" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default LockIcon;