import { PageContainer, TermsMain } from "components";

const Terms = () => {
  return(
    <PageContainer>
      <TermsMain/>
    </PageContainer>
  )
}

export default Terms;