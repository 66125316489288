import { forwardRef, useEffect, useRef } from 'react';
import styles from './Button.module.css';

const Button = forwardRef(({
  children,
  className,
  variant, // primary | accent | outline | outline-filled
  size="medium", // medium | big
  color,
  active=false,
  isLoading=false,
  icon,
  isLeftIcon,
  onClick,
  disabled,
  disableEffects,
  ...props
}, ref) => {
  const localRef = useRef(null);
  const buttonRef = ref || localRef;

  useEffect(() => {
    if (buttonRef?.current) {
      buttonRef?.current.style.setProperty('--button-accent', color);
    }
  }, []);

  const handleClick = e => {
    if (!isLoading && typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <button
      className={`
        ${styles.button}
        ${styles[variant]}
        ${styles[size]}
        ${active && styles.active}
        ${isLoading && styles.loading}
        ${className}
        ${disabled && styles.disabled}
        ${disableEffects && styles.disableEffects}
      `}
      style={children && icon ? { gap: '10px' } : null}
      ref={buttonRef}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {isLeftIcon && icon}
      <span className={styles.label}>{children}</span>
      {!isLeftIcon && icon}
    </button>
  );
});

export default Button;
