const CartIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M12.1441 6.75387V4.2272C12.1441 2.49054 10.7366 1.08304 9.00074 1.08304C7.26407 1.07554 5.84991 2.47637 5.84241 4.21304V4.2272V6.75387" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9518 16.4999H5.04815C3.08808 16.4999 1.5 14.9124 1.5 12.954V8.35736C1.5 6.39902 3.08808 4.81152 5.04815 4.81152H12.9518C14.9119 4.81152 16.5 6.39902 16.5 8.35736V12.954C16.5 14.9124 14.9119 16.4999 12.9518 16.4999Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default CartIcon;
