import styles from "./Card.module.css"

const Card = ({number, text}) => {
  return(
    <div className={styles.card}>
      <p className={styles.number}>{number}</p>
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default Card;
