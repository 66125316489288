import {
  CabinetContainer,
  DashboardCards,
  DashboardCareerPromo,
  DashboardIncome,
} from "components";
import { ChartIcon, TeamIcon, WalletIcon, TetherIcon, CoinIcon } from "components/ui/icons";
import { useBinaryQuery, useDashboardQuery, useFinanceQuery } from "redux/api/common";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigateLang } from "hooks";
import { SITE_URL } from 'helpers/constants/common';

const Dashboard = ({ t, i18n }) => {
  const { data } = useDashboardQuery();
  const { data: financeData } = useFinanceQuery();
  const { data: binaryData } = useBinaryQuery();
  const navigate = useNavigateLang();
  const [copyBtnText, setCopyBtnText] = useState(t('copy'));

  const handleCopyReferralCode = async () => {
    try {
      const referralLink = `${SITE_URL}${i18n.language}/pro?referral=${data?.user?.referral?.code}`;
      await navigator.clipboard.writeText(referralLink);
      setCopyBtnText(t('copied'));
    } catch (error) {
      console.error(error);
    }
  };

  const cards = [
    {
      title: {
        text: t('info'),
        icon: <ChartIcon />,
      },
      fields: [
        {
          key: `${t('affilate')} CAN`,
          value: {
            isBig: false,
            text: `${financeData?.profit_affiliate.amount} CAN`,
            icons: [<CoinIcon />]
          }
        },
        {
          key: t('earned-total'),
          selectable: false,
          interactive: false,
          value: {
            isBig: false,
            text: `${data?.profit.amount} CAN`,
            icons: [<CoinIcon fill={'#4fa778'} />],
          },
        },
        {
          key: `${t('earned-total')} $`,
          selectable: false,
          interactive: false,
          value: {
            isBig: false,
            text: `${data?.profit.usd} $`,
            icons: [<TetherIcon />],
          },
        },
      ],
      button: {
        label: copyBtnText,
        onClick: handleCopyReferralCode,
      },
    },
    {
      title: {
        text: t('balance'),
        icon: <WalletIcon />,
      },
      fields: [
        {
          key: t('total'),
          selectable: false,
          interactive: false,
          value: {
            isBig: true,
            text: `${data?.user.balance} CAN`,
            icons: [],
          },
        },
        {
          key: t('choose-currency'),
          selectable: true,
          interactive: false,
          value: {
            isBig: true,
            text: "",
            icons: [<CoinIcon />, <TetherIcon />],
          },
        },
        {
          key: t('currency'),
          selectable: false,
          interactive: true,
          value: {
            isBig: false,
            text: data?.user.balance,
            icons: [<CoinIcon />],
          },
        },
      ],
      button: {
        label: t('deposit'),
        onClick: () => navigate('/finance'),
      },
    },
    {
      title: {
        text: t('team'),
        icon: <TeamIcon />,
      },
      fields: [
        {
          key: t('partners'),
          selectable: false,
          interactive: false,
          value: {
            isBig: true,
            text: binaryData?.binary.team_count,
            icons: [],
          },
        },
        {
          key: t('team-turnover'),
          selectable: false,
          interactive: false,
          value: {
            isBig: false,
            text: `${binaryData?.binary.total_invested.amount} CAN`,
            icons: [<CoinIcon />],
          },
        },
        {
          key: `${t('team-turnover')} $`,
          selectable: false,
          interactive: false,
          value: {
            isBig: false,
            text: `${binaryData?.binary.total_invested.usd} $`,
            icons: [<TetherIcon />],
          },
        },
      ],
      button: {
        label: t('my-team'),
        onClick: () => navigate('/binary'),
      },
    },
  ];

  return (
    <CabinetContainer>
      <div>
        <h1>{t('dashboard')}</h1>
        <DashboardCards cards={cards} withButton />
      </div>
      <div>
        <h2>{t('calculate-your-income')}</h2>
        <DashboardIncome />
      </div>
      <div>
        <DashboardCareerPromo />
      </div>
    </CabinetContainer>
  );
};

export default withTranslation('dashboard')(Dashboard);
