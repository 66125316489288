const DownloadIcon2 = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
      <path d="M8.60183 11.3633L8.60183 1.3291" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.0318 8.92383L8.6018 11.3638L6.1718 8.92383" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.4625 5.27344H13.24C14.9358 5.27344 16.31 6.6476 16.31 8.34427V12.4143C16.31 14.1059 14.9391 15.4768 13.2475 15.4768L3.96413 15.4768C2.2683 15.4768 0.893301 14.1018 0.893301 12.4059L0.893301 8.3351C0.893301 6.64427 2.26497 5.27344 3.9558 5.27344L4.7408 5.27344" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default DownloadIcon2;
