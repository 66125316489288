import { CommonIntro, PageContainer, TechnicalMain } from "components"
import { withTranslation } from "react-i18next"

const Technical = ({ t }) => {
  return (
    <PageContainer>
      <CommonIntro 
        title={t("intro-title")}
        subtitle={t("intro-subtitle")}
        number='02' 
      />
      <TechnicalMain/>
    </PageContainer>
  )
}

export default withTranslation('technical')(Technical)
