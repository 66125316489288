import styles from "./Card.module.css"

const Card = ({number, title, description}) => {
  return(
    <div className={styles.card}>
      <h2 className={styles.number}><span className="accent-text">{number}</span></h2>
        <div className={styles.right}>
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
        </div>
    </div>
  )
}

export default Card;