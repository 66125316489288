import { Button } from 'components';
import { CoinIcon, TetherIcon } from 'components/ui/icons';
import { useState } from 'react';
import styles from './DashboardCards.module.css';

const DashboardCards = ({ cards, withButton, ...props }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(0);

  return (
    <section className={styles.dashboardCards} {...props}>
      {cards && cards.map(({ title, fields, button }, index) => 
        <div className={styles.card} key={index}>
          <div className={styles.head}>
            <span className={styles.cardTitle}>{title.text}</span>
            <Button icon={title.icon} variant='outline-filled' disableEffects />
          </div>
          <div className={styles.body}>
            {fields.map(({ key, value, selectable, interactive }, index) => 
              <div className={styles.field} key={index}>
                <div className={styles.key}>{key}</div>
                <div className={styles.valueWrapper}>
                  {value.icons.length ? value.icons.map((icon, index) => 
                    <div
                      className={`
                        ${styles.iconWrapper}
                        ${selectable ? styles.selectable : null}
                        ${selectable && selectedCurrency === index ? styles.selected : styles.rest}
                        ${!selectable ? styles.selected : styles.rest}
                      `}
                      key={index}
                      onClick={selectable ? () => setSelectedCurrency(index) : null}
                    >
                      {interactive ? selectedCurrency === 0 ? <CoinIcon /> : <TetherIcon /> : icon}
                    </div>
                  ) : null}
                  <div
                    className={styles.value}
                  >
                    {interactive ? `${value.text} ${selectedCurrency === 0 ? 'CAN' : '$'}` : value.text}
                  </div>
                </div>
              </div>
            )}
          </div>
          {withButton ? 
            <div className={styles.footer}>
              <Button onClick={button.onClick} variant='accent'>{button.label}</Button>
            </div>
          : null}
        </div>
      )}
    </section>
  );
};

export default DashboardCards;
