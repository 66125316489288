const PackagesIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M9.99601 14.4706V12.3564" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.1583 5.01562C16.5666 5.01562 17.7 6.15729 17.7 7.56563V10.4323C15.65 11.6323 12.9416 12.3573 9.99163 12.3573C7.04163 12.3573 4.34163 11.6323 2.29163 10.4323V7.55729C2.29163 6.14896 3.43329 5.01562 4.84163 5.01562H15.1583Z" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.9127 5.0119V4.7069C12.9127 3.69023 12.0877 2.86523 11.071 2.86523H8.92101C7.90435 2.86523 7.07935 3.69023 7.07935 4.7069V5.0119" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.31201 13.4766L2.46951 15.5674C2.57618 16.9766 3.75035 18.0657 5.16285 18.0657H14.8287C16.2412 18.0657 17.4153 16.9766 17.522 15.5674L17.6795 13.4766" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default PackagesIcon;
