import UltimateModal from "../UltimateModal";
import styles from "./ThankYou.module.css"
import img from "assets/images/Image-8.png"
import { withTranslation, Trans } from "react-i18next";

const ThankYou = ({
  active,
  setActive,
  withClose = true,
  t,
}) => {
  const modalProps = {
    children: (
      <>
        <h2 className={styles.title}>
          <span className="accent-text">{t('thank-you')}</span>
        </h2>
        <p className={styles.subtitle}>
          <Trans
            t={t}
            i18nKey={"thank-you-for-your-request"}
            components={[]}
          /> 
        </p>
        <div className={styles.circle}>
          <img src={img} alt={t('thank-you')} />
        </div>
      </>
    ),
    active,
    setActive,
    withClose,
  };

  return <UltimateModal {...modalProps} />;
};

export default withTranslation('landing-modals')(ThankYou);