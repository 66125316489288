import { Header, Footer, Drawer } from 'components';
import { useLocation } from 'react-router-dom';

const PageContainer = ({
  children,
}) => {
  const location = useLocation();

  const lightPages = ['/our-products', '/privacy-policy', '/terms', '/contact-us'];

  return (
    <div>
      <Header
        variant={lightPages.includes(location.pathname.replace(/\/.*\//, "/")) ? 'light' : 'blur'} //регулярка типа срезает локализацию
      />
      <main>
        <div>{children}</div>
      </main>
      <Footer />
      <Drawer />
    </div>
  );
};

export default PageContainer;
