import styles from "./Wrong2FACode.module.css"
import { Trans, withTranslation } from "react-i18next"
import UltimateModal from "../UltimateModal"
import Button from "components/ui/Button"
import { useNavigateLang } from "hooks"

const Wrong2FACode = ({
  active,
  setActive,
  withClose,
  t,
}) => {
  const navigate = useNavigateLang();

  const props = {
    children:
      <div className={styles.wrapper}>
        <p className={styles.subtitle}>{t('error')}</p>
        <h2 className={styles.title}>
          <Trans
            t={t}
            i18nKey={"wrong-2fa-code"}
            components={[<span style={{color:'var(--error, #F46E86)'}}/>]}
          /> 
        </h2>
      </div>,
      type:'products',
      active,
      setActive,
      withClose,
  }

  return <UltimateModal {...props}/>
}

export default withTranslation('cabinet-modals')(Wrong2FACode)
