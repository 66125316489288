import styles from "./UncommonIntro.module.css";
import { Button, Reveal } from "components";
import { CircleArrowDownIcon } from "components/ui/icons";
import { withTranslation } from "react-i18next";

const UncommonIntro = ({ t }) => {
  const scrollToNext = () => {
    window.scrollTo({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <section className={styles.intro}>
      <div className={styles.titleWrapper}>
        <Reveal>
          <div className={`${styles.title} ${styles.titleLeft}`}>
            {t('intro-title-1')}
          </div>
        </Reveal>
        <Reveal>
          <div className={`${styles.title} ${styles.titleRight}`}>
            {t('intro-title-2')}
          </div>
        </Reveal>
        <Reveal>
          <div className={`${styles.title} ${styles.titleCenter}`}>
            {t('intro-title-3')}
          </div>
        </Reveal>
      </div>
      <div className={styles.bottom}>
        <p className={styles.text}>
          {t('intro-text')}
        </p>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            icon={<CircleArrowDownIcon />}
            onClick={scrollToNext}
          />
        </div>
      </div>
    </section>
  );
};

export default withTranslation('aboutus')(UncommonIntro);
