import Card from "./Card";
import styles from "./Tokenomics.module.css"
import image from "assets/images/image-7.png"
import { Reveal } from "components";
import { withTranslation } from "react-i18next";

const Tokenomics = ({ t }) => {
  const cards = [
    {
      title: t('tokenomics-card-1-title'), 
      description: t('tokenomics-card-1-description')
    },
    {
      title: t('tokenomics-card-2-title'), 
      description: t('tokenomics-card-2-description')
    },
    {
      title: t('tokenomics-card-3-title'), 
      description: t('tokenomics-card-3-description')
    },
  ]

  return(
    <section className={styles.wrapper}>
      <p className={styles.title}><span className="accent-text">token</span>omics</p>
      <div className={styles.bottom}>
        <div className={styles.cards}>
          {cards.map((card, index) => 
            <Card
              key={index}
              {...card}
            />
          )}
        </div>
        <img className={styles.img} src={image}/>
      </div>
    </section>
  )
}

export default withTranslation('aboutus')(Tokenomics);
