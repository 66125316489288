import UltimateModal from "../UltimateModal";
import styles from "./BuyPackage.module.css"
import img from "assets/images/image-modal-3.png"
import { withTranslation } from "react-i18next";

const BuyPackage = ({
  active,
  setActive,
  text,
  withClose = true,
  t
}) => {
  const modalProps = {
    children: (
      <>
        <h2 className={styles.title}>
          {t('congratulations')}
        </h2>
        <p className={styles.subtitle}>
          <span className="accent-text">{text}</span>
        </p>
        <img src={img} alt="Password Change" />
      </>
    ),
    active,
    setActive,
    withClose,
  };

  return <UltimateModal {...modalProps} />;
};

export default withTranslation('settings')(BuyPackage);
