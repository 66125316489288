import styles from "./SettingsRefferal.module.css"
import { StarIcon, LocationIcon, CopyIcon } from "components/ui/icons"
import Button from "components/ui/Button"
import { useBinaryQuery, useDashboardQuery } from "redux/api/common"
import { useState } from "react"
import { withTranslation } from "react-i18next"

const SettingsRefferal = ({ t }) => {
  const { data: settings } = useBinaryQuery();
  const { data } = useDashboardQuery();
  const [copyBtnText, setCopyBtnText] = useState(t('copy-refferal-link'));

  const handleCopyReferralCode = async () => {
    try {
      await navigator.clipboard.writeText(data?.user?.referral?.code);
      setCopyBtnText(t('copied-refferal-link'));
    } catch (error) {
      console.error(error);
    }
  }

  return(
    <div className={styles.wrapper}>
      <div className={styles.subwrapper}>

        <div className={styles.sponsor}>
          <div className={styles.icon}>
            <StarIcon/>
          </div>
          <div className={styles.info}>
            <p className={styles.name}>{t('sponsor-id')}</p>
            <p className={styles.value}>{settings?.user.sponsor || 'Sponsor'}</p>
          </div>
        </div>

        <div className={styles.sponsor}>
          <div className={styles.icon}>
            <LocationIcon/>
          </div>
          <div className={styles.info}>
            <p className={styles.name}>{t('country')}</p>
            <p className={styles.value}>{settings?.user.country || 'Country'}</p>
          </div>
        </div>
        <Button 
          className={styles.button} 
          variant="accent" 
          icon={<CopyIcon 
          className={styles.buttonIcon}/>} 
          onClick={handleCopyReferralCode}
        >
          {copyBtnText}
        </Button>
      </div>
    </div>
  )
}

export default withTranslation('settings')(SettingsRefferal)