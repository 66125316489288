import { Button } from 'components';
import styles from './StructureStatistic.module.css';
import { formatNumber } from 'helpers/utils';
import { withTranslation } from 'react-i18next';
import coin from 'assets/images/icons/coin.svg';
import { useStructureStatisticQuery } from 'redux/api/common';
import { ProfileOutlineIcon, StarIcon, TeamIcon, WalletIcon } from 'components/ui/icons';

const StructureStatistic = ({ t }) => {
  const { data: statistic } = useStructureStatisticQuery();

  const items = [
    {
      suptitle: t('personal-invited'),
      title: <><span className='accent-text'>{formatNumber(statistic?.partners)}</span>  {t('partners')}</>,
      icon: null,
      buttonIcon: <TeamIcon />,
    },
    {
      suptitle: t('active-partners'),
      title: <><span className='accent-text'>{formatNumber(statistic?.active_partners)}</span>  {t('partners')}</>,
      icon: null,
      buttonIcon: <TeamIcon />,
    },
  ];

  return (
    <section className={styles.binaryInfo}>
      <div className={styles.commonBlock}>
        <div className={styles.box}>
          <Button className={`${styles.iconButton} ${styles.starButton}`} variant='outline-filled' icon={<ProfileOutlineIcon />}/>
          <div className={styles.info}>
            <div className={styles.label}>{t('sponsor-id')}</div>
            <div className={styles.value}>{statistic?.sponsor_login}</div>
          </div>
        </div>
        <div className={styles.box}>
          <Button className={styles.iconButton} variant='outline-filled' icon={<ProfileOutlineIcon />}/>
          <div className={styles.info}>
            <div className={styles.label}>{t('login')}</div>
            <div className={styles.value}>{statistic?.login}</div>
          </div>
        </div>
        <div className={styles.box}>
          <Button className={styles.iconButton} variant='outline-filled' icon={<StarIcon />}/>
          <div className={styles.info}>
            <div className={styles.label}>{t('rank')}</div>
            <div className={styles.value}>{statistic?.rank}</div>
          </div>
        </div>
      </div>
      <div className={styles.gridBlock}>
        {items.map(({ suptitle, title, icon, buttonIcon }, index) =>
          <div key={index} className={`${styles.box} ${styles.gridItem}`}>
            <div className={styles.left}>
              <div className={styles.label}>{suptitle}</div>
              <div className={styles.value}>
                {icon && <img src={icon}  alt=''/>}
                <div className={styles.title}>{title}</div>
              </div>
            </div>
            <div className={styles.right}>
              <Button className={styles.iconButton} variant='outline-filled' icon={buttonIcon} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default withTranslation('binary')(StructureStatistic);
