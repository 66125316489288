const EyeIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6096 8.72623C14.6096 10.5503 13.1303 12.0286 11.3062 12.0286C9.48217 12.0286 8.00391 10.5503 8.00391 8.72623C8.00391 6.90112 9.48217 5.42285 11.3062 5.42285C13.1303 5.42285 14.6096 6.90112 14.6096 8.72623Z" stroke="white" strokeWidth="1.88048" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3041 16.3546C15.2823 16.3546 18.9211 13.4942 20.9698 8.72614C18.9211 3.95808 15.2823 1.09766 11.3041 1.09766H11.3083C7.32999 1.09766 3.69126 3.95808 1.64258 8.72614C3.69126 13.4942 7.32999 16.3546 11.3083 16.3546H11.3041Z" stroke="white" strokeWidth="1.88048" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default EyeIcon;
