import CryptoJS from "crypto-js";
import { SECRET_KEY } from "./constants/crypto";

export const formatNumber = num => {
  if (!num) {
    return '0';
  }

  let numStr = num.toString();
  let groups = [];

  while (numStr.length > 0) {
    groups.unshift(numStr.slice(-3));
    numStr = numStr.slice(0, -3);
  }

  return groups.join(' ');
}

export const formatNode = node => {
  let formattedNode = {};

  if (!node) {
    return formattedNode;
  }

  if (!node.hasOwnProperty('childes')) {
    formattedNode = {
      ...node.user,
      childes: {
        ...node.user,
        left: node.left,
        right: node.right,
      }
    }
  } else {
    formattedNode = node;
  }

  return formattedNode;
};

export const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
}

export const smoothScroll = (from, to, duration, element) => {
  const change = to - from;
  const increment = 10;
  let currentTime = 0;


  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, from, change, duration);
    element.scrollTo(0, val);
    return currentTime < duration ? setTimeout(animateScroll, increment) : null;
  };

  animateScroll();
}

export const scrollTo = blockId => {
  const element = window.document.querySelector(`#${blockId}`);
  element && smoothScroll(window.scrollY, element.offsetTop, 300, window);
}

export const chunkArray = (arr, n) => {
  var chunkLength = Math.max(arr.length / n, 1);
  var chunks = [];

  for (var i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }

  return chunks;
};

export const pause = async delay => {
  return new Promise(resolve => setTimeout(resolve, delay));
};

export const buildReferralCode = login => {
  return encodeURIComponent(CryptoJS.AES.encrypt(login, SECRET_KEY).toString());
};

export const decodeReferralCode = code => {
  return CryptoJS.AES.decrypt(decodeURIComponent(code), SECRET_KEY).toString(CryptoJS.enc.Utf8);
};

export const convertDotDateString = dateString => {
  const [day, month, year] = dateString.split('.').map(Number);
  const fullYear = 2000 + year;
  return new Date(fullYear, month - 1, day);
};

export const sortByDate = (a, b) => {
  const firstDate = convertDotDateString(a.date);
  const secondDate = convertDotDateString(b.date);
  return secondDate.getTime() - firstDate.getTime();
};
