import { TextIntput, Button } from 'components';
import { EmailIcon } from 'components/ui/icons';
import styles from './ForgotForm.module.css';
import { useForm } from 'react-hook-form';
import { useSendEmailMutation } from 'redux/api/auth';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigateLang } from 'hooks';

const ForgotForm = ({ t, ...props }) => {
  const navigate = useNavigateLang();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all'
  });
  const [sendEmail, { isLoading, error }] = useSendEmailMutation();

  useEffect(() => {
    if (error) {
      if(error?.status === 500) {
	      toast(t('something-went-wrong'), { type: 'error' });
      } else {
        const [fieldName] = Object.keys(error?.data?.errors);
        const message = error?.data?.errors[fieldName];
        setError(fieldName, { type: 'message', message });
      }
    }
  }, [error]);

  const onSendEmail = async ({ email }) => {
    try {
      const resp = await sendEmail({ email });

      if (resp?.data?.success === 'ok') {
	      toast(t('email-sent'), { type: 'success' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div {...props}>
      <div className={styles.header}>
        <h1 className={`form-title ${styles.title}`}>{t('forgot-password')}</h1>
        <p className='form-desc'>{t('to-reset-your-password')}</p>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSendEmail)}>
        <div className={styles.passwordBlock}>
          <TextIntput
            label='E-mail'
            type='email'
            placeholder='example@gmail.com'
            icon={<EmailIcon />}
            name='email'
            register={register}
            errors={errors}
            validationSchema={{
              required: t('need-a-help'),
              validate: {
                maxLength: (v) =>
                  v.length <= 50 || t('max-length-alert'),
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  t('email-must-be-valid'),
              },
            }}
          />
          {/*<div className={styles.forgot}>
            <a href='#' className={styles.forgotLink}>
              Not receive the code?
            </a>
          </div>*/}
        </div>
        <Button isLoading={isLoading} type='submit' variant='accent' size='big'>{t('request-password-change')}</Button>
        <div className={styles.registerBlock}>
          <div className={styles.left}>
            <span>{t('dont-have-an-account')}</span>
            <a onClick={() => navigate('/register')} className='form-link'>{t('register-here')}</a>
          </div>
          <div className={styles.rigth}>
            <span>{t('need-a-help')}</span>
          </div>
        </div>
      </form>
      <div className={styles.footer}></div>
    </div>
  );
};

export default withTranslation('forms')(ForgotForm);
