import { useEffect, useRef } from 'react';
import { formatNumber } from 'helpers/utils';
import styles from './Progress.module.css';
import coinGrey from 'assets/images/icons/coin_grey.svg';

const Progress = ({
  value = 0,
  min,
  max,
  percent = 40,
  label = 'Loading...',
  variant='regular', // regular | accent
}) => {
  //const calculatePercentage = value => (value - min) * 100 / (max - min);
  const ref = useRef(null);

  useEffect(() => {
    //ref.current.style.backgroundSize = `${calculatePercentage(value)}% 100%`;
    ref.current.style.backgroundSize = `${parseFloat(percent)}% 100%`;
  }, [percent]);

  return (
    <div className={styles.progress}>
      <div className={styles.label}>{label}</div>
      <div className={`${styles.bar} ${styles[variant]}`} ref={ref}>
        {/*<span className={styles.percent}>{calculatePercentage(value)}%</span>*/}
        <span className={styles.percent}>{percent ? `${percent}%` : null}</span>
      </div>
      <div className={styles.minmax}>
        <div>
          <img src={coinGrey} />
          <div>{formatNumber(min)}</div>
        </div> 
        <div>
          <img src={coinGrey} />
          <div>{formatNumber(max)}</div>
        </div> 
      </div>
    </div>
  )
}

export default Progress;
