import styles from "./InsufficientFunds.module.css"
import { Trans, withTranslation } from "react-i18next"
import UltimateModal from "../UltimateModal"
import Button from "components/ui/Button"
import { useNavigateLang } from "hooks"

const InsufficientFunds = ({
  active,
  setActive,
  withClose,
  t,
}) => {
  const navigate = useNavigateLang();

  const props = {
    children:
      <div className={styles.wrapper}>
        <p className={styles.subtitle}>{t('error')}</p>
        <h2 className={styles.title}>
          <Trans
            t={t}
            i18nKey={"insufficient-funds"}
            components={[<span style={{color:'var(--error, #F46E86)'}}/>]}
          /> 
        </h2>
        <p className={styles.addition}>{t('top-up-your-balance')}</p>
        <Button onClick={() => navigate('/finance#deposit-block')} variant='accent' className={styles.button}>{t('deposit')}</Button>
      </div>,
      type:'products',
      active,
      setActive,
      withClose,
  }

  return <UltimateModal {...props}/>
}

export default withTranslation('cabinet-modals')(InsufficientFunds)
