import UltimateModal from "../UltimateModal";
import styles from "./TfaModal.module.css";
import { Button, TextIntput } from "components";
import { useFormContext } from "react-hook-form";
import { PasswordIcon } from "components/ui/icons";
import { withTranslation } from "react-i18next";

const PasswordChanged = ({
  active,
  setActive,
  withClose = true,
  t,
}) => {
  const {
    register,
    getValues,
    formState: { errors, }
  } = useFormContext();

  const handleButtonClick = () => {
    const values = getValues();

    if (!values.withdrawal_code.length) {
      return;
    }

    setActive(false);
  };

  const modalProps = {
    children: (
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          <span className="accent-text">{t('google-authenticator')}</span>
        </h2>
        <TextIntput
          label={t('enter-code')}
          register={register}
          placeholder="000 000"
          name="withdrawal_code"
          type="number"
          length={6}
          icon={<PasswordIcon />}
          errors={errors}
          validationSchema={{
            required: 'Fill this field!',
          }}
        />
        <div className={styles.bottom}>
          <Button
            variant="accent"
            size='big'
            type="submit" form="withdraw-form"
            onClick={handleButtonClick}
          >
            {t('confirm')}
          </Button>
        </div>
      </div>
    ),
    className: styles.modal,
    active,
    setActive,
    withClose,
  };

  return <UltimateModal {...modalProps} />;
};

export default withTranslation('settings')(PasswordChanged);
