import Card from "./Card";
import styles from "./InvestInfo.module.css"
import image from "assets/images/image-7.png"

const InvestInfoV2 = ({cards = []}) => {
  return(
    <section className={styles.wrapper}>
      <div className={styles.bottom}>
        <div className={styles.cards}>
          {cards.map((card, index) => 
            <Card
              key={index}
              {...card}
            />
          )}
        </div>
        <img className={styles.img} src={image}/>
      </div>
    </section>
  )
}

export default InvestInfoV2;