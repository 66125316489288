import styles from "./TextBuilder.module.css"

const TextBuilder = ({texts}) => {
  return(
    <div className={styles.wrapper}>
      {texts.map((text, index) => 
      <div className={styles.textWrapper}>

        <p className={styles.title}>
          {text.title}
        </p>

        <div className={styles.text}>
          {text.text.map((description, index) =>
            <p className={styles.description}>
              {description}
            </p>
            )}
        </div>

      </div>
      )}
    </div>
  )
}

export default TextBuilder;