import styles from "./Support.module.css"
import { EmailIcon } from "components/ui/icons";
import { Trans } from "react-i18next";
import UltimateModal from "../UltimateModal";
import modalImage2 from "assets/images/image-modal-2.png";
import { withTranslation } from "react-i18next";

const Support = ({ 
  active,
  setActive,
  withClose = true,
  t, 
}) => {
  const props = {
    children: 
    <>
      <h2 className={styles.title}>
        {t('support')}
      </h2>
      <p className={styles.subtitle}>
        <Trans
          t={t}
          i18nKey={"support-will-reply"}
          components={[<span className="accent-text"/>]}
        /> 
      </p>
      <img src={modalImage2}/>
      <p className={styles.email}>
        <EmailIcon className={styles.icon}/> support@cannabetrust.com
      </p>
    </>,
    active,
    setActive,
    withClose,
  }

  return <UltimateModal {...props} />
}

export default withTranslation('settings')(Support);