import styles from "./Cream.module.css"
import ProductsModal from "../ProductsModal";
import img from "assets/images/card-4-normalized.png"
import { withTranslation } from "react-i18next";

const Cream = ({
  active,
  setActive,
  withClose,
  t,
}) => {
  const cards1 = [
    {
      title: t('storage-conditions'),
      text: t('in-the-original-container'),
    },
    {
      title: t('ingredients'),
      text: t('cream-composition'),
    },
  ]

  const cards2 = [
    {
      title: t('cbd'),
      text: `10%`,
    },
    {
      title: t('package-size'),
      text: `5000 ${t('mg')}`,
    },
    {
      title: t('shelf-life'),
      text: `12 ${t('month')}`,
    },
  ]

  const title1 = t('cannabetrust');
  const title2 = `${t('cbd')} ${t('cream')}`;
  const subtitle = t('helps-you-to-make-cbd-skincare');
  const addition = t('with-a-lusciously-smooth-glide');
  const image = <img className={styles.img} src={img} alt={`${t('cbd')} ${t('cream')}`}/>
  const color = '#4FA778'

  const props = {
    title1,
    title2,
    subtitle,
    addition,
    cards1,
    cards2,
    image,
    color,

    active,
    setActive,
    withClose,
  }

  return <ProductsModal {...props} />
}

export default withTranslation('landing-modals')(Cream);;