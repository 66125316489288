import styles from "./StandartedTextRowHalf.module.css"

const StandartedTextRowHalf = ({
  title=<></>,
  subtitle=<></>,
  overtitle=<></>,
  addition=<></>,
  children=<></>
}) => {
  console.log(document.querySelector(`.${styles.overtitle}`))
  return(
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.right}>
        <div className={styles.overtitle}>{overtitle}</div>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.addition}>{addition}</div>
      </div>
      {children}
    </div>
  )
}

export default StandartedTextRowHalf