import Margin from "../Margin";
import Card from "./Card";
import styles from "./MedicalInfo.module.css"
import { withTranslation, Trans } from "react-i18next";
import abstract1 from "assets/images/abstract-1.png";
import abstract2 from "assets/images/abstract-2.png";

const MedicalInfo = ({ t }) => {
  const cards = [
    {
      number: '01', 
      text: t("info-card-1-text")
    },
    {
      number: '02', 
      text: t("info-card-2-text")
    },
    {
      number: '03', 
      text: t("info-card-3-text")
    },
  ]
  return(
    <section className={styles.info}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}> 
          <Trans
            t={t}
            i18nKey={"info-title"}
            components={[<span className="accent-text"/>]}
          /> 
        </h2>
        <img className={styles.abstract1} src={abstract1} />
        <img className={styles.abstract2} src={abstract2} />
        <div className={styles.cards}>
          {cards.map((card, index) => 
            <Card
              key={index}
              {...card}
            />
          )}
        </div> 
      </div>
    </section>
  )
}

export default withTranslation('medical')(MedicalInfo);
