import { TextIntput, Button } from 'components';
import { PasswordIcon } from 'components/ui/icons';
import styles from './ResetForm.module.css';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useResetMutation } from 'redux/api/auth';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import PasswordChanged from 'components/Modals/PasswordChanged';
import { withTranslation } from 'react-i18next';
import { useNavigateLang } from 'hooks';

const ResetForm = ({t, ...props}) => {
  const [active, setActive] = useState();
  const navigate = useNavigateLang();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all'
  });
  const [reset, { isLoading, error }] = useResetMutation();
  const { token } = useParams();

  useEffect(() => {
    if (error) {
      if(error?.status === 500) {
	      toast(t('something-went-wrong'), { type: 'error' });
      } else {
        const [fieldName] = Object.keys(error?.data?.errors);
        const message = error?.data?.errors[fieldName];
        setError(fieldName, { type: 'message', message });
      }
    }
  }, [error]);

  const onSendEmail = async ({ password, password_confirmation }) => {
    try {
      const resp = await reset({ token, password, password_confirmation });

      if (resp?.data?.success === 'ok') {
        setActive(true);
      }
    } catch (error) {
      console.error(error);
	    toast(t('something-went-wrong'), { type: 'error' });
    }
  };

  return (
    <div {...props}>
      <div className={styles.header}>
        <h1 className={`form-title ${styles.title}`}>{t('change-password')}</h1>
        <p className='form-desc'>{t('to-reset-your-password')}</p>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSendEmail)}>
          <TextIntput
            label={t('new-password')}
            type='password'
            placeholder={t('bjhvhgcfxfx')}
            icon={<PasswordIcon />}
            name='password'
            register={register}
            errors={errors}
            validationSchema={{
              required: t('fill-this-field'),
              validate: {
                maxLength: (v) => v.length >= 8 || t('min-length-alert'),
              },
            }}
          />
          <TextIntput
            label={t('confirm-password')}
            type='password'
            placeholder={t('password').toLowerCase()}
            icon={<PasswordIcon />}
            name='password_confirmation'
            register={register}
            errors={errors}
            validationSchema={{
              required: t('fill-this-field'),
              validate: {
                maxLength: (v) => v.length >= 8 || t('min-length-alert'),
              },
            }}
          />
          {/*<div className={styles.forgot}>
            <a href='#' className={styles.forgotLink}>
              Not receive the code?
            </a>
          </div>*/}
        <Button isLoading={isLoading} type='submit' variant='accent' size='big'>{t('change-password')}</Button>
        <div className={styles.registerBlock}>
          <div className={styles.left}>
            <span>{t('dont-have-an-account')}</span>
            <a onClick={() => navigate('/register')} className='form-link'>{t('register-here')}</a>
          </div>
          <div className={styles.rigth}>
            <span>{t('need-a-help')}</span>
          </div>
        </div>
      </form>
      <PasswordChanged active={active} setActive={setActive} />
      <div className={styles.footer}></div>
    </div>
  );
};

export default withTranslation('forms')(ResetForm);
