const WorkIcon = (props) => {
  return(
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
      <path d="M8.99592 12.588V10.4739" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1583 3.13257C15.5666 3.13257 16.6999 4.27423 16.6999 5.68257V8.54924C14.6499 9.74924 11.9416 10.4742 8.9916 10.4742C6.0416 10.4742 3.3416 9.74924 1.2916 8.54924V5.67424C1.2916 4.2659 2.43326 3.13257 3.8416 3.13257H14.1583Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9126 3.12884V2.82384C11.9126 1.80718 11.0876 0.982178 10.0709 0.982178H7.92092C6.90425 0.982178 6.07925 1.80718 6.07925 2.82384V3.12884" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.31201 11.5933L1.46951 13.6841C1.57618 15.0933 2.75035 16.1824 4.16285 16.1824H13.8287C15.2412 16.1824 16.4153 15.0933 16.522 13.6841L16.6795 11.5933" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default WorkIcon