import { TextIntput, Button } from 'components';
import { ProfileIcon, PasswordIcon } from 'components/ui/icons';
import styles from './LoginForm.module.css';
import { useForm } from 'react-hook-form';
import { useLoginMutation } from 'redux/api/auth';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigateLang } from 'hooks';
import Support from 'components/Modals/Support';

const LoginForm = ({ t, ...props }) => {
  const navigate = useNavigateLang();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all'
  });
  const [login, { isLoading, error }] = useLoginMutation();
  const [modal, setModal] = useState();

  useEffect(() => {
    if (error) {
      const [fieldName] = Object.keys(error?.data?.errors);
      const message = error?.data?.errors[fieldName];
      setError(fieldName, { type: 'message', message });
    }
  }, [error]);

  const onSubmit = async ({ email, password }) => {
    try {
      const data = new FormData();
      data.append('email', email);
      data.append('password', password);

      const resp = await login(data);
      if (resp.data?.token) {
        localStorage.setItem('auth_token', resp.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div {...props}>
      <div className={styles.header}>
        <h1 className={`form-title ${styles.title}`}>{t('login')}</h1>
        <p className='form-desc'>{t('please-input-your-information')}</p>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <TextIntput
          icon={<ProfileIcon />}
          label='E-mail'
          placeholder='email'
          name='email'
          type='text'
          errors={errors}
          register={register}
          validationSchema={{
            required: t('fill-this-field'),
            validate: {
              maxLength: (v) =>
                v.length <= 50 || t('max-length-alert'),
              matchPattern: (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                t('email-must-be-valid'),
            },
          }}
        />
        <div className={styles.passwordBlock}>
          <TextIntput
            label={t('password')}
            type='password'
            name='password'
            placeholder={t('password').toLowerCase()}
            icon={<PasswordIcon />}
            errors={errors}
            register={register}
            validationSchema={{
              required: t('fill-this-field'),
              validate: {
                maxLength: (v) => v.length >= 8 || t('min-length-alert'),
              },
            }}
          />
          <div className={styles.forgot}>
            <a onClick={() => navigate('/forgot')} className={styles.forgotLink}>
              {t('forgot-password')}
            </a>
          </div>
        </div>
        <Button
          type='submit'
          variant='accent'
          size='big'
          isLoading={isLoading}
        >
          {t('log-in')}
        </Button>
        <div className={styles.registerBlock}>
          <div className={styles.left}>
            <span>{t('dont-have-an-account')}</span>
            <a onClick={() => navigate('/register')} className='form-link'>{t('register-here')}</a>
          </div>
          <div className={styles.rigth}>
            <span style={{"cursor": "pointer"}} onClick={() => setModal(true)}>{t('need-a-help')}</span>
          </div>
        </div>
      </form>
      <div className={styles.footer}></div>
      <Support active={modal} setActive={setModal} withClose={true}/>
    </div>
  );
};

export default withTranslation('forms')(LoginForm);
