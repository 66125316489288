const ProfileIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
      <g id="Iconly/Regular/Bold/Profile">
        <g id="Profile">
          <path id="Profile_2" fillRule="evenodd" clipRule="evenodd" d="M14.4113 6.30911C14.4113 8.75664 12.4489 10.7192 9.99967 10.7192C7.55126 10.7192 5.58802 8.75664 5.58802 6.30911C5.58802 3.86159 7.55126 1.8999 9.99967 1.8999C12.4489 1.8999 14.4113 3.86159 14.4113 6.30911ZM9.99967 18.5666C6.38499 18.5666 3.33301 17.9791 3.33301 15.7124C3.33301 13.4449 6.40416 12.8782 9.99967 12.8782C13.6152 12.8782 16.6663 13.4657 16.6663 15.7324C16.6663 17.9999 13.5952 18.5666 9.99967 18.5666Z" />
        </g>
      </g>
    </svg>
  );
}

export default ProfileIcon;
