import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'helpers/constants/api';

const baseQueryWithAuthToken = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers) => {
    const authToken = localStorage.getItem('auth_token');
    headers.set('Authorization', `Bearer ${authToken}`);
    return headers;
  },
});

const baseQueryWithLogout = async (args, api, extraOptions) => {
  const result = await baseQueryWithAuthToken(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.removeItem('auth_token');
  } else if (result.error && result.error.status === 500) {
	  // toast('Server Error', { type: 'error' });
  }
  return result;
}

export const commonApi = createApi({
  reducerPath: 'commonApi',
  tagTypes: ['Binary'],
  baseQuery: baseQueryWithLogout,
  endpoints: build => ({
    dashboard: build.query({
      query: () => ({
        url: 'dashboard',
        method: 'POST'
      }),
      providesTags: ['Common']
    }),
    materials: build.query({
      query: () => ({
        url: 'material',
        method: 'POST'
      }),
      providesTags: ['Common']
    }),
    finance: build.query({
      query: () => ({
        url: 'finance',
        method: 'POST'
      }),
      providesTags: ['Common']
    }),
    binary: build.query({
      query: () => ({
        url: 'binary',
        method: 'POST'
      }),
      providesTags: [{ type: 'Binary', id: 'LIST' }],
    }),
    binaryChangePosition: build.mutation({
      query: params => ({
        url: `binary`,
        method: 'POST',
        params
      }),
    }),
    binaryChangeDirection: build.mutation({
      query: params => ({
        url: `binary/position_child`,
        method: 'POST',
        params
      }),
      invalidatesTags: [{ type: 'Binary', id: 'LIST' }],
    }),
    depositHistory: build.query({
      query: () => ({
        url: 'finance/deposit',
        method: 'POST'
      }),
    }),
    withdrawalHistory: build.query({
      query: () => ({
        url: 'finance/withdrawal',
        method: 'POST'
      }),
    }),
    allHistory: build.query({
      query: () => ({
        url: 'finance/all',
        method: 'POST'
      }),
    }),
    packagesAll: build.query({
      query: () => ({
        url: 'packages',
        method: 'POST'
      }),
    }),
    packagesCalc: build.query({
      query: ({ packageId, amount }) => {
        const body = new FormData();
        body.append('package_id', packageId);
        body.append('amount', amount);

        return {
          url: 'package/calc',
          method: 'POST',
          body
        }
      },
    }),
    userPackages: build.query({
      query: () => ({
        url: 'package',
        method: 'POST'
      }),
      providesTags: ['Common']
    }),
    buyPackage: build.mutation({
      query: ({ packageId, amount, code }) => {
        const body = new FormData();
        body.append('package_id', packageId);
        body.append('amount', amount);
        body.append('code', code);

        return {
          url: 'package/buy',
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['Common']
    }),
    depositCalc: build.mutation({
      query: params => ({
        url: 'deposit/calc',
        method: 'POST',
        params
      }),
    }),
    withdrawalCalc: build.mutation({
      query: params => ({
        url: 'withdrawal/calc',
        method: 'POST',
        params
      }),
    }),
    withdrawalCreate: build.mutation({
      query: params => ({
        url: 'withdrawal',
        method: 'POST',
        params
      }),
      invalidatesTags: ['Common']
    }),
    updateSettings: build.mutation({
      query: params => ({
        url: 'setting/update',
        method: 'POST',
        params
      }),
    }),
    contact: build.mutation({
      query: params => ({
        url: 'contact',
        method: 'POST',
        params
      }),
    }),
    structure: build.query({
      query: ({search, user_id})  => ({
        url: 'structure',
        method: 'GET',
        params: {
          search,
          user_id
        }
      }),
    }),
    structureStatistic: build.query({
      query: ()  => ({
        url: 'structure/statistic',
        method: 'GET',
      }),
    }),
  })
});

export const {
  useDashboardQuery,
  useLazyDashboardQuery,
  useMaterialsQuery,
  useFinanceQuery,
  useBinaryQuery,
  useBinaryChangePositionMutation,
  useBinaryChangeDirectionMutation,
  useDepositHistoryQuery,
  useWithdrawalHistoryQuery,
  useAllHistoryQuery,
  usePackagesAllQuery,
  usePackagesCalcQuery,
  useUserPackagesQuery,
  useBuyPackageMutation,
  useDepositCalcMutation,
  useWithdrawalCalcMutation,
  useWithdrawalCreateMutation,
  useUpdateSettingsMutation,
  useContactMutation,
  useStructureStatisticQuery,
  useStructureQuery,
  useLazyStructureQuery,
} = commonApi;
