import Header from "components/Header";
import Button from "components/ui/Button";
import styles from "./NotFoundMain.module.css"
import { withTranslation, Trans } from "react-i18next";
import {useNavigateLang} from "../../../hooks";

const NotFoundMain = ({ t }) => {
  const navigate = useNavigateLang();

  return(
    <div className={styles.wrapper}>
      <Header logoVariant='light'/>
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>
            <Trans
              t={t}
              i18nKey={"ouch-error-404"}
              components={[<span className="accent-text"/>]}
            /> 
          </p>
        </div>
        <div className={styles.line}/>
        <div className={styles.bottomWrapper}>
          <div className={styles.textWrapper}>
            <p className={styles.subTitle}> {t('we-couldnt-find-the-page')} </p>
            <p className={styles.addition}> {t('could-not-be-found')} </p>
          </div>
          <Button
              className={styles.button}
              variant='accent'
              onClick={() => navigate('/')}
          >{t('go-home')}</Button>
        </div>
      </div>
    </div>
  )
}

export default withTranslation('not-a-found')(NotFoundMain);