import { CabinetContainer, Enable2FA } from "components";

const SettingEnable2FA = () => {
  return(
    <CabinetContainer>
      <Enable2FA />
    </CabinetContainer>
  )
}

export default SettingEnable2FA;