import { useEffect, useRef, useState } from "react";

let lastScrollTop = 0;

const Parallax = ({
  children=<></>,
  className,
  style,
}) => {
  const ref = useRef(null)
  const [inView, setInView] = useState(true)

  const handleScroll = () => {
    if(lastScrollTop === 0){
      lastScrollTop = window.scrollY;
      return
    }

    const currentScrollTop = window.scrollY;
    const scrollDelta = currentScrollTop - lastScrollTop;
    const calculatedScroll = parseFloat(ref.current.style.top.replace('px', '')) || 0;
    const parallax = calculatedScroll + scrollDelta / 8;

    ref.current.style.top = `${parallax}px`;

    lastScrollTop = currentScrollTop;
  };

  useEffect(()=>{
    const observe = (entries, observer) =>{
      if (entries[0].isIntersecting) {
        enter(observer)
      }else{
        leave(observer)
      }
    }

    const enter = (observer) => {
      setInView(true);
      //ref.current.style.top = '0';
      window.addEventListener('scroll', handleScroll);
      //observer.unobserve(ref.current);
    }

    const leave = (observer) => {
      setInView(false);
      //ref.current.style.top = '0';
      window.removeEventListener('scroll', handleScroll);
    }

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    }

    const observer = new IntersectionObserver(observe, observerOptions);
    observer.observe(ref.current);
  },[])

  return(
    <div ref={ref} 
      className={className}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0px',
        left: '0px',
      }
    }>
      <div style={{

      }}>
        {children}
      </div>
    </div>
  )
}

export default Parallax;
