import ProductsModal from "../ProductsModal";
import styles from "./Capsules.module.css"
import img from "assets/images/card-2-normalized.png"
import { withTranslation } from "react-i18next";

const Capsules = ({
  active,
  setActive,
  withClose,
  t,
}) => {
  const cards1 = [
    {
      title: '',
      text: t('work-synergistically'),
    },
    {
      title: '',
      text: t('cannabinoids-in-our-products')
    },
    {
      title: '',
      text: t('cgmp-certified')
    },
  ]

  const cards2 = [
    {
      title: t('cbd'),
      text: '150 Mg',
    },
    {
      title: t('cbg'),
      text: `450 ${t('mg')}`,
    },
    {
      title: t('package-size'),
      text: `100 ${t('capsules')}`,
    },
  ]

  const title1 = t('cannabetrust')
  const title2 = `${t('cbd')} ${t('capsules')}`
  const subtitle = t('proprietary-formulation-allows')
  const addition = t('highly-concentrated-in-cbg')
  const image = <img className={styles.img} src={img} alt={`${t('cbd')} ${t('capsules')}`}/>
  const color = '#367755'

  const props = {
    title1,
    title2,
    subtitle,
    addition,
    cards1,
    cards2,
    image,
    color,

    active,
    setActive,
    withClose,
  }

  return <ProductsModal {...props} />
}

export default withTranslation('landing-modals')(Capsules);