const ChartIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path d="M6.64286 8.50195V14.2187" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5318 5.7666V14.2189" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.3572 11.5225V14.2183" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4048 1.66699H6.59526C3.87304 1.66699 2.16669 3.59373 2.16669 6.32129V13.6794C2.16669 16.4069 3.8651 18.3337 6.59526 18.3337H14.4048C17.1349 18.3337 18.8334 16.4069 18.8334 13.6794V6.32129C18.8334 3.59373 17.1349 1.66699 14.4048 1.66699Z" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default ChartIcon;
