import { useState, useRef, useEffect } from 'react';
import styles from './AuthPageContainer.module.css';
import logo4 from 'assets/images/logo-4.svg';
import { useNavigateLang } from 'hooks';

const AuthPageContainer = ({
  children,
}) => {
  const [isWide, setIsWide] = useState(false);
  const contentRef = useRef(null);
  const rightRef = useRef(null);
  const navigate = useNavigateLang();

  useEffect(() => {
    if (!contentRef && !rightRef) {
      return;
    }

    const handleResize = () => {
      const wideCondition = rightRef?.current?.offsetHeight > contentRef?.current?.offsetHeight;
      // window 1000px following the media query
      setIsWide(wideCondition && window.innerWidth > 1000);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <img className={styles.mobileLogo} src={logo4} onClick={() => navigate('/')} />
      <div className={styles.inner}>
        <div className={styles.left}>
          <img src={logo4} />
        </div>
        <div
          className={styles.right}
          ref={rightRef}
          style={{ justifyContent: isWide ? 'center' : 'flex-start' }}
        >
          <div
            className={styles.content}
            ref={contentRef}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPageContainer;
