import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'helpers/constants/api';
import { fetchCsrfToken } from 'helpers/api';

const baseQueryWithCsrfToken = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers) => {
    // const csrfToken = await fetchCsrfToken(API_URL);
    // headers.set('X-CSRF-TOKEN', csrfToken);
    return headers;
  },
});

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithCsrfToken,
  tagTypes: ['Auth'],
  endpoints: build => ({
    login: build.mutation({
      query: credentials => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['Auth'],
    }),
    registration: build.mutation({
      query: credentials => ({
        url: 'auth/registration',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['Auth'],
    }),
    sendEmail: build.mutation({
      query: body => ({
        url: 'auth/password',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Auth'],
    }),
    reset: build.mutation({
      query: body => ({
        url: 'auth/password/reset',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Auth'],
    }),
    referral: build.mutation({
      query: body => ({
        url: `auth/registration/referral`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const { useLoginMutation, useRegistrationMutation, useSendEmailMutation, useResetMutation, useReferralMutation } = authApi;
