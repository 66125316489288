const CoinIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30">
      <path d="M15.0432 22.4026C15.1456 22.1515 15.2721 21.9108 15.4209 21.6841L15.3656 25.0004H13.1732C14.0329 24.3319 14.6821 23.4301 15.0432 22.4026Z" fill="#001007" />
      <path d="M13.4468 20.4023C14.131 20.7156 14.7928 21.0757 15.4274 21.4801C15.1418 21.4156 14.2483 21.1761 13.9166 21.0472C10.2871 19.5548 6.05876 20.4207 3.78339 21.0472L6.60227 15.5752C8.4384 17.7377 10.7935 19.3986 13.4468 20.4023Z" fill="#001007" />
      <path d="M13.9191 22.0037C14.4142 21.8287 14.9186 21.681 15.4299 21.5615C15.1881 21.7279 14.9286 21.867 14.6561 21.9761C13.1716 22.5204 11.9346 23.5839 11.1739 24.97H1.75L3.33447 21.8839C5.43482 22.5933 9.68158 23.8001 13.9191 22.0037Z" fill="#001007" />
      <path d="M14.7953 19.0484C15.0585 19.7382 15.271 20.4463 15.431 21.1671V21.3882C15.2007 20.9921 14.5742 19.9235 14.39 19.4721C13.2228 16.3926 11.2035 13.7088 8.56799 11.734L12.0409 5H14.3255C13.2753 8.823 12.3081 13.9725 14.7953 19.0484Z" fill="#001007" />
      <path d="M17.876 24.9992H15.6651L15.6098 21.6553C15.7665 21.8901 15.8992 22.1401 16.0059 22.4014C16.3734 23.4256 17.0214 24.3257 17.876 24.9992Z" fill="#001007" />
      <path d="M16.673 19.4721C16.339 20.139 15.97 20.7879 15.5676 21.4159V21.1487C15.7137 20.4304 15.9265 19.7272 16.2032 19.0484C18.7734 14.4423 17.7508 9.00724 16.6638 5H18.9484L22.4398 11.7432C19.8285 13.7255 17.8297 16.4045 16.673 19.4721Z" fill="#001007" />
      <path d="M17.6044 20.4035C20.2637 19.4147 22.6261 17.7626 24.4673 15.604L27.2954 21.0668C24.9924 20.4403 20.7733 19.5928 17.1622 21.0668C16.8213 21.2049 15.8909 21.4444 15.633 21.5089C16.2648 21.097 16.9234 20.7277 17.6044 20.4035Z" fill="#001007" />
      <path d="M29.3265 24.9992H19.8842C19.1209 23.6153 17.8848 22.5525 16.402 22.0053C16.125 21.8897 15.8597 21.7478 15.6098 21.5815C16.1275 21.7037 16.638 21.8544 17.139 22.0329C21.3765 23.8753 25.6325 22.6225 27.7329 21.9408L29.3265 24.9992Z" fill="#001007" />
    </svg>
  );
}

export default CoinIcon;
