import styles from "./Margin.module.css"

const Margin = ({direction="H", screen=1920,  margin=0, minMargin=0}) => {

  const style = {
    marginBottom: direction === "H" ? `${(margin / screen) * 100}vw` : 0,
    marginLeft: direction === "V" ? `${(margin / screen) * 100}vw` : 0,
  }

  return(
    <div 
      className={`
      ${styles.wrapper}
      ${styles[direction]}
      `}
      style={style}
    />
  )
}

export default Margin;