import { AdditionalInfo, CommonIntro, InvestInfo, MedicalInfo, OfferInfo, PageContainer } from "components"
import { withTranslation } from "react-i18next"

const Medical = ({ t }) => {
  return (
    <PageContainer>
      <CommonIntro 
        title={t("intro-title")}
        subtitle={t("intro-subtitle")}
        number='03' 
      />
      <MedicalInfo/>
      <OfferInfo/> {/* ТУТ ВЕСЬ ОСНОВНОЙ КОНТЕНТ, НУЖНО ПЕРЕИМЕНОВАТЬ КОМПОНЕНТ В НАПРИМЕР MEDICALMAIN
      А ТАК ЖЕ ЕСТЬ КОМПОНЕНТ INVESTINTRO КОТОРЫЙ ТОЖЕ НУЖНО ПЕРЕИМЕНОВАТЬ В СООТВЕТСТВИИ С РОЛЬЮ */}
    </PageContainer>
  )
}

export default withTranslation("medical")(Medical)
