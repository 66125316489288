import { formatNumber } from 'helpers/utils';
import { useEffect, useRef } from 'react';
import styles from './Slider.module.css';

const Slider = ({
  min,
  max,
  value,
  onChange,
  ...props
}) => {
  const calculatePercentage = value => `${(value - min) * 100 / (max - min)}% 100%`;
  const ref = useRef(null);

  useEffect(() => {
    ref.current.style.backgroundSize = calculatePercentage(ref.current.value);
  }, [value]);

  const handleChange = e => {
    e.target.style.backgroundSize = calculatePercentage(e.target.value);

    if (typeof onChange === 'function') {
      onChange(e.target.value);
    }
  }

  return (
    <div className={styles.sliderWrapper}>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        ref={ref}
        {...props}
      />
      <div className={styles.label}>
        <div>{`${formatNumber(min)}$`}</div>
        <div>{`${formatNumber(max)}$`}</div>
      </div>
    </div>
  );
};

export default Slider;
