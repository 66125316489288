const ProfileOutlineIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.4873 11.2891C4.26428 11.2891 1.5119 11.7764 1.5119 13.728C1.5119 15.6795 4.24682 16.1843 7.4873 16.1843C10.7103 16.1843 13.4619 15.6962 13.4619 13.7454C13.4619 11.7946 10.7278 11.2891 7.4873 11.2891Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.48729 8.50523C9.60236 8.50523 11.3167 6.79015 11.3167 4.67507C11.3167 2.55999 9.60236 0.845703 7.48729 0.845703C5.37221 0.845703 3.65713 2.55999 3.65713 4.67507C3.64998 6.783 5.35316 8.49808 7.4603 8.50523H7.48729Z" stroke="black" strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default ProfileOutlineIcon;
