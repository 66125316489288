import styles from './MenuItem.module.css';

const MenuItem = ({
  text,
  icon,
  active,
  isCompact,
  ...props
}) => {

  return (
    <li className={styles.menuItem} style={isCompact ? { gap: 0, justifyContent: 'center' } : null} {...props}>
      <div className={`${styles.content} ${active ? styles.active : ''}`} >
        <div className={styles.iconWrapper}>
          {icon}
        </div>
        {!isCompact ? <div className={styles.text}>{text}</div> : null }
      </div>
    </li>
  );
};

export default MenuItem;
