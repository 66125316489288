import styles from "./OfferInfo.module.css"
import img1 from "assets/images/image-1.png"
import img3 from "assets/images/image-3.png"
import StandartedTextColumn from "../StandartedTextColumn";
import InvestInfo from "../InvestInfo";
import AdditionalInfo from "../AdditionalInfo";
import StandartedTextRow from "../StandartedTextRow";
import LettersComponent from "components/LettersComponent";
import Margin from "../Margin";
import StandartedTextColumnV2 from "../StandartedTextColumnV2";
import StandartedTextRowV2 from "../StandartedTextRowV2";
import StandartedTextRowV3 from "../StandartedTextRowV3";
import StandartedTextRowV6 from "../StandartedTextRowV6";
import { withTranslation, Trans } from "react-i18next";

const OfferInfo = ({ t }) => {
  const offerInfo = {
    title: 
      <Trans
        t={t}
        i18nKey={"offer-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle: 
      <Trans
        t={t}
        i18nKey={"offer-subtitle"}
        components={[<span className="accent-text"/>]}
      />,
    addition: 
      <Trans
        t={t}
        i18nKey={"offer-addition"}
        components={[]}
      />
  }
  const investInfo = {
    title: 
      <Trans
        t={t}
        i18nKey={"invest-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle: 
      <Trans
        t={t}
        i18nKey={"invest-subtitle"}
        components={[<span className="accent-text"/>]}
      />,
    addition: 
      <Trans
        t={t}
        i18nKey={"invest-addition"}
        components={[<span className="bold-accent-text"/>]}
      />,
  }
  const factsInfo = {
    title: 
      <Trans
        t={t}
        i18nKey={"facts-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle: 
      <Trans
        t={t}
        i18nKey={"facts-subtitle"}
        components={[<span className="accent-text"/>]}
      />,
    addition: 
      <Trans
        t={t}
        i18nKey={"facts-addition"}
        components={[]}
      />,
  }
  const opportunityInfo = {
    title: 
      <Trans
        t={t}
        i18nKey={"o9y-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle: 
      <Trans
        t={t}
        i18nKey={"o9y-subtitle"}
        components={[<span className="accent-text"/>]}
      />,
    addition: 
      <Trans
        t={t}
        i18nKey={"o9y-addition"}
        components={[<span className="bold-accent-text"/>]}
      />,
  }
  const cards = [
    {
      number : 
        <Trans
          t={t}
          i18nKey={"card-1-number"}
          components={[<span className="accent-text"/>]}
        />,
      title : 
        <Trans
          t={t}
          i18nKey={"card-1-title"}
          components={[<span className="accent-text"/>]}
        />,
      description : 
        <Trans
          t={t}
          i18nKey={"card-1-description"}
          components={[<span className="accent-text"/>]}
        />,
    },
    {
      number : 
        <Trans
          t={t}
          i18nKey={"card-2-number"}
          components={[<span className="accent-text"/>]}
        />,
      title : 
        <Trans
          t={t}
          i18nKey={"card-2-title"}
          components={[<span className="accent-text"/>]}
        />,
      description : 
        <Trans
          t={t}
          i18nKey={"card-2-description"}
          components={[<span className="accent-text"/>]}
        />,
    },
  ]
  return(
    <section className={styles.wrapper}>
      <Margin margin={165}/>
      <StandartedTextColumnV2
        {...offerInfo}
      >
        <img className={styles.img} src={img1} /> 
      </StandartedTextColumnV2>
      <Margin margin={100} />
      <StandartedTextRowV6
        {...investInfo}
      />
      <Margin margin={100}/>
      <InvestInfo cards={cards}/>
      <AdditionalInfo>
        <p className={styles.ourProductsText}> 
          <Trans
            t={t}
            i18nKey={"our-products"}
            components={[<span className="white-accent-text"/>]}
          />, 
        </p>
        </AdditionalInfo>
      <Margin margin={100} />
      <StandartedTextColumnV2
        {...factsInfo}
      />
      <Margin margin={150} />
      <StandartedTextRow
        {...opportunityInfo}
      />
      <div className={styles.letters}>
        <LettersComponent isAccent isDark />
        <img className={styles.img3} src={img3}/>
      </div>
      <Margin margin={200}/>
    </section>
  )
}

export default withTranslation("medical")(OfferInfo);
