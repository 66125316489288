import styles from './StoreProducts.module.css';
import Product from './Product';
import product1 from 'assets/images/product-1.png';
import product2 from 'assets/images/product-2.png';
import product3 from 'assets/images/product-3.png';
import product4 from 'assets/images/product-4.png';

const StoreProducts = () => {
  const productList = [
    {
      id: 1,
      title: 'Broad Spectrum CBD Oil',
      subtitle: 'Hemp-derived cannabidiol',
      buttonText: 'Out of stock',
      dosageLeft: ['10 ml/0,33 Fl.oz'],
      dosageRight: ['20%', '2000 mg'],
      price: 55,
      img: product1,
      status: 'out', // 'out', 'soon', 'available'
    },
    {
      id: 2,
      title: 'Broad Spectrum CBD Oil',
      title: 'Neurogan CBD Capsules',
      subtitle: 'Highly concentrated in CBG',
      buttonText: 'Comming soon',
      dosageLeft: ['650 mg'],
      dosageRight: ['450 mg CBG', '150 mg CBD'],
      price: 55,
      img: product2,
      status: 'soon',
    },
    {
      id: 3,
      title: 'Neurogan CBD Gummy bears',
      subtitle: 'Full spectrum CBD',
      buttonText: 'Comming soon',
      dosageLeft: ['20mg CBD'],
      dosageRight: ['25 bears', 'Wild berry flavor'],
      price: 55,
      img: product3,
      status: 'soon',
    },
    {
      id: 4,
      title: 'Neurogan CBD Cream',
      subtitle: 'Only hand-selected the finest skin ingredients',
      buttonText: 'Out of stock',
      dosageLeft: ['10%', '5000 mg'],
      dosageRight: [],
      price: 55,
      img: product4,
      status: 'out',
    },
  ];

  return (
    <section className={styles.storeProducts}>
      {productList.map((product, index) => 
        <Product key={index} {...product} />
      )}
    </section>
  )
}

export default StoreProducts;
