const StoreIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.7615 18.4908H6.80495C4.24965 18.4908 2.28931 17.5678 2.84614 13.8531L3.4945 8.81879C3.83775 6.96524 5.02005 6.25586 6.05743 6.25586H14.5395C15.5921 6.25586 16.7058 7.01864 17.1024 8.81879L17.7508 13.8531C18.2237 17.1483 16.3168 18.4908 13.7615 18.4908Z" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.8759 6.07299C13.8759 4.0846 12.264 2.47269 10.2756 2.47269V2.47269C9.31809 2.46863 8.39842 2.84616 7.71993 3.52178C7.04144 4.19741 6.66003 5.11548 6.66003 6.07299H6.66003" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.747 9.82572H12.7088" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.88807 9.82572H7.84993" stroke="#8F939C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default StoreIcon;
