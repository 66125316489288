import { AuthPageContainer, RegisterForm } from "components"

const Register = () => {
  return (
    <div>
      <AuthPageContainer>
        <RegisterForm />
      </AuthPageContainer>
    </div>
  )
}

export default Register;
