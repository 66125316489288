import styles from "./AccentCards.module.css"

const AccentCards = ({number, title, description, children}) => {
  return(
    <div className={styles.wrapper}>
      <div className={styles.number}>{number}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      {children}
      <div className={styles.background}></div>
    </div>
  )
}

export default AccentCards;