import UltimateModal from "../UltimateModal";
import styles from "./Registered.module.css"
import img from "assets/images/image-modal-4.png"
import { withTranslation, Trans } from "react-i18next";

const Registered = ({
  active,
  setActive,
  withClose,
  t,
}) => {
  const modalProps = {
    children:(
      <>
        <h2 className={styles.title}>
          {t('Congratulations!')}
        </h2>
        <p className={styles.subtitle}>
          <span className="accent-text">{t('you-withdraw-successful')}</span>
        </p>
        <img src={img} alt="Password Change" />
        <p className={styles.text}>
          <Trans 
            t={t}
            i18nKey={"we-recommend-you-go-to-settings"}
          />
        </p>
      </>
    ),
    active,
    setActive,
    withClose,
  }

  return <UltimateModal {...modalProps}/>
}

export default withTranslation('settings')(Registered);