import styles from './CircularProgress.module.css';

const CircularProgress = ({
  size = 120,
  progress = 0,
  trackWidth = 10,
  trackColor = `#CED0D6`,
  indicatorWidth = 10,
  indicatorColor = '#4FA778',
  indicatorCap = 'round',
  label = 'Loading...',
  labelColor = '#001007',
  progressLabel,
  spinnerMode = false,
  spinnerSpeed = 1
}) => {
  const center = size / 2;
  const radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  let hideLabel = (size < 100 || !label.length || spinnerMode) ? true : false

  return (
    <>
      <div
        className={styles.svgPiWrapper}
        style={{ width: size, height: size }}
      >
        <svg
          className="svg-pi"
          style={{ width: size, height: size }}
        >
          <circle
            className="svg-pi-track"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            className={`svg-pi-indicator ${spinnerMode ? "svg-pi-indicator--spinner" : ""}`}
            style={{ animationDuration: spinnerSpeed * 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>

        {!hideLabel && (
          <div
            className={styles.svgPiLabel}
            style={{ color: labelColor }}
          >
            <span className={styles.svgPiLabel__loading}>
              {label}
            </span>

            {!spinnerMode && (
              <span className={styles.svgPiLabel__progress}>
                {progressLabel ? progressLabel : `${progress > 100 ? 100 : progress}%`}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default CircularProgress;
