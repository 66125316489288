import { PageContainer, MainPage } from "components";

const Main = () => {
  return (
    <PageContainer>
      <MainPage />
    </PageContainer>
  );
};

export default Main;
