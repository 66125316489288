import LettersComponent from "components/LettersComponent";
import Card from "./Card";
import styles from "./OurAdvantages.module.css"
import img8 from "assets/images/Image-8.png"
import { withTranslation, Trans } from "react-i18next";

const OurAdvantages = ({ t }) => {
  const cardList = [
    {title: '01', subtitle: t('increase-capital')},
    {title: '02', subtitle: t('create-capital')},
    {title: '03', subtitle: t('factory')},
    {title: '04', subtitle: t('bonuses')},
  ]
  return(
    <section className={styles.advantages}>
      <div className={styles.textWrapper}>
        <h2 className={styles.firstText}>
          <Trans
            t={t}
            i18nKey='adventages-text'
            components={[<span className="white-accent-text" />]}
          />
        </h2>
        <p className={styles.secondText}>
          <Trans
            t={t}
            i18nKey='our-adventages'
          />
        </p>
      </div>
      <div className={styles.infoWrapper}>
        {cardList.map((card, index) => 
          <Card
            key={index}
            {...card}
          />
        )}
      </div>
      <img className={styles.infoImg} src={img8}/>
      <div className={styles.letters}>
      <LettersComponent/>
      </div>
    </section>
  )
}

export default withTranslation('our-products')(OurAdvantages);
