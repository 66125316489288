import { Button } from 'components';
import { useEffect, useState } from 'react';
import styles from './ButtonGroup.module.css';

const ButtonGroup = ({
  buttons, // [{ text: string, color: string }]
  label,
  value,
  onChange,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    if (typeof value === 'number') {
      setSelectedItem(value);
    }
  }, [value]);

  const handleSelect = index => {
    setSelectedItem(index);

    if (typeof onChange === 'function') {
      onChange({...buttons[index], index});
    }
  }

  return (
    <div className={styles.buttonGroup} {...props}>
      <div className={styles.label}>{label}</div>
      <div className={styles.buttonList}>
        {buttons ? buttons.map(({ color, text }, index) => 
          <Button
            key={index}
            variant='outline-dark'
            active={selectedItem === index}
            onClick={() => handleSelect(index)}
            color={color}
          >
            {text}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default ButtonGroup;
