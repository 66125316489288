const ArrowRightSquareIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.7083 12.1113V4.88717C16.7083 2.37051 14.9258 0.79134 12.4033 0.79134H5.59667C3.07417 0.79134 1.29167 2.36217 1.29167 4.88717L1.29167 12.1113C1.29167 14.6363 3.07417 16.208 5.59667 16.208H12.4033C14.9258 16.208 16.7083 14.6363 16.7083 12.1113Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.405 8.49935H5.595" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.26858 5.37638L12.4052 8.49971L9.26858 11.623" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowRightSquareIcon;
