import { TextIntput, Button, Checkbox } from 'components';
import { ProfileIcon, PasswordIcon, EmailIcon } from 'components/ui/icons';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRegistrationMutation } from 'redux/api/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './RegisterForm.module.css';
import { withTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useNavigateLang } from 'hooks';

const RegisterForm = ({ t, ...props }) => {
  const recaptchaRef = useRef();
  const [regActive, setRegActive] = useState();
  const navigate = useNavigateLang();
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all'
  });
  const [registration, { isLoading, error }] = useRegistrationMutation();

  useEffect(() => {
    const sponsorLogin = localStorage.getItem('sponsor_login');

    if (sponsorLogin) {
      setValue('sponsor_login', sponsorLogin);
    }
  }, []);

  useEffect(() => {
    if (error) {
      const [fieldName] = Object.keys(error?.data?.errors);
      const message = error?.data?.errors[fieldName];
      setError(fieldName, { type: 'message', message });
    }
  }, [error]);

  const handleTermsChange = e => {
    setRegActive(e.target.checked);
  }

  const onSubmit = async data => {
    try {
      const formData = new FormData();

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }

      const token = recaptchaRef.current.getValue();

      formData.append('name', data.login);
      formData.append('email_confirmation', data.email);
      formData.append('terms', 1);
      formData.append('g-recaptcha-response', token);

      const resp = await registration(formData);

      if (resp.data?.token) {
        localStorage.setItem('auth_token', resp.data.token);
        navigate('/dashboard');
      } else {
        const message = resp?.data?.error || resp?.error?.data?.message || 'Something went wrong';
        toast(message, { type: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div {...props}>
      <div className={styles.header}>
        <h1 className={`form-title ${styles.title}`}>{t('registration-account')}</h1>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <TextIntput
          icon={<ProfileIcon />}
          label={t('sponsor-login')}
          placeholder={t('login').toLowerCase()}
          name='sponsor_login'
          errors={errors}
          register={register}
          validationSchema={{
            required: t('fill-this-field'),
          }}
        />
        <TextIntput
          icon={<ProfileIcon />}
          label={t('your-login')}
          placeholder={t('login').toLowerCase()}
          name='login'
          errors={errors}
          register={register}
          validationSchema={{
            required: t('fill-this-field'),
          }}
        />
        <TextIntput
          icon={<EmailIcon />}
          type='email'
          label='E-mail'
          placeholder='example@gmail.com'
          name='email'
          errors={errors}
          register={register}
          validationSchema={{
            required: t('fill-this-field'),
            validate: {
              maxLength: (v) =>
                v.length <= 50 || t('max-length-alert'),
              matchPattern: (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                t('email-must-be-valid'),
            },
          }}
        />
        <TextIntput
          label={t('password')}
          type='password'
          placeholder={t('password').toLowerCase()}
          icon={<PasswordIcon />}
          name='password'
          errors={errors}
          register={register}
          validationSchema={{
            required: t('fill-this-field'),
            validate: {
              maxLength: (v) => v.length >= 8 || t('min-length-alert'),
            },
          }}
        />
        <TextIntput
          label={t('confirm-password')}
          type='password'
          placeholder={t('password').toLowerCase()}
          icon={<PasswordIcon />}
          name='password_confirmation'
          errors={errors}
          register={register}
          validationSchema={{
            required: t('fill-this-field'),
            validate: {
              maxLength: (v) => v.length >= 8 || t('min-length-alert'),
            },
          }}
        />
        <Checkbox 
          onChange={handleTermsChange} 
          label={
            <Trans
              t={t}
              i18nKey={"i-agree"}
              components={[
                <span className="accent-text bold-text" onClick={() => navigate('/privacy-policy')}/>, 
                <span className="accent-text bold-text" onClick={() => navigate('/terms')}/>
              ]}
            />
          } 
          name='privacy' 
        />
        
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LdNKQcgAAAAAMMreoE6ChIW8Y66MbgcJ74KaEGX"
        />
        <Button type='submit' variant='accent' size='big' disabled={!regActive} isLoading={isLoading}>{t('register-now')}</Button>
        <div className={styles.registerBlock}>
          <div className={styles.left}>
            <span>{t('have-an-account')}</span>
            <a onClick={() => navigate('/login')} className='form-link'>{t('login-here')}</a>
          </div>
          <div className={styles.rigth}>
            <span>{t('need-a-help')}</span>
          </div>
        </div>
      </form>
      <div className={styles.footer}></div>
    </div>
  );
};

export default withTranslation('forms')(RegisterForm);
