import { Button, CircularProgress } from "components";
import styles from "./BonusCard.module.css";
import { useDashboardQuery } from "redux/api/common";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigateLang } from "hooks";

const BonusCard = ({ t, ...props }) => {
  const navigate = useNavigateLang();
  const { data } = useDashboardQuery();
  const [isExpired, setIsExpired] = useState();

  useEffect(() => {
    if (data && parseInt(data?.promotion_bonus.days) === 0) {
      setIsExpired(true);
    }
  }, [data]);

  return (
    <div className={styles.card} {...props}>
      {isExpired && <div className={styles.expired} />}
      <div className={styles.header}>
        <div className={styles.suptitle}>{t('receive-your')}</div>
        <div className={styles.title}>{t('quick-start-bonus')}</div>
      </div>
      <div className={styles.indicator}>
        <CircularProgress
          label={t('left')}
          progressLabel={`${data?.promotion_bonus.days} ${t('days')}`}
          progress={data?.promotion_bonus.percent}
          trackWidth={10}
          indicatorWidth={10}
        />
      </div>
      <Button
        className={styles.btn}
        onClick={() => navigate('/finance')}
        variant="accent"
      >
        {t('deposit')}
      </Button>
    </div>
  );
};

export default withTranslation('cabinet-header')(BonusCard);
