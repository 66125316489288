import styles from "./Card.module.css";
import { EyeIcon } from "components/ui/icons";
import { Button } from "components";
import { withTranslation } from "react-i18next";

const Card = ({ number, img, name, onClick, t }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.document}>
        <img className={styles.img} src={img} />
        <p className={styles.number}>
          {(number + 1).toString().padStart(2, "0")}
        </p>

        <Button
          onClick={onClick}
          className={styles.button}
          icon={<EyeIcon />}
          variant="accent"
        >
          {t('documents-button-text')}
        </Button>
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.type}>{t('documents-type-text')}</p>
        <p className={styles.name}>{name}</p>
      </div>
    </div>
  );
};

export default withTranslation('aboutus')(Card);
