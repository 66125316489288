import { useNavigateLang } from "hooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useReferralMutation } from "redux/api/auth";

const Pro = () => {
  const [getReferral] = useReferralMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigateLang();

  const getLoginByReferralCode = async () => {
    const referral = searchParams.get('referral');

    if (!referral) {
      navigate('/');
      return;
    }

    const resp = await getReferral({ referral });

    if (resp?.data?.login) {
      localStorage.setItem('sponsor_login', resp.data.login);
    } 

    navigate('/');
  };

  useEffect(() => {
    getLoginByReferralCode()
  }, []);

  return (
    <div style={{backgroundColor: 'var(--accent)', height: '100%'}}>
      <div className="lds-container">
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        Redirecting...
      </div>
    </div>
  );
}

export default Pro;
