import styles from "./ProductsIntro.module.css";
import { Reveal } from "components";
import card1 from "assets/images/card-1.png";
import card2 from "assets/images/card-2.png";
import card3 from "assets/images/card-3.png";
import card4 from "assets/images/card-4.png";
import { useEffect, useRef, useState } from "react";
import { withTranslation, Trans } from "react-i18next";

const ProductsIntro = ({ t }) => {
  const [load, setLoad] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const { top, height } = container.getBoundingClientRect();
        const screenHeight = window.innerHeight;

        const offsetY = top - screenHeight;

        setLoad(offsetY < 0);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className={styles.intro}>
      <div className={styles.titles}>
        <Reveal>
          <div className={`${styles.title} heading`}>{t('intro-title-cannabeturst')}</div>
        </Reveal>
        <Reveal>
          <div
            className={`${styles.title} ${styles.rightTitle} heading accent-text`}
          >
            {t('intro-profitable')}  
          </div>
        </Reveal>
        <Reveal>
          <div className={`${styles.title} ${styles.centerTitle} heading`}>
            {t('intro-investment')} 
          </div>
        </Reveal>
      </div>
      <div className={styles.subtitle}>
        <Reveal>
          <h2 className={styles.introSubText}>
            <Trans
              t={t}
              i18nKey="intro-exports"
              components={[<span className='accent-text' />]}
            />
          </h2>
        </Reveal>
        <Reveal>
          <h2 className={styles.introCompany}>
            <Trans
              t={t}
              i18nKey="intro-company"
              components={[<span className='accent-text' />]}
            />
          </h2>
        </Reveal>
      </div>
      <div className={styles.figureContainer} ref={containerRef}>
        <div className={`${styles.figureCircle} ${load && styles.onLoad}`}>
          <div className={styles.figure}>
            <img className={styles.figureImage} src={card3} />
          </div>
          <div className={styles.figure}>
            <img className={styles.figureImage} src={card4} />
          </div>
          <div className={styles.figure}>
            <img className={styles.figureImage} src={card1} />
          </div>
          <div className={styles.figure}>
            <img className={styles.figureImage} src={card2} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('our-products')(ProductsIntro);
