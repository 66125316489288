import styles from "./LettersComponent.module.css";

const LettersComponent = ({ counts, percent, isAccent, isDark }) => {
  return (
    <div className={styles.frameParent}>
      {/* <div className={styles.line} /> */}
      <div className={`${styles.frameGroup} ${isDark ? styles.dark : null}`}>
        {counts > 0 && (
          <div className={`${styles.whiteLine} ${isDark ? styles.bgDark : null}`} style={{ width: `${percent}%` }} />
        )}
        <div className={styles.iParent}>
          <div className={`${styles.i} ${percent > 0 ? styles.bolder : ""}`}>
            I
          </div>
          <div className={`${styles.i} ${percent >= 12 ? styles.bolder : ""}`}>
            N
          </div>
        </div>
        <div className={`${styles.iParent} ${isAccent ? styles.accentLetter : null}`}>
          <div className={`${styles.i} ${percent >= 18 ? styles.bolder : ""}`}>
            G
          </div>
          <div className={`${styles.i} ${percent >= 25 ? styles.bolder : ""}`}>
            R
          </div>
          <div className={`${styles.i} ${percent >= 30 ? styles.bolder : ""}`}>
            E
          </div>
          <div className={`${styles.i} ${percent >= 35 ? styles.bolder : ""}`}>
            E
          </div>
          <div className={`${styles.i} ${percent >= 44 ? styles.bolder : ""}`}>
            N
          </div>
        </div>
        <div className={styles.iParent}>
          <div className={`${styles.i} ${percent >= 46 ? styles.bolder : ""}`}>
            G
          </div>
          <div className={`${styles.i} ${percent >= 50 ? styles.bolder : ""}`}>
            O
          </div>
          <div className={`${styles.i} ${percent >= 55 ? styles.bolder : ""}`}>
            L
          </div>
          <div className={`${styles.i} ${percent >= 60 ? styles.bolder : ""}`}>
            D
          </div>
        </div>
        <div className={styles.iParent}>
          <div className={`${styles.i} ${percent >= 65 ? styles.bolder : ""}`}>
            W
          </div>
          <div className={`${styles.i} ${percent >= 70 ? styles.bolder : ""}`}>
            E
          </div>
        </div>
        <div className={styles.iParent}>
          <div className={`${styles.i} ${percent >= 75 ? styles.bolder : ""}`}>
            T
          </div>
          <div className={`${styles.i} ${percent >= 80 ? styles.bolder : ""}`}>
            R
          </div>
          <div className={`${styles.i} ${percent >= 85 ? styles.bolder : ""}`}>
            U
          </div>
          <div className={`${styles.i} ${percent >= 90 ? styles.bolder : ""}`}>
            S
          </div>
          <div className={`${styles.i} ${percent >= 95 ? styles.bolder : ""}`}>
            T
          </div>
        </div>
      </div>
      {/* <img className={styles.groupChild} alt="" src="/vector-154.svg" /> */}
    </div>
  );
};

export default LettersComponent;
