import styles from "./PackagesInfo.module.css"
import { WorkIcon } from "components/ui/icons";
import coin from "assets/images/icons/coin.svg";
import logo from "assets/images/icons/cannabi-green.svg";
import { useUserPackagesQuery } from "redux/api/common";
import { withTranslation } from "react-i18next";
import { sortByDate } from "helpers/utils";

const PackagesInfo = ({ t }) => {
  const { data } = useUserPackagesQuery();

  const cards = [
    {
      name: t('total-active-packages'),
      value: <div className={styles.value}><p><span className="accent-text">{data?.count_active}</span> {t('packages').toLowerCase()}</p></div>,
    },
    {
      name: t('total-investment'),
      value: <div className={styles.value}><img src={coin} /><p>{data?.total_invested} CAN</p></div>
    }
  ];

  const colors = {
    Seed: '#87C7AB',
    Vega: '#67B793',
    'Canna Hold': '#6F7DD7',
    Bloom: '#489B6D',
    Harvest: '#367755',
    Shareholder: '#26543D',
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.cards}>
        {cards.map((card, index) =>
          <div key={index} className={styles.card}>
            <div className={styles.content}>
              <p>{card.name}</p>
              {card.value}
            </div>
            <div className={styles.icon}><WorkIcon /></div>
          </div>
        )}
      </div>

      <div className={styles.cannaHoldCards}>
        {data?.user_packages &&
        data?.user_packages
        .slice()
        .sort(sortByDate)
        .sort((a, b) => !a.is_active && b.is_active ? 1 : a.is_active && !b.is_active ? -1 : 0)
        .map(({
          package: userPackage,
          date,
          amount,
          received_amount,
          total_amount,
          days_left,
          is_active,
        }) => {
          return (
            <div key={userPackage.id} className={`${styles.cannaHoldCard} ${styles.expired}`}>
              {!is_active &&
              <>
                <span className={styles.overlayText}>{t('completed')}</span>
                <div className={styles.overlay}></div>
              </>}
              <div className={styles.top} style={{ backgroundColor: is_active ? colors[userPackage.name] : 'grey' }}>
                <p className={styles.title}>
                  {userPackage.name}
                </p>
                <p className={styles.text}>
                  {`${t('activation-date')} ${date}`}
                </p>
                <p className={styles.text}>
                  {`${t('days-left')} ${days_left}`}
                </p>
              </div>

              <div className={styles.content}>
                <div>
                  <p className={styles.name}>{t('total-investment')}</p>
                  <div className={styles.value}>
                    <img src={coin} />
                    <p>{amount}</p>
                  </div>
                </div>
                <div className={styles.logo}>
                  <img src={logo} />
                </div>
                <div>
                  <p className={styles.name}><span className="accent-text">{t('total-earned')}</span></p>
                  <div className={styles.value}>
                    <img src={coin} />
                    <p>{received_amount}</p>
                  </div>
                </div>
                <div>
                  <p className={styles.name}>{t('total-will-earn')}</p>
                  <div className={styles.value}>
                    <img src={coin} />
                    <p>{total_amount}</p>
                  </div>
                </div>
              </div>
            </div>)
        })}
      </div>
    </div>
  )
}

export default withTranslation('packages')(PackagesInfo);
