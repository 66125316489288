import styles from "./Card.module.css"
import Button from "components/ui/Button"
import { ArrowRightIcon } from "components/ui/icons"
import { useDispatch } from "react-redux"
import { setModal } from "redux/slices/commonSlice"

const Card = ({ number, title, name, description, image, modal }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.card}>
      <p className={styles.number}>{number}</p>
      <h2 className={styles.title}>{title}<br /><span className="accent-text">{name}</span></h2>
      <p className={styles.description}>{description}</p>
      <div className={styles.btnWrapper}>
        <Button
          icon={<ArrowRightIcon style={{ filter: 'invert(100%)' }} />}
          variant={'accent'}
          className={styles.button}
          onClick={() => dispatch(setModal(modal))}
        >
          <p className={styles.buttonText}>More </p>
        </Button>
      </div>
      <img className={styles.image} src={image} />
    </div>
  )
}

export default Card;
