import { ContactUsMain, PageContainer } from "components";

const ContactUs = () => {
  return(
    <PageContainer>
      <ContactUsMain/>
    </PageContainer>
  )
}

export default ContactUs;