import AdditionalInfo from "../AdditionalInfo";
import InvestmentIndustries from "../InvestmentIndustries";
import OurDocuments from "../OurDocuments";
import ProjectTeam from "../ProjectTeam";
import Roadmap from "../Roadmap";
import StandartedTextRowHalf from "../StandartedTextRowHalf";
import Tokenomics from "../Tokenomics";
import styles from "./AboutUsMain.module.css";
import LogoCard from "./LogoCard";
import TokenCard from "./TokenCard";
import tokenImg from "assets/images/coin-1.png";
import glassy18 from "assets/images/glassy-18.png";
import DownloadButton from "./DownloadButton";
import Reveal from "components/containers/animations/Reveal";
import Parallax from "components/containers/animations/Parallax";
import { withTranslation, Trans } from "react-i18next";
import { useEffect, useState } from "react";

const AboutUsMain = ({ t }) => {
  const speciallyCreated = {
    title: (
      <Trans
        t={t}
        i18nKey={"specially-title"}
        components={[<span className="accent-text"/>]}
      /> 
    ),
    overtitle: (
      <Trans
        t={t}
        i18nKey={"specially-overtitle"}
        components={[<span className="accent-text"/>, <DownloadButton className={styles.downloadButton} />]}
      /> 
    ),
    subtitle: (
      t("specially-subtitle")
    ),
    addition: (
      t("specially-addition")
    ),
  };
  const tokensCards = [
    {
      percent: t('token-card-1-percent'),
      description: t('token-card-1-description'),
    },
    {
      percent: t('token-card-2-percent'),
      description: t('token-card-2-description'),
    },
    {
      percent: t('token-card-3-percent'),
      description: t('token-card-3-description'),
    },
    {
      percent: t('token-card-4-percent'),
      description: t('token-card-4-description'),
    },
  ];

  return (
    <section className={styles.wrapper}>
      {/* <Margin margin={120} /> */}
      <StandartedTextRowHalf {...speciallyCreated} />
      <AdditionalInfo>
        <p className={styles.tokenText}>
          <Trans
            t={t}
            i18nKey={"token-text"}
            components={[<span className="white-accent-text"/>]}
          /> 
          <Parallax>
            <img className={styles.tokenImg} src={tokenImg} />
          </Parallax>
        </p>
      </AdditionalInfo>
      <Tokenomics />
      <AdditionalInfo letters={false}>
        <div className={styles.tokensCards}>
          {tokensCards.map((card, index) => (
            <TokenCard {...card} />
          ))}
        </div>
        <img className={styles.tokenCardsImg} src={glassy18} />
      </AdditionalInfo>
      <Roadmap hideButtons={true} />
      {/* <Margin margin={1620} /> */}
      <div className={styles.ourPartnersTitle} style={{ marginTop: 0 }}>
        <Reveal>
          <p className={styles.title}>{t('partners-title-1')}</p>
        </Reveal>
        <Reveal>
          <p className={styles.title}>
            <span className="accent-text">{t('partners-title-2')}</span>
          </p>
        </Reveal>
      </div>
      <LogoCard />
      {/* <Margin margin={125} /> */}
      <InvestmentIndustries />
      {/* <Margin margin={120} /> */}
      <div className={styles.projectTeamTitle}>
          <p className={styles.title}>{t('team-title-1')}</p>
          <p className={styles.title}>
            <span className="accent-text">{t('team-title-2')}</span>
          </p>
      </div>
      {/* <Margin margin={20} /> */}
      <ProjectTeam />
      {/* <Margin margin={130} /> */}
      <div className={styles.ourLegalTitle}>
        <p className={styles.title}>{t('documents-title-1')}</p>
        <p className={styles.title}>
          <span className="accent-text">{t('documents-title-2')}</span>
        </p>
      </div>
      {/* <Margin margin={50} /> */}
      <OurDocuments />
    </section>
  );
};

export default withTranslation('aboutus')(AboutUsMain);
