import { CabinetContainer, StructureStatistic, Structure } from 'components';
import { withTranslation } from 'react-i18next';

const Binary = ({ t }) => {
  return (
    <CabinetContainer>
      <div>
        <h1>{t('team')}</h1>
        <StructureStatistic />
      </div>
      <div>
        <h2>{t('linear-structure')}</h2>
        <Structure />
      </div>
    </CabinetContainer>
  );
}

export default withTranslation('binary')(Binary);
