import styles from './MaterialsVideo.module.css';

const MaterialsVideo = () => {
  return (
    <section className={styles.materialsVideo}>
      <iframe src="https://player.vimeo.com/video/756427203?h=1074c0682a" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </section>
  );
};

export default MaterialsVideo;
