const LocationIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.58333 7.25075C9.58333 6.09969 8.65063 5.16699 7.50042 5.16699C6.34936 5.16699 5.41666 6.09969 5.41666 7.25075C5.41666 8.40096 6.34936 9.33366 7.50042 9.33366C8.65063 9.33366 9.58333 8.40096 9.58333 7.25075Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.49959 16C6.50086 16 1.25 11.7486 1.25 7.30274C1.25 3.8222 4.04758 1 7.49959 1C10.9516 1 13.75 3.8222 13.75 7.30274C13.75 11.7486 8.49832 16 7.49959 16Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default LocationIcon;
