import styles from './Burger.module.css';

const Burger = ({ open, ...props }) => {
  return (
    <div {...props} className={`${styles.burger} ${open ? styles.open : null}`}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Burger;
