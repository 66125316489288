import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ScrollToTop } from 'components';
import LanguageBasedRoutes from 'components/LanguageBasedRoutes/LanguageBasedRoutes';

const App = () => {
  return (
    <div className="App">
      <ScrollToTop />
      <LanguageBasedRoutes />
      <ToastContainer
        position="top-right"
        theme="colored"
        autoClose={3000}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
