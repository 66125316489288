import Button from "components/ui/Button"
import TextIntput from "components/ui/TextInput"
import { useForm } from "react-hook-form";
import styles from "./SettingsInfo.module.css"
import { ProfileIcon, EmailIcon, PasswordIcon, LockIcon } from "components/ui/icons";
import UltimateModal from "components/Modals/UltimateModal";
import modalImage from "assets/images/image-modal-1.png";
import modalImage2 from "assets/images/image-modal-2.png";
import { useBinaryQuery, useLazyDashboardQuery, useUpdateSettingsMutation } from "redux/api/common";
import { useEffect, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { useNavigateLang } from "hooks";

const SettingsInfo = ({ t }) => {
  const [changePasswordModal, setChangePasswordModal] = useState();
  const { data: settings } = useBinaryQuery();
  const [dashboardTrigger, { data: dashboardData }] = useLazyDashboardQuery();
  const [updateSettings, { isLoading, error }] = useUpdateSettingsMutation();
  const navigate = useNavigateLang();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dashboardTrigger();
  }, []);

  useEffect(() => {
    if (error) {
      const [fieldName] = Object.keys(error?.data?.errors);
      const message = error?.data?.errors[fieldName];
      setError(fieldName, { type: 'message', message });
    }
  }, [error]);

  const handleTfaClick = () => {
    if (dashboardData?.user?.is_2fa_enable) {
      navigate('/2fa/remove');
    } else {
      navigate('/2fa/enable');
    }
  };

  const onNewPasswordSubmit = async ({ password, password_confirmation }) => {
    try {
      const resp = await updateSettings({ password, password_confirmation });

      if (resp?.data?.success === 'ok') {
        setChangePasswordModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return(
    <div className={styles.wrapper}>
      <div className={styles.subWrapper}>
        <div className={styles.inputs}>
          <div className={styles.gigachad}>
            <div className={styles.input}>
              <div className={styles.name}>
                {t('login')}
              </div>
              <TextIntput name={'login'} register={register} icon={<ProfileIcon />} placeholder="login" value={settings?.user.login} disabled />
            </div>
            <div className={styles.input}>
              <div className={styles.name}>
                {t('e-mail')}
              </div>
              <TextIntput name={'email'} register={register} icon={<EmailIcon />} placeholder="example@gmail.com" value={settings?.user.email} disabled />
            </div>
          </div>
          <form className={styles.gigachad} onSubmit={handleSubmit(onNewPasswordSubmit)} id="change-settings">
            <div className={styles.input}>
              <TextIntput
                className={styles.passInput}
                label={t('new-password')}
                name={'password'}
                type='password'
                register={register}
                errors={errors}
                icon={<PasswordIcon/>}
                placeholder="|"
                validationSchema={{
                  required: 'Fill this field!',
                }}
              />
            </div>
            <div className={styles.input}>
              <TextIntput
                className={styles.passInput}
                label={t('confirm-password')}
                name={'password_confirmation'}
                type='password'
                register={register}
                errors={errors}
                icon={<PasswordIcon/>}
                placeholder="|"
                validationSchema={{
                  required: 'Fill this field!',
                }}
              />
            </div>
          </form>
        </div>
        <Button isLoading={isLoading} type="submit" form="change-settings" className={`${styles.button} ${styles.black}`}>
          {t('change')}
        </Button>
      </div>
      <div className={styles.buttons}>
        <Button
          onClick={handleTfaClick}
          className={`${styles.button} ${styles.black}`}
          icon={<LockIcon />}
        >
          {dashboardData?.user?.is_2fa_enable ? t('remove') : t('enable')} {t('google-authenticator')}
        </Button>
        <a href="https://cannabetrust.freshdesk.com/" target='_blank'><Button className={`${styles.button} ${styles.green}`} variant="accent">{t('support')}</Button></a>
        <a href="https://t.me/CannaBeSupport" target='_blank'><Button className={`${styles.button} ${styles.blue}`} variant="accent">{`Telegram ${t('support')}`}</Button></a>
      </div>

      <UltimateModal active={changePasswordModal} setActive={setChangePasswordModal}>
        <h2 className={styles.modalTitle}> 
          <Trans
            t={t}
            i18nKey={"password-change"}
            components={[<span className="accent-text"/>]}
          /> 
        </h2>
        <p className={styles.modalSubtitle}>
          <Trans
            t={t}
            i18nKey={"successfully-changed"}
            components={[]}
          /> 
        </p>
        <img src={modalImage}/>
      </UltimateModal>

      <UltimateModal active={false}>
        <h2 className={styles.modalTitle}>
          {t('support')}
        </h2>
        <p className={styles.modalSubtitle}>
          <Trans
            t={t}
            i18nKey={"support-will-reply"}
            components={[<span className="accent-text"/>]}
          /> 
        </p>
        <img src={modalImage2}/>
        <p className={styles.modalEmail}>
          <EmailIcon className={styles.modalEmailIcon}/> support@cannabetrust.com
        </p>
      </UltimateModal>
    </div>
  )
}

export default withTranslation('settings')(SettingsInfo)
