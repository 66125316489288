import styles from "./ProjectTeam.module.css"
import grey from "assets/images/grey.png"
import vries from "assets/images/vries.png"
import tailor from "assets/images/tailor.png"
import rect from "assets/images/rect.png"
import Card from "./Card"
import { withTranslation } from "react-i18next"


const ProjectTeam = ({ t }) => {
  const cards = [
    {
      img: grey,
      name: t('project-card-1-name'),
      specialist: t('project-card-1-specialist'),
      isEmpty: false,
    },
    {
      img: vries,
      name: t('project-card-2-name'),
      specialist: t('project-card-2-specialist'),
      isEmpty: false,
    },
    {
      img: rect,
      name: t('project-card-3-name'),
      specialist: t('project-card-3-specialist'),
      isEmpty: true,
    },
  ]
  return(
    <div className={styles.wrapper}>
      <div className={styles.cards}>
        {cards.map((card, index) => 
          <Card 
            {...card}
            key={index}
          />
        )}
      </div>
    </div>
  )
}

export default withTranslation('aboutus')(ProjectTeam);
