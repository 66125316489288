import { CommonIntro, PageContainer, SeedsMain } from "components"
import { withTranslation } from "react-i18next"

const Seeds = ({ t }) => {
  return (
    <PageContainer>
      <CommonIntro 
        title={t("intro-title")} 
        subtitle={t("intro-subtitle")}
        number='01' />
      <SeedsMain/>
    </PageContainer>
  )
}

export default withTranslation('seeds')(Seeds)
