import UltimateModal from "components/Modals/UltimateModal";
import Button from "components/ui/Button";
import { ArrowRightIcon, PasswordIcon } from "components/ui/icons";
import TextIntput from "components/ui/TextInput";
import { useForm } from "react-hook-form";
import styles from "./Remove2FA.module.css";
import modalImage from "assets/images/image-modal-1.png";
import { useRemoveTfaMutation } from "redux/api/2fa";
import { useEffect } from "react";
import { useLazyDashboardQuery } from "redux/api/common";
import { withTranslation, Trans } from "react-i18next";
import { toast } from 'react-toastify';
import { useNavigateLang } from "hooks";

const Remove2FA = ({ t }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [dashboardTrigger, { data: dashboardData }] = useLazyDashboardQuery();
  const [removeTfa, { isLoading, error }] = useRemoveTfaMutation();
  const navigate = useNavigateLang();

  const handleRemoveTfa = async ({ code }) => {
    const resp = await removeTfa({ code });

    if (resp?.data?.success === 'ok') {
      await dashboardTrigger();
      navigate('/settings');
    } else {
      const message = resp?.data?.error || resp?.error?.data?.message || 'Something went wrong';
	    toast(message, { type: 'error' });
    }
  };

  const initRemove = async () => {
    await dashboardTrigger();
    console.log('is enabled', !dashboardData?.user?.is_2fa_enable);
    if (!dashboardData?.user?.is_2fa_enable) {
      navigate('/settings');
    }
  };

  useEffect(() => {
    // initRemove();
  }, []);

  useEffect(() => {
      if(error && error?.status !== 200) {
        const message = error?.data?.errors || 'Something went wrong';
	      toast(message.join(' '), { type: 'error' });
    }
  }, [error]);

  return(
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <Button className={styles.titleButton} icon={<ArrowRightIcon className={styles.iconButton}/>}>{t('back-to-settings')}</Button>
        <p className={styles.title}>
          {t('remove')} <span className="accent-text">{t('google-authenticator')}</span>
        </p>
      </div>

      <div className={styles.content}>
        <p className={styles.subtitle}>
          {t('security-verification')}
        </p>
        <form className={styles.input} onSubmit={handleSubmit(handleRemoveTfa)}>
          <TextIntput
            name={'code'}
            type='number'
            label={t('enter-code')}
            register={register}
            icon={<PasswordIcon/>}
            placeholder="|"
            errors={errors}
            className={styles.codeInput}
            validationSchema={{
              required: 'Fill this field!',
            }}
          />
          <Button type="submit" isLoading={isLoading} className={styles.inputButton} variant="accent">{t('next')}</Button>
        </form>
      </div>
      
      <UltimateModal state={false}>
        <h2 className={styles.modalTitle}>
          <span className="accent-text">{t('success')}</span>
        </h2>
        <p className={styles.modalSubtitle}>
          <Trans
            t={t}
            i18nKey={"removed-authenticator"}
            components={[]}
          /> 
        </p>
        <img src={modalImage}/>
      </UltimateModal>
    </div>
  )
}

export default withTranslation('settings')(Remove2FA);
