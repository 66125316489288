import { configureStore } from '@reduxjs/toolkit';
import commonSlice from './slices/commonSlice';
import { commonApi } from './api/common';
import { authApi } from './api/auth';
import { tfaApi } from './api/2fa';

export default configureStore({
  reducer: {
    common: commonSlice,
    [commonApi.reducerPath]: commonApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [tfaApi.reducerPath]: tfaApi.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware()
    .concat([
      commonApi.middleware,
      authApi.middleware,
      tfaApi.middleware,
    ])
});
