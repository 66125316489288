import { TextIntput, Button } from 'components';
import { PasswordIcon } from 'components/ui/icons';
import styles from './TfaForm.module.css';
import { withTranslation } from 'react-i18next';

const TfaForm = ({t, ...props}) => {

  return (
    <div {...props}>
      <div className={styles.header}>
        <h1 className={`form-title ${styles.title}`}>{t('two-factor-authentication')}</h1>
        <p className='form-desc'>{t('enter-the-code')}</p>
      </div>
      <form className={styles.form}>
        <div className={styles.passwordBlock}>
          <TextIntput
            label='2FA code'
            type='number'
            placeholder='678 999'
            icon={<PasswordIcon />}
          />
          <div className={styles.forgot}>
            <a href='#' className={styles.forgotLink}>
              {t('not-receive-the-code')}
            </a>
          </div>
        </div>
        <Button type='submit' variant='accent' size='big'>{t('change-password')}</Button>
        <div className={styles.registerBlock}>
          <div className={styles.left}></div>
          <div className={styles.rigth}>
            <span>{t('need-a-help')}</span>
          </div>
        </div>
      </form>
      <div className={styles.footer}></div>
    </div>
  );
};

export default withTranslation('forms')(TfaForm);
