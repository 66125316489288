import { Reveal } from "components";
import styles from "./Corp.module.css";
import img7 from "assets/images/image-7.png";
import { withTranslation, Trans } from "react-i18next";

const Corp = ({ t }) => {
  return (
    <section className={styles.corp}>
      <div className={styles.headings}>
        <Reveal>
          <div className={styles.title}>
            <Trans
              t={t}
              i18nKey='cannabetrust'
              components={[<span className='accent-text' />]}
            />
          </div>
        </Reveal>
        <Reveal>
          <div className={`${styles.title} ${styles.center}`}>{t('corporation')}</div>
        </Reveal>
        <Reveal>
          <div className={styles.title}>{t('operates')}</div>
        </Reveal>
        <img className={styles.img7} src={img7} />
      </div>
      <div className={styles.corpInfo}>
        <div className={styles.figureContainer}>
          <div className={styles.figure}>
            <div className={styles.countriesCount}>
              {t('countries-count')}
              <span className={styles.countries}>{t('countries')}</span>
            </div>
          </div>
        </div>
        <div className={styles.text}>
          <div className={styles.bigText}>
            <Trans 
              t={t}
              i18nKey='corp-text1'
              components={[<span className={styles.boldAccentText} />]}
            />
          </div>
          <div className={styles.smallText}>
            <Trans
              t={t}
              i18nKey='corp-text2'
              components={[<span className={styles.boldAccentText} />]}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation('main')(Corp);
