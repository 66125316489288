import styles from "./UltimateModal.module.css";
import { CloseSquareIcon } from "components/ui/icons";
import { Button } from "components";
import { useDispatch } from "react-redux";
import { setModal } from "redux/slices/commonSlice";

const UltimateModal = ({
  children,
  className,
  active,
  setActive=()=>{},
  withClose=true,
  type, //none, products, video
}) => {
  const dispatch = useDispatch();

  const close = e => {
    if (e) {
      e.preventDefault();
    }

    setActive(false)
    dispatch(setModal(''))
  };

  return(
    <div className={`${styles.wrapper} ${active ? styles.active : null}`} onClick={close}>
      <div className={`${styles.modal} ${styles[type]} ${className}`} onClick={(e) => e.stopPropagation()}>
        {children}
        {withClose &&
        <Button className={styles.close} icon={<CloseSquareIcon className={styles.closeImage}/>} onClick={close}/>}
      </div>
    </div>
  )
}

export default UltimateModal
