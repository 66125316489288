import React, { useEffect, useState } from 'react';
import styles from './LangSelector.module.css';
import en from 'assets/images/lang/en.svg';
import ru from 'assets/images/lang/ru.svg';
import ua from 'assets/images/lang/ua.svg';
import pt from 'assets/images/lang/pt.svg';
import es from 'assets/images/lang/es.svg';
import ArrowDown from 'assets/images/icons/arrow-down.svg';
import ArrowDownDark from 'assets/images/icons/arrow-down_dark.svg';

const LangSelector = ({
  variant='light', // light | dark
  className,
  selectedLanguage,
  setSelectedLanguage,
  withIcon=true,
}) => {
  const langs = [
    {name:'Eng', img: en, value: 'en'},
    {name:'Rus', img: ru, value: 'ru'},
  ];

  const handleLanguageChange = index => {
    setSelectedLanguage(index);
  };

  const [isOpen, setIsOpen] = useState(false)
  return (
    <div 
      className={`${styles.langSelector} ${styles[variant]} ${className}`}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {withIcon && <img className={styles.flag} src={langs[selectedLanguage].img} />}
      <span>{langs[selectedLanguage].name}</span>
      <img src={variant === 'light' ? ArrowDown : ArrowDownDark} />

      {isOpen && <div className={styles.dropdownBlock}/>}

      {isOpen && 
      <div className={`${styles.dropdownWrapper} ${styles[variant]}`}>
        {langs && langs.map((lang, index) => 
          <div key={index} className={styles.dropdownLang} onClick={() => handleLanguageChange(index)}>
            <img className={styles.langImg} src={lang.img} />
            <p className={styles.langName}>{lang.name}</p>
          </div>
        )}
      </div>}

    </div>
  );
};

export default LangSelector;
