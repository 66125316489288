import { CabinetContainer, StoreHeader, StoreProducts } from 'components';

const Store = () => {
  return (
    <CabinetContainer>
      <div>
        <StoreHeader />
        <StoreProducts />
      </div>
    </CabinetContainer>
  );
}

export default Store;
