const ArrowRightIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M16.459 10.0445H3.95898" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.418 5.02411L16.4596 10.0441L11.418 15.0649" stroke="#001007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default ArrowRightIcon;
