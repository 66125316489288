import { BinaryTree, Button, TextIntput } from 'components';
import { ArrowRightSquareIcon, ArrowLeftSquareIcon, SearchIcon } from 'components/ui/icons';
import { useForm } from 'react-hook-form';
import styles from './BinaryStructure.module.css';
import gradient from 'assets/images/icons/gradient.svg';
import profile from 'assets/images/icons/profile.svg';
import two from 'assets/images/icons/two.svg';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBinaryBaseData } from 'redux/slices/commonSlice';
import { useBinaryChangeDirectionMutation, useBinaryChangePositionMutation, useBinaryQuery, useDashboardQuery } from 'redux/api/common';
import { withTranslation, Trans } from 'react-i18next';

const BinaryStructure = ({ t }) => {
  const [isMouseOver, setIsMouseOver] = useState();
  const [selectedDirection, setSelectedDirection] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const { register } = useForm();
  const { binaryBaseData } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const [binaryChangePosition, result] = useBinaryChangePositionMutation();
  const [binaryChangeDirection] = useBinaryChangeDirectionMutation()
  const { data: dashboardData } = useDashboardQuery();
  const { data: binaryData } = useBinaryQuery();

  useEffect(() => {
    if (binaryData?.binary) {
      switch (binaryData?.binary.position_child) {
        case 'left':
          setSelectedDirection(0);
          break;
        case 'auto':
          setSelectedDirection(1);
          break;
        case 'right':
          setSelectedDirection(2);
          break;
        default:
          break;
      }
    }
  }, [binaryData]);

  useEffect(() => {
    if (binaryBaseData?.user?.login !== dashboardData?.user?.login) {
      setSearchQuery(binaryBaseData?.user?.login);
    }
  }, [binaryBaseData]);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      dispatch(setBinaryBaseData(result?.data));
    }
  }, [result]);

  const handleSearch = () => {
    binaryChangePosition({ login: searchQuery });
  };

  const handleDirectionClick = position => {
    binaryChangeDirection({ position });
  };

  return (
    <section className={styles.binaryStructure}>
      <div className={styles.head}>
        <div className={styles.searchBar}>
          <TextIntput
            className={styles.searchInput}
            label='Search partner'
            name='search'
            register={register}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            placeholder='login'
            icon={<SearchIcon />}
          />
          <Button
            variant='accent'
            onClick={handleSearch}
          >
            {t('search-partner')}
          </Button>
        </div>
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={styles.imgWrapper}>
              <img src={gradient}/>
            </div>
            <span>{t('displays-partners-package')}</span>
          </div>
          <div className={styles.item}>
            <div className={styles.imgWrapper}>
              <img src={profile}/>
            </div>
            <span>{t('non-active-partner')}</span>
          </div>
          <div className={styles.item}>
            <div className={styles.imgWrapper}>
              <img src={two}/>
            </div>
            <span>{t('rank')}</span>
          </div>
        </div>
        <div className={styles.controls}>
          <div className={styles.top}>
            <div className={styles.gridItem}></div>
            <div className={styles.gridItem}>
              <span className={styles.text}>{t('choose-way')}</span>
              {isMouseOver &&
              <div className={styles.wrapper}>
                <Trans
                  t={t}
                  i18nKey={"help"}
                  components={[<span className="accent-text"/>]}
                /> 
              </div>}
            </div>
            <div className={styles.gridItem}>
            </div>
          </div>
          <div className={styles.bottom}>
            <Button
              variant={selectedDirection === 0 ? 'accent' : 'outline-filled'}
              icon={<ArrowLeftSquareIcon style={selectedDirection === 0 ? null : { filter: 'invert(100%)' }} className={styles.icon} />}
              className={styles.iconButton}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
              onClick={() => handleDirectionClick('left')}
            />
            <Button
              variant={selectedDirection === 1 ? 'accent' : 'outline-filled'}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
              onClick={() => handleDirectionClick('auto')}
            >
              {t('auto')}
            </Button>
            <Button
              variant={selectedDirection === 2 ? 'accent' : 'outline-filled'}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
              icon={<ArrowRightSquareIcon style={selectedDirection === 2 ? { filter: 'invert(100%)' } : null} className={styles.icon}/>}
              className={styles.iconButton}
              onClick={() => handleDirectionClick('right')}
            />
          </div>
        </div>
        <BinaryTree />
      </div>
    </section>
  );
};

export default withTranslation('binary')(BinaryStructure);
