const PasswordIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <g id="Iconly/Regular/Bold/Password">
        <g id="Password">
          <path id="Password_2" fillRule="evenodd" clipRule="evenodd" d="M14.1752 12.4011C14.5202 12.4011 14.8002 12.1211 14.8002 11.7761V10.2327C14.8002 9.88775 14.5202 9.60775 14.1752 9.60775H9.43185C9.16185 8.71858 8.34352 8.06525 7.36685 8.06525C6.17185 8.06525 5.19935 9.03775 5.19935 10.2327C5.19935 11.4286 6.17185 12.4011 7.36685 12.4011C8.34352 12.4011 9.16185 11.7477 9.43185 10.8577H11.1918V11.7761C11.1918 12.1211 11.4718 12.4011 11.8168 12.4011C12.1618 12.4011 12.4418 12.1211 12.4418 11.7761V10.8577H13.5502V11.7761C13.5502 12.1211 13.8302 12.4011 14.1752 12.4011ZM6.38768 1.89941H13.6118C16.4352 1.89941 18.3327 3.88108 18.3327 6.83025V13.6361C18.3327 16.5852 16.4352 18.5661 13.611 18.5661H6.38768C3.56352 18.5661 1.66602 16.5852 1.66602 13.6361V6.83025C1.66602 3.88108 3.56352 1.89941 6.38768 1.89941ZM6.4486 10.2335C6.4486 9.72683 6.8611 9.31516 7.36693 9.31516C7.87277 9.31516 8.28527 9.72683 8.28527 10.2335C8.28527 10.7393 7.87277 11.151 7.36693 11.151C6.8611 11.151 6.4486 10.7393 6.4486 10.2335Z" fill="#8F939C" />
        </g>
      </g>
    </svg>
  );
}

export default PasswordIcon;
