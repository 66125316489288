import { Button, TextIntput } from "components";
import styles from "./Transfer.module.css";
import ChooseCoin from "./ChooseCoin";
import coin from "assets/images/icons/coin.svg";
import { FormProvider, useForm } from "react-hook-form";
import QRCode from "react-qr-code";
import { CopyIcon } from "components/ui/icons";
import {
  useDashboardQuery,
  useFinanceQuery,
  useWithdrawalCalcMutation,
  useWithdrawalCreateMutation
} from "redux/api/common";
import { useDebounce, useNavigateLang } from "hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { pause, scrollTo } from "helpers/utils";
import TfaModal from 'components/Modals/TfaModal';
import { withTranslation, Trans } from "react-i18next";
import { BuyPackage, Wrong2FACode } from "components";
import { useSelector } from "react-redux";

const Transfer = ({ t }) => {
  const { data } = useFinanceQuery();
  const { data: dashboardData } = useDashboardQuery();
  const [withdrawalCalc, withRes] = useWithdrawalCalcMutation();
  
  const [openTfa, setOpenTfa] = useState();
  const [openSuccess, setOpenSuccess] = useState();
  const [copyBtnText, setCopyBtnText] = useState();
  const [wrangModal, setWrangModal] = useState();
  
  const [withdrawalFromAmount, setWithdrawalFromAmount] = useState(0);
  const [withdrawalToAmount, setWithdrawalToAmount] = useState(0);
  // const debouncedWithFromValue = useDebounce(withdrawalFromAmount, 300);
  // const debouncedWithToValue = useDebounce(withdrawalToAmount, 300);

  const [currect, setCurrect] = useState(0);
  const [depCurrect, setDepCurrect] = useState(0);

  const location = useLocation()
  const navigate = useNavigateLang();

  const labels = [
    'USDT TRC-20',
    'CAN TRC-20',
  ];

  const calculateCurrencyNet = currect => {
    return {
      network: 'tron',
      currency: currect === 0 ? 'usdt' : 'can',
    };
  };

  useEffect(() => {
    const { currency, network } = calculateCurrencyNet(depCurrect);

    withdrawalCalc({
      currency,
      network,
      from_amount: 10,
    });
  }, []);

  useEffect(() => {
    if (data) {
      setCopyBtnText(data?.deposit.address);
    }
  }, [data]);

  useEffect(() => {
    if (location.hash.length) {
      scrollTo(location.hash.replace('#', ''));
    }
  }, [location]);

  const handleWithdrawFrom = async (from_amount) => {
    setWithdrawalFromAmount(from_amount);
    const { currency, network } = calculateCurrencyNet(currect);

    if (!parseFloat(withdrawalFromAmount)) {
      setWithdrawalToAmount(0);
      return;
    }

    const resp = await withdrawalCalc({
      currency,
      network,
      from_amount,
    });

    setWithdrawalToAmount(resp?.data?.calc);
  };

  useEffect(() => {
    handleWithdrawFrom(withdrawalFromAmount);
  }, [currect]);

  const handleWithdrawTo = async (to_amount) => {
    setWithdrawalToAmount(to_amount);
    const { currency, network } = calculateCurrencyNet(currect);

    if (!parseFloat(withdrawalToAmount)) {
      setWithdrawalFromAmount(0);
      return;
    }

    const resp = await withdrawalCalc({
      currency,
      network,
      to_amount,
    });

    setWithdrawalFromAmount(resp?.data?.calc);
    setValue('withdrawal_from_amount', resp?.data?.calc);
  };

  const methods = useForm({
    defaultValues: {
      amountCan: 1,
      amountUsdt: 1,
      fromAmountCan: 1,
      toAmountUsdt: 1,
    },
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    setError,
  } = methods;

  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText(data?.deposit.address);
      setCopyBtnText(t('copied-address'));
      await pause(1000);
      setCopyBtnText(data?.deposit.address);
    } catch (error) {
      console.error(error);
    }
  };

  const [withdrawalCreate] = useWithdrawalCreateMutation();

  const onWithdraw = async data => {
    try {
      const { currency, network } = calculateCurrencyNet(currect);
      const { wallet_address, withdrawal_from_amount, withdrawal_code } = data;

      const resp = await withdrawalCreate({
        currency,
        network,
        wallet_address,
        withdrawal_from_amount,
        withdrawal_code,
      });

      if (resp?.data?.success === 'ok') {
        setOpenSuccess(true);
      }else{
        resp?.error?.data?.message === "Invalid code." ? setWrangModal(true) : setWrangModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenTfa = () => {
    const { wallet_address, withdrawal_from_amount } = getValues();
    if (!wallet_address.length) {
      setError('wallet_address', { message: t('not-empty') });
      return;
    }

    if (withdrawal_from_amount < parseFloat(withRes?.data?.min_amount)) {
      setError('withdrawal_from_amount', { message: 'The withdrawal from amount must be at least 10' });
      return;
    }

    setOpenTfa(true);
  };

  return (
    <section className={styles.transfer}>
      <Wrong2FACode active={wrangModal} setActive={setWrangModal}/>
      <div className={`${styles.box} ${styles.deposit}`} id="deposit-block">
        <h2>{t('deposit')}</h2>
        <div className={styles.card}>
          <div className={styles.head}>
            <ChooseCoin currect={depCurrect} setCurrect={setDepCurrect} />
          </div>
          <div className={styles.body}>
            <div className={styles.rate}>
              <img src={coin} />
              <span>1 CAN = {data?.deposit?.rate_deposit} USDT</span>
            </div>
            <div className={styles.bottom}>
              <div className={styles.qrcode}>
                <QRCode
                  size={`100%`}
                  bgColor="white"
                  fgColor="black"
                  value={data?.deposit?.address || ''}
                />
              </div>
              <div className={styles.actions}>
                <Button
                  variant="accent"
                  className={styles.QRbutton}
                  icon={<CopyIcon style={{ filter: "invert(100%)" }} />}
                  onClick={handleCopyAddress}
                >
                  <p>{copyBtnText}</p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.box} ${styles.withdraw}`}>
        <h2>{t('withdraw')}</h2>
        <div className={styles.card}>
          <div className={styles.head}>
            <ChooseCoin currect={currect} setCurrect={setCurrect} />
          </div>
          <div className={styles.body}>
            <div>
              <div className={styles.balance}>
                <div>
                  <div className={styles.label}>{t('total_balance')}</div>
                  <div className={styles.value}>
                    <img src={coin} />
                    <div>{dashboardData?.user?.balance} CAN</div>
                  </div>
                </div>
                <div>
                  <div className={styles.label}>{t('available')}</div>
                  <div className={styles.value}>
                    <img src={coin} />
                    <div>{dashboardData?.user?.balance} CAN</div>
                  </div>
                </div>
              </div>
              <div className={styles.rate}>
                <img src={coin} />
                <span>1 CAN = {data?.deposit?.rate_deposit} USDT</span>
              </div>
              <FormProvider {...methods}>
                <form id="withdraw-form" className={styles.inputs} onSubmit={handleSubmit(onWithdraw)}>
                  <TextIntput
                    label={`${t('from-amount')} CAN`}
                    rightLabel={currect !== 1 ? `${t('fee')} 3% + ${withRes?.data?.fee?.amount || ''}$` : ``}
                    register={register}
                    name="withdrawal_from_amount"
                    type="text"
                    errors={errors}
                    onChange={e => handleWithdrawFrom(e.target.value)}
                    value={withdrawalFromAmount}
                    validationSchema={{
                      required: t('not-empty')
                    }}
                  />
                  <TextIntput
                    label={`${t('to-amount')} ${labels[currect]} ${t('with-fee')}`}
                    register={register}
                    name="withdrawal_to_amount"
                    errors={errors}
                    onChange={e => handleWithdrawTo(e.target.value)}
                    value={withdrawalToAmount}
                    type="text"
                  />
                  <TextIntput
                    label={t('enter-address')}
                    register={register}
                    errors={errors}
                    name="wallet_address"
                    placeholder=""
                    validationSchema={{
                      required: t('not-empty')
                    }}
                  />
                  <TfaModal active={openTfa} setActive={setOpenTfa} />
                  <BuyPackage active={openSuccess} setActive={setOpenSuccess} text={t('you-withdraw-successful', { ns: 'settings' })} />
                </form>
              </FormProvider>
              <div className={styles.auth}>
                {!dashboardData?.user?.is_2fa_enable &&
                  <div className={styles.authMessage}>
                    <Trans
                      t={t}
                      i18nKey={'g-auth'}
                      components={[<a onClick={() => navigate('/2fa/enable')} />]}
                    />
                  </div>}
                <div className={styles.authTimeWarning}>
                  <Trans
                    t={t}
                    i18nKey={'term'}
                    components={[<b />]}
                  />
                </div>
              </div>
            </div>
            <div className={styles.actions}>
              <Button onClick={handleOpenTfa} variant="accent">{t('withdraw')}</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation(['finance', 'settings'])(Transfer);
