const ArrowLeftSquareIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.29168 4.88866L1.29168 12.1128C1.29168 14.6295 3.07418 16.2087 5.59668 16.2087H12.4033C14.9258 16.2087 16.7083 14.6378 16.7083 12.1128L16.7083 4.88866C16.7083 2.36366 14.9258 0.791992 12.4033 0.791992L5.59668 0.791992C3.07418 0.791992 1.29168 2.36366 1.29168 4.88866Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.595 8.50065L12.405 8.50065" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.73141 11.6236L5.59475 8.50029L8.73141 5.37695" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowLeftSquareIcon;
