const ActivityIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M6.03738 12.3183L8.53166 9.07646L11.3768 11.3114L13.8177 8.16113" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <ellipse cx="16.6629" cy="3.50027" rx="1.60183" ry="1.60183" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4371 2.60059H6.38063C3.8711 2.60059 2.31503 4.37785 2.31503 6.88739V13.6227C2.31503 16.1322 3.84059 17.9019 6.38063 17.9019H13.5507C16.0603 17.9019 17.6163 16.1322 17.6163 13.6227V7.75695" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ActivityIcon;
