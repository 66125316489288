import { Button } from "components";
import styles from "./Card.module.css";
import { ArrowRightIcon } from "components/ui/icons";
import { withTranslation } from "react-i18next";
import { useNavigateLang } from "hooks";

const Card = ({
  title,
  suptitle,
  description,
  footerPrefix,
  footerContent,
  isEven,
  t,
  hideButtons
}) => {
  const navigate = useNavigateLang();

  return (
    <div
      className={`${styles.card} ${!isEven ? styles.even : null}`}
      style={isEven ? { backgroundColor: "white" } : null}
    >
      <div className={styles.head}>
        <div
          className={styles.suptitle}
          style={isEven ? { color: "var(--grey-1)" } : null}
        >
          {suptitle}
        </div>
        <div className={styles.title}>
          <span
            style={isEven ? { color: "var(--accent)" } : { color: "white" }}
          >
            {title.split(" ")[0]}
          </span>{" "}
          {title.split(" ").slice(1).join(" ")}
        </div>
        <div
          className={styles.description}
          style={isEven ? { color: "var(--grey-1)" } : null}
        >
          {description}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.body}>
          {!hideButtons &&
          <Button
            icon={
              isEven ? (
                <ArrowRightIcon style={{ filter: "invert(100%)" }} />
              ) : (
                <ArrowRightIcon />
              )
            }
            variant={isEven ? "accent" : "primary"}
            onClick={() => navigate('/about-us')}
          >
            {t('roadmap-about')}
          </Button>
          }
        </div>
        <div className={styles.footer}>
          <span className={isEven && "accent-text"}>{footerPrefix}</span>
          &nbsp;
          <span
            className={`${styles.footerContent} ${
              isEven && styles.footerContentBlack
            }`}
          >
            {footerContent}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('main')(Card);
