import { PageContainer, UncommonIntro, AboutUsMain } from "components";

const AboutUs = () => {
  return (
    <PageContainer>
      <UncommonIntro />
      <AboutUsMain />
    </PageContainer>
  );
};

export default AboutUs;
