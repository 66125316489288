import { useEffect, useRef, useState } from 'react';
import styles from './Counter.module.css';

const Counter = ({ onChange=Function.prototype }) => {
  const [count, setCount] = useState(1);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef) {
      inputRef.current.style.width = `${(count.toString().length + 1) / 2}em`;
    }
  }, [count]);

  const handleChange = (operation) => {

    switch (operation) {
      case '+':
        setCount(count + 1);
        break;
      case '-':
        if (count > 0) {
          setCount(count - 1);
        }
        break;
    }
  };

  return (
    <div className={styles.counter}>
      <div className={styles.button} onClick={() => handleChange('-')}>-</div>
      <input
        type='number'
        className={styles.count}
        value={count}
        onChange={e => setCount(e.target.value)}
        ref={inputRef}
      />
      <div className={styles.button} onClick={() => handleChange('+')}>+</div>
    </div>
  );
};

export default Counter;
