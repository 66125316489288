import LettersComponent from "components/LettersComponent"
import AdditionalInfo from "../AdditionalInfo"
import InvestInfo from "../InvestInfo"
import StandartedTextColumn from "../StandartedTextColumn"
import StandartedTextRow from "../StandartedTextRow"
import styles from "./TechnicalMain.module.css"
import img4 from "assets/images/image-4.png"
import img5 from "assets/images/image-5.png"
import img6 from "assets/images/image-6.png"
import Margin from "../Margin"
import StandartedTextRowV4 from "../StandartedTextRowV4"
import StandartedTextRowV5 from "../StandartedTextRowV5"
import StandartedTextRowV3 from "../StandartedTextRowV3"
import StandartedTextRowV2 from "../StandartedTextRowV2"
import StandartedTextColumnV2 from "../StandartedTextColumnV2"
import InvestInfoV2 from "../InvestInfoV2"
import { withTranslation, Trans } from "react-i18next"

const TechnicalMain = ({ t }) => {
  const greatestProspects = {
    title: 
      <Trans
        t={t}
        i18nKey={"greatest-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle: 
      <Trans
        t={t}
        i18nKey={"greatest-subtitle"}
        components={[<span className="accent-text"/>]}
      />,
    addition: 
      <Trans
        t={t}
        i18nKey={"greatest-addition"}
        components={[<span className="accent-text"/>]}
      />,
  }
  const biofuel = {
    title:  
      <Trans
        t={t}
        i18nKey={"biofuel-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle:  
      <Trans
        t={t}
        i18nKey={"biofuel-subtitle"}
        components={[<span className="accent-text"/>]}
      />,
    addition:  
      <Trans
        t={t}
        i18nKey={"biofuel-addition"}
        components={[<span className="accent-text"/>]}
      />,
  }
  const toKnow = {
    title:   
      <Trans
        t={t}
        i18nKey={"toknow-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle:   
      <Trans
        t={t}
        i18nKey={"toknow-subtitle"}
        components={[<span className="accent-text"/>]}
      />,
    addition:   
      <Trans
        t={t}
        i18nKey={"toknow-addition"}
        components={[]}
      />,
  }
  const cards = [
    {
      number:
      <Trans
        t={t}
        i18nKey={"cards-1-number"}
        components={[]}
      />,
      title : 
      <Trans
        t={t}
        i18nKey={"cards-1-title"}
        components={[]}
      />,
      description : 
      <Trans
        t={t}
        i18nKey={"cards-1-description"}
        components={[]}
      />,
    },
    {
      number :  
        <Trans
          t={t}
          i18nKey={"cards-2-number"}
          components={[]}
        />,
      title :  
        <Trans
          t={t}
          i18nKey={"cards-2-title"}
          components={[]}
        />,
      description :  
        <Trans
          t={t}
          i18nKey={"cards-2-description"}
          components={[]}
        />,
    },
  ]
  const guarantees = {
    title:  
      <Trans
        t={t}
        i18nKey={"guarantees-title"}
        components={[<span className="accent-text"/>]}
      />,
    subtitle:  
      <Trans
        t={t}
        i18nKey={"guarantees-subtitle"}
        components={[]}
      />,
    addition:  
      <Trans
        t={t}
        i18nKey={"guarantees-addition"}
        components={[]}
      />,
  }
  return(
    <section className={styles.wrapper}>
      <Margin margin={110}/>
      <StandartedTextRowV4
        {...greatestProspects}
      >
        <img className={styles.img5} src={img5}/>
      </StandartedTextRowV4>
      <Margin margin={30}/>
      <div className={styles.letters}>
        <LettersComponent isAccent isDark />
        <img className={styles.img4} src={img4}/>
      </div>
      <Margin margin={40}/>
      <StandartedTextRowV3
        {...biofuel}
      />
      <Margin margin={320}/>
      <StandartedTextColumnV2
        {...toKnow}
      >
        <img className={styles.img6} src={img6}/>
      </StandartedTextColumnV2>
      <Margin margin={130}/>
      <AdditionalInfo>
        <p className={styles.exceptionalText}>
          <Trans
            t={t}
            i18nKey={"exceptional"}
            components={[<span className="white-accent-text"/>]}
          />
        </p>
      </AdditionalInfo>
      <InvestInfoV2 cards={cards}/>
      <Margin margin={135}/>
      <StandartedTextRowV5
        {...guarantees}
      />
      <Margin margin={130}/>
    </section>
  )
}

export default withTranslation("technical")(TechnicalMain);
