import styles from "./StandartedTextColumn.module.css"

const StandartedTextColumnV2 = ({
  title=<></>,
  subtitle=<></>,
  addition=<></>,
  children=<></>
}) => {
  return(
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.line}/>
      <div className={styles.right}>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.line}/>
        <div className={styles.addition}>{addition}</div>
      </div>
      {children}
    </div>
  )
}

export default StandartedTextColumnV2;